/* eslint-disable prettier/prettier */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { saveSelectTreeItemAct } from 'src/store/reducers/files';
import { TablePhoto } from 'src/store/types';
import { Icon } from '../UI/Icon';
import * as Styled from './styles';

interface TreeProps {
  tree: TablePhoto[];
  searchStr?: string;
}

const TreeMenu: React.FC<TreeProps> = ({ tree, searchStr }) => {
  const [opened, setOpened] = useState<boolean>(true);
  const { treeName } = useAppSelector((state) => state.files);
  const { tablePhotos } = useAppSelector((state) => state.files);
  const { id: imageId } = useAppSelector(
    (state) => state.files.selectedTreeItem
  );
  const dispatch = useAppDispatch();

  return (
    <Styled.Root>
      <Styled.DropHead opened={opened} onClick={() => setOpened(!opened)}>
        <Styled.DropTitle>
          { treeName }
        </Styled.DropTitle>
        <Icon name='chevron-down' width={7} height={7} />
      </Styled.DropHead>
      {searchStr ? (
        <Styled.DropBody opened={opened} itemCount={tree.length}>
          {tree.filter((item) => item.upload.includes(searchStr)).map((item) => <Styled.Item key={item.id}>{item.upload}</Styled.Item>)}
        </Styled.DropBody>
      ) : (
        <Styled.DropBody opened={opened} itemCount={tree.length}>
          {tree.map((item) => 
          <Styled.Item 
            active={ item.id === imageId }
            key={item.id} 
            onClick = {() => {
              dispatch(
                saveSelectTreeItemAct({
                  id: item.id,
                  name: item.imgName,
                  url: tablePhotos.find((treeItem) => treeItem.imgName === item.imgName)?.upload || '',
                  numBox: item.numBox,
                  range: item.range,
                })
              );
            }}
          >
            {item.imgName}
          </Styled.Item>)}
        </Styled.DropBody>
      )}
    </Styled.Root>
  );
};

export default TreeMenu;
