import styled from 'styled-components';

interface Props {
  logged?: boolean;
}

// eslint-disable-next-line prettier/prettier
export const Wrapper = styled.div<Props>`
  width: 100vw;
  height: calc(100vh - 88px);
  display: flex;
  border-left: 20px solid ${({ theme }) => theme.colors.mainRed};
`;
