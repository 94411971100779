import { Row } from 'src/store/types';

export type fileExtensions = 'docx' | 'png' | 'jpg' | 'xlsx' | 'xls' | 'txt';

export const parseExtension = (extension: string): fileExtensions => {
  switch (extension) {
    case 'jpg' || 'jpeg':
      return 'jpg';
      break;

    case 'png':
      return 'png';
      break;

    case 'xls' || 'xlsx':
      return 'xls';
      break;

    case 'doc' || 'docx':
      return 'docx';
      break;

    case 'txt':
      return 'txt';
      break;

    default:
      return 'xls';
      break;
  }
};

export const lytArrToObj = (arr: any[]): any => {
  return arr.reduce((prev, curr) => {
    const name = Object.keys(curr)[0];
    return {
      ...prev,
      [name]: curr[name],
    };
  }, {});
};

// export const agregateColors = (arr: any[]): any => {
//   let lyt = {};
//   arr.forEach((d: any) => {
//     lyt = {
//       ...lyt,
//       ...lytArrToObj(d.lythology),
//     };
//   });
//   return lyt;
// };

export const dataToTable = (data: any[]) => {
  let index = 0;
  const tableArr: Row[] = [];
  // const colors = agregateColors(data);
  // const lastValue = data[data?.length - 1]?.valueTo;
  // for (let i = 1; i <= lastValue * 10; i += 1) {
  //   tableArr.push({
  //     id: i,
  //     interval: (i / 10).toFixed(1),
  //     color: colors[String((i / 10).toFixed(1))],
  //   });
  // }
  data.forEach((obj, num) => {
    if (num > 0) {
      if (obj.valueTo.toFixed(1) === data[num - 1].valueTo.toFixed(1)) {
        index++;
        tableArr.push({
          id: index,
          interval: obj.valueTo.toFixed(1),
          color: 'white',
          drillingId: obj?.id,
          cruiserNum: obj?.cruisernum,
          boxNumFinish: obj?.boxNumFinish,
          cabinetNumFinish: obj?.cabinetNumFinish,
          valueFrom: obj?.valueFrom,
          valueTo: obj?.valueTo,
          metr: obj?.valueTo - obj?.valueFrom,
          factMetr: obj?.realTo - obj?.realFrom,
          output: obj?.coreOutput,
        });
      }
    }

    obj.lythology.forEach((item) => {
      index++;
      if (
        Math.round(obj.valueTo * 10) / 10 ===
          Math.round(+Object.keys(item)[0] * 10) / 10 &&
        !tableArr.find((item) => item?.valueTo === obj?.valueTo)
      ) {
        tableArr.push({
          id: index,
          interval: Object.keys(item)[0],
          color: String(Object.values(item)[0]),
          drillingId: obj?.id,
          cruiserNum: obj?.cruisernum,
          boxNumFinish: obj?.boxNumFinish,
          cabinetNumFinish: obj?.cabinetNumFinish,
          valueFrom: obj?.valueFrom,
          valueTo: obj?.valueTo,
          metr: obj?.valueTo - obj?.valueFrom,
          factMetr: obj?.realTo - obj?.realFrom,
          output: obj?.coreOutput,
        });
      } else {
        tableArr.push({
          id: index,
          interval: Object.keys(item)[0],
          color: String(Object.values(item)[0]),
        });
      }
    });
  });
  // data.forEach((d) => {
  //   tableArr = tableArr.map((row) => {
  //     if (row.interval) {
  //       if (d.valueTo.toFixed(1) === (+row.interval).toFixed(1)) {
  //         return {
  //           ...row,
  //           drillingId: d?.id,
  //           cruiserNum: d?.cruisernum,
  //           boxNumFinish: d?.boxNumFinish,
  //           cabinetNumFinish: d?.cabinetNumFinish,
  //           valueFrom: d?.valueFrom,
  //           valueTo: d?.valueTo,
  //           metr: d?.valueTo - d?.valueFrom,
  //           factMetr: d?.realTo - d?.realFrom,
  //           output: d?.coreOutput,
  //         };
  //       }
  //     }
  //     return row;
  //   });
  // });
  return tableArr;
};
