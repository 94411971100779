/* eslint-disable prettier/prettier */
export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = (T | U) extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;
export type Not<T> = {
    [P in keyof T]?: void;
};

export const parseFormData = (data: FormData) => {
    Array.from(data.entries(), ([key, prop]) => ({
        [key]: {
          ContentLength: typeof prop === 'string' ? prop.length : prop.size,
        },
    }));
}

const toCamelStr = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const toCamelCase = (o: object | any[]) => {
  if (isObject(o)) {
    const n = {};
    Object.keys(o)
      .forEach((k) => {
        n[toCamelStr(k)] = toCamelCase(o[k]);
    });

    return n;
    } if (isArray(o)) {
      return (o as any).map((i) => {
        return toCamelCase(i);
    });
  }
  return o;
};

export const keysToArrays = (obj: any, level = 0) => {
  const res: any[] = [];
  Object.keys(obj).forEach((key, i) => {
    res.push({
      name: key,
      values: [],
      level,
    });
    if (!Array.isArray(obj[key]) && obj[key]) {
      res[i].values = keysToArrays(obj[key], level + 1)
    } else if (obj[key]) {
      res[i].values = obj[key].map((img) => ({
        ...img,
        level: level + 1
      }))
    }
  });

  return res;
};
