import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { logout } from 'src/store/reducers/auth';
import { setSelectLanguageAct } from 'src/store/reducers/files';
import { Navigation } from '../Navigation';
import { Icon } from '../UI/Icon';
import * as Styled from './styles';

interface HeaderProps {
  className?: string;
}

const Header: React.FC<HeaderProps> = () => {
  const dispatch = useAppDispatch();
  const { login, companiesList } = useAppSelector((state) => state.auth);
  const { selectLanguage } = useAppSelector((state) => state.files);

  const [lang, setLang] = useState<'Ru' | 'En' | string>(selectLanguage);
  const [_isRussian, _setIsRussian] = useState<boolean>(true);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [company, setCompany] = useState<string>('#');

  const toggleLang = () => {
    setLang(lang === 'En' ? 'Ru' : 'En');
    setIsOpened(false);
  };

  useEffect(() => {
    setCompany(companiesList?.find((item) => item.isdefault)?.title || '#');
  }, [companiesList]);

  useEffect(() => {
    dispatch(setSelectLanguageAct(lang));
    localStorage.setItem('selectLanguage', lang);
  }, [lang]);

  return (
    <Styled.Root>
      <Icon
        name={selectLanguage === 'Ru' ? 'logo-2' : 'logo_eng-2'}
        width={80}
        height={35}
      />
      <Navigation />

      <Styled.HeaderMenu>
        <Styled.UserInfo>
          <Styled.UserName>{company}</Styled.UserName>
          <Styled.UserID>{login}</Styled.UserID>
        </Styled.UserInfo>
        <Icon name="account" width={25} height={25} />

        <Styled.ExitIcon
          name="log-out"
          onClick={() => dispatch(logout())}
          width={28}
          height={32}
        />

        <Styled.LangBlock>
          <Icon name="lang" width={8} height={8} />
          {lang}
          <Styled.CustomIcon
            name="chevron-down"
            width={7}
            height={4}
            onClick={() => setIsOpened(!isOpened)}
          />
          {isOpened}
          <Styled.HiddenLang opened={isOpened} onClick={toggleLang}>
            {lang === 'En' ? 'Ru' : 'En'}
          </Styled.HiddenLang>
        </Styled.LangBlock>
      </Styled.HeaderMenu>
    </Styled.Root>
  );
};

export default Header;
