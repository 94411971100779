/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface Props {
    isactive?: boolean;
    shelfColorWidth?: number;
    shelfColorName?: string;
    colors?: string[];
    type?: string;
}

interface MenuProps {
    xpos: number
}

export const Root = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
`;

export const MenuBox = styled.div<MenuProps>`
    width: 140px;
    height: auto;
    z-index: 1000000;
    position: absolute;
    bottom: -4px;
    ${({ xpos }) => css`left: ${xpos}px;`}
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray};
`

export const MenuItems = styled.ul`
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 2px 0;
`

export const MenuItem = styled.li<{color?: string}>`
    width: 100%;
    color: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.typography.text_12_14};
    padding: 0 2px;
    cursor: pointer;
    position: relative;

    ${({ color }) => color && css`
        &::before {
            content: '';
            position: absolute;
            right: 10px;
            width: 10px;
            height: 10px;
            background: ${color};
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    `}

    &:hover {
        background-color: ${({ theme }) => theme.colors.gray};
        .sub-menu {
            display: flex;
        }
    }
`

export const MenuItemMenu = styled.div`
    width: inherit;
    min-height: 16px;
    height: auto;
    z-index: 1000000;
    position: absolute;
    left: 100%;
    display: none;
    top: 0;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray};
`

export const Ruler = styled.div`
    width: 670px;
    height: 25px;

    border: 0.3px solid #286275;
    border-radius: 5px;

    margin: 30px auto;
    margin-bottom: 3px;
    display: flex;
    justify-content: space-around;
    padding: 0 2px;
`;

export const RulerMainText = styled.p`
    margin: auto 0;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.black};
`;

export const RulerSmallText = styled.p`
    font-size: 7px;
    color: ${({ theme }) => theme.colors.black};
`;

export const RulerTextWrapper = styled.div`
    position: relative;
`;

export const RulerSeparator = styled.div`
    position: absolute;
    content: '';
    width: 1px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.black};

    bottom: 0;
    left: 50%;
`;

export const RulerSmallSeparator = styled.div`
    position: absolute;
    content: '';
    width: 0.5px;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.black};

    bottom: 0;
    left: 50%;
`;

export const GradBlock = styled.div<Props>`
    margin-top: -2px;
    border: 1px solid #000;
    width: 660px;
    height: 20px;
    border-radius: 5px;
    display: flex;
    background: linear-gradient(90deg, ${({ colors }) => colors?.join(', ')});
`

export const ColorBlock = styled.div<{ color: string, text?: string, type: string }>`
    width: 33px;
    height: 100%;
    background:  ${({ color }) => color};
    position: relative;
    &::before {
        position: absolute;
        width: 80%;
        height: 18px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        content: '${({ text }) => text}';
        background: #000000bb;
        color: #FFF;
        border-radius: 4px;
        font-size: 10px;
        bottom: -19px;
        transition: all .25s;
        opacity: 0;
    }
   
    &:hover {
        &::before {
            opacity: 1;
        }
    }

    ${({ type }) => type === 'oxides' 
        ? css` width: 100%; `
        : ''
    }
`

export const ShelfWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`;

export const ShelfImg = styled.img`
    width: 660px;
    user-select: none;
`;

export const ImgGroup = styled.div`
    width: 660px;
    height: 90%;
    position: relative;
`

interface CrackProps {
    width: number;
    color: string;
    position: number;
}

interface CoreProps {
    start: number,
    end: number,
    color: string,
}

export const Crack = styled.div<CrackProps>`
    border: 2px solid transparent;
    top: 0;
    height: 90%;
    border-radius: 5px;
    position: absolute;
    z-index: 3;
    top: 50%;
    cursor: move;
    transform: translateY(-50%);

    &:hover {
        & div {
            opacity: 1;
        }
    }

    ${({ width, color, position }) => css`
        width: ${width < 8 ? width + 10 : width}px;
        border-color: ${color};
        left: ${position}px;
    `}
`

export const CrackActionLeft = styled.div`
    position: absolute;
    content: '';
    z-index: 3;
    opacity: 0;
    top: 40%;
    left: -6px;
    width: 4px;
    height: 12px;
    cursor: col-resize;
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.white};
`;

export const CrackActionRight = styled.div`
    position: absolute;
    content: '';
    z-index: 3;
    opacity: 0;
    top: 40%;
    right: -6px;
    width: 4px;
    height: 12px;
    cursor: col-resize;
    border-radius: 3px;
    background: ${({ theme }) => theme.colors.white};
`;

export const Core = styled.div<CoreProps>`
    border: 2px solid transparent;
    top: 0;
    height: 90%;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
        & div {
            opacity: 1;
        }
    }

    ${({ start, end, color }) => css`
        left: ${start}px;
        width: ${end - start}px;
        border-color: ${color};
    `}
`

export const CoreInfo = styled.div<CoreProps>`
    position: absolute;
    background: rgba(0, 0, 0, 0.33);
    border-radius: 5px;
    opacity: 0;
    height: 100%;
    z-index: 2;
    display: flex;

    left: 0px;
    width: 100%;

    // ${({ start, end }) => css`
    //     left: 0px;
    //     width: ${end - start}px;
    // `}
`

export const CoreInfoText = styled.p`
    margin: auto;
    color: ${({ theme }) => theme.colors.white};
    ${({ theme }) => theme.typography.text_12_14};
    font-weight: 600;
    
`

export const ShelfColumnFrom = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-100%, 0);

    min-width: 20px;
    width: auto;
    height: 100%;
    text-align: center;
    display: flex;
    padding: 2px;

    flex-direction: column;
    justify-content: space-between;

    border: 1px solid #286275;
    border-radius: 5px;
`;

export const ShelfColumnRightFrom = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, 0);

    min-width: 20px;
    width: auto;
    height: 100%;
    text-align: center;
    display: flex;
    padding: 2px;

    flex-direction: column;
    justify-content: space-between;

    border: 1px solid #286275;
    border-radius: 5px;
`;

export const ShelfTopNumberFrom = styled.p`
    margin: 0;
    font-size: 12px;
    font-weight: 600;
`;

export const ShelfBottomNumberFrom = styled.p`
    margin: 0;
    font-weight: 600;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.mainRed};
`;

export const ShelfColorWrapper = styled.div`
    width: 660px;
    display: flex;
    margin: 0 auto;
`;

export const ShelfColor = styled.p<Props>`
    height: 20px;
    margin: 3px auto;
    border-radius: 5px;

    ${({ theme, shelfColorWidth, shelfColorName }) =>
    css`
        width: ${shelfColorWidth}%;
        background-color: ${theme.colors[shelfColorName || '']};
    `}
`;
