/* eslint-disable import/no-unresolved */
import React from 'react';
import { useAppSelector } from 'src/hooks/storeHooks';
import { CheckFilesPage } from '../CheckFilesPage';
import { MainUpload } from './InnerPages/MainUpload';
import * as Styled from './styles';

interface InRoute {
  element: JSX.Element;
  name: string;
  path: string;
}

const innerRoutes: InRoute[] = [
  { element: <MainUpload />, name: 'upload', path: '#upload' },
  { element: <CheckFilesPage />, name: 'upload', path: '#check' },
];

const UploadFilesPage: React.FC = () => {
  const { activeSubPath } = useAppSelector((state) => state.navigation);

  return (
    <Styled.Root>
      {
        innerRoutes
          .filter((route) => route.path === activeSubPath)
          .map((route) => route.element)[0]
      }
    </Styled.Root>
  );
};

export default UploadFilesPage;
