/* eslint-disable prefer-template */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
/* eslint-disable unused-imports/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import * as asyncActs from './async';
import {
  addFormData,
  checkValid,
  localLoadFiles,
  clearAll,
  fillDragDrop,
  overrideImgInfo,
  checkImgInfo,
  setImageSetId,
  setForwardRoutes,
  saveSelectTreeItem,
  setProfileType,
  setSelectLanguage,
  setLoadingBarSize,
  setLoadingBar,
  setimgTable,
  setCropType,
  removeError,
  clearImgs,
  clearMine,
  clearWell,
  clearFeilds,
  resetLoadingBar,
  clearImgsTable,
  setImgSetStatus,
  // setModifiedCracks,
  setSelectedCompany,
  selectedTabelsCreated,
  selectedTabelsDeleted,
} from './actions';
import {
  AnalizisRow,
  Character,
  ImageSetAccount,
  InfoFile,
  InfoPhoto,
  IProfile,
  Row,
  TablePhoto,
  TableInfo,
  ILegend,
  InfoError,
} from 'src/store/types';
// eslint-disable-next-line unused-imports/no-unused-imports
import { setActivePathAct, setSubPath } from '../navigation';
import { toCamelCase } from 'src/utils';
import { dataToTable, parseExtension } from 'src/utils/files';
import { _baseURL } from 'src/env';

type stepState = 'valid' | 'invalid';

export interface ITreeItem {
  name?: string;
  url?: string;
  id?: number;
  numBox?: string;
  range?: string;
}

export interface ICrack {
  confidence: number;
  layerColor: string;
  crackType: string;
  id?: number;
  quantity?: number;
  layerInstEndPix: number;
  layerInstPixMiddle: number;
  layerInstStartPix: number;
}

export interface IFormData {
  organization?: string;
  well?: string;
  fieldd?: string;
  mine?: string;
  cabinetNums?: number;
  description?: string;
  organization_name?: string;
}

export interface ITableTitle {
  id: number;
  num: number;
  title: string;
  children?: ITableTitle[];
  sum?: number;
}

export interface IImgTable {
  value: string;
  confidence: number;
  column_inst__id: number;
  color: string;
  edit?: number;
}

// описываем что должно быть у нас в стейте, обязательные и необязательные поля(?)
export interface FilesState {
  selectLanguage: 'Ru' | 'En' | string;
  uploadedFilesNow?: FormData;
  errors: InfoError[];
  typeCrop: string;
  loadingState: 'loading' | 'failed' | 'success';
  loadingBar: boolean;
  loadingBarSize: number;
  filledDrops: boolean[];
  profilesList?: IProfile[];
  listFields?: string[];
  listMine?: string[];
  listWell?: string[];
  defaultProflileID?: number;
  company?: string;
  formData: IFormData;
  imgSetsInfo: IFormData[];
  currStep: number;
  tableInfo?: TableInfo;
  existFiles: string[];
  stepsStates: stepState[];
  imgSetStatus: string;
  images: InfoPhoto[];
  files: InfoFile[];
  selectedCompany: number;
  selectedTabels: number[];
  activeProfile?: number;
  imagesetId?: number;
  imgInfo?: Character[];
  newImgInfo?: InfoPhoto[];
  availableImageSets: ImageSetAccount[];
  firstAvailableImageSetId?: number;
  analizTable: AnalizisRow[];
  globalInfoImgs?: any;
  treeName: string;
  tablePhotos: TablePhoto[];
  table: Row[];
  analizId: number;
  analizMenu: string[];
  legend: ILegend[];
  menuData?: any;
  forwardRoutes?: string[];
  cropedImages: {
    path: string,
    maskUpload: string,
    id: number,
    cacks: ICrack[],
    cstart: number,
    start: number,
    finish: number,
    cfinish: number,
  }[];
  commands: {
    name: string,
    url: string[],
    method: string,
  }[];
  selectedTreeItem: ITreeItem;
  prifileType: string;
  tableTitle: ITableTitle[];
  imgTable: IImgTable[][];
  // modifiedCracks: ICrack[];
}

// инициализируем стейт
const initialState: FilesState = {
  selectLanguage: localStorage.getItem('selectLanguage') || 'Ru',
  loadingState: 'success',
  loadingBar: false,
  loadingBarSize: 0,
  errors: [],
  typeCrop: 'lythology',
  cropedImages: [],
  table: [],
  analizId: 1,
  analizMenu: [],
  treeName: '',
  filledDrops: [false, false],
  profilesList: [],
  listFields: [],
  listMine: [],
  listWell: [],
  formData: {},
  imgSetsInfo: [],
  imgSetStatus: 'Ready',
  selectedCompany: 0,
  selectedTabels: [],
  currStep: 1,
  existFiles: [],
  stepsStates: ['invalid', 'invalid', 'invalid', 'invalid'],
  images: [],
  analizTable: [],
  availableImageSets: [],
  files: [],
  tablePhotos: [],
  legend: [],
  commands: [],
  selectedTreeItem: {},
  prifileType: '',
  tableTitle: [],
  imgTable: [],
  // modifiedCracks: [],
};

export const counterSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    checkValid,
    addFormData,
    localLoadFiles,
    fillDragDrop,
    clearAll,
    checkImgInfo,
    overrideImgInfo,
    setImageSetId,
    setForwardRoutes,
    saveSelectTreeItem,
    setSelectLanguage,
    setLoadingBarSize,
    setProfileType,
    setLoadingBar,
    setimgTable,
    setCropType,
    removeError,
    clearImgs,
    clearMine,
    clearWell,
    clearFeilds,
    resetLoadingBar,
    clearImgsTable,
    setImgSetStatus,
    setSelectedCompany,
    // setModifiedCracks,
    selectedTabelsCreated,
    selectedTabelsDeleted,
  },
  extraReducers: (builder) => {
    // builder.addCase(asyncActs.updateCrack.fulfilled, (state, { payload }) => {
    //   // state.cropedImages = state.cropedImages.map((img) => {
    //   //   if (img.id === payload.itemId) {
    //   //     return {
    //   //       ...img,
    //   //       cacks: img.cacks.map((crack) => {
    //   //         if (crack.id === payload.crack.id) {
    //   //           return payload.crack;
    //   //         }
    //   //         return crack;
    //   //       }),
    //   //     };
    //   //   }
    //   //   return img;
    //   // });
    //   state.loadingState = 'success';
    //   state.modifiedCracks = [];
    // });

    // builder.addCase(asyncActs.updateCrack.rejected, (state, { payload, error }) => {
    //   console.log(error);
    //   state.errors.push({ id: state.errors.length, msg: (payload as any)?.data?.message });
    //   state.loadingState = 'failed';
    // });

    // builder.addCase(asyncActs.addCrack.fulfilled, (state, { payload }) => {
    //   state.cropedImages = state.cropedImages.map((crop) => {
    //     if (crop.id === payload.id) {
    //       crop.cacks.push(payload.newCrack);
    //     }
    //     return crop;
    //   });
    // });

    // builder.addCase(asyncActs.removeCrack.fulfilled, (state, { payload }) => {
    //   state.cropedImages = state.cropedImages.map((crop) => {
    //     if (crop.id === payload.imageId) {
    //       crop.cacks = crop.cacks.filter((cr) => cr.id !== payload.crackId);
    //     }
    //     return crop;
    //   });
    // });

    builder.addCase(asyncActs.callRoute.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.callRoute.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.menuData = payload;
      state.loadingState = 'success';

      if (payload[0].split(' ')[0] === 'deleted') {
        const deletedId = payload[0].split(' ')[1];
        state.availableImageSets = state.availableImageSets.filter((imgSet) => imgSet.id !== +deletedId);
      }
    });

    builder.addCase(asyncActs.uploadFiles.pending, (state) => {
      state.loadingState = 'loading';
      state.imagesetId = 0;
    });

    builder.addCase(asyncActs.callRoute.rejected, (state, { payload, error }) => {
      state.loadingState = 'failed';
      console.log(error);
    });

    builder.addCase(asyncActs.getAnalizMenu.pending, (state) => {
      // state.loadingState = 'loading';
      state.analizMenu = [];
    });

    builder.addCase(asyncActs.getAnalizMenu.fulfilled, (state, { payload }) => {
      console.log(payload);
      const temp: string[] = [];
      if (payload) {
        for (const [key, value] of Object.entries(payload)) {
          if (value === "Ready" && key !== "crop") {
            temp.push(key)
          }
        }
        state.analizMenu = [...temp]
      }
      state.typeCrop = (temp[0] ? temp[0] : '');
      state.loadingState = 'success';
    });

    builder.addCase(asyncActs.getAnalizMenu.rejected, (state, { payload, error }) => {
      state.loadingState = 'failed';
      state.analizMenu = [];
      console.log(error);
    });

    builder.addCase(asyncActs.uploadFiles.rejected, (state, { payload, error }) => {
      console.log(payload);
      state.errors.push({ id: state.errors.length + 1, msg: 'Проверьте пожалуйста все ли поля заполненены' });
      // state.uploadedFilesNow = undefined;
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.uploadFiles.fulfilled, (state, { payload }) => {
      state.imagesetId = payload;
      state.uploadedFilesNow = undefined;
      state.loadingState = 'success';
      setSubPath('#check');
    });

    builder.addCase(asyncActs.sendNewImg.pending, (state) => {
      state.loadingState = 'loading';
      // state.imagesetId = 1;
    });

    builder.addCase(asyncActs.sendNewImg.rejected, (state, { error }) => {
      console.log(error);
      state.errors.push({ id: state.errors.length, msg: 'error' });
      state.uploadedFilesNow = undefined;
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.sendNewImg.fulfilled, (state, { payload }) => {
      state.imagesetId = payload;
      state.uploadedFilesNow = undefined;
      state.loadingState = 'success';
      // setSubPath('#check');
    });

    builder.addCase(
      asyncActs.getImageSetChoises.rejected,
      (state, { error }) => {
        console.log(error);
        state.errors.push({ id: state.errors.length, msg: 'error' });
        // alert('[Ошибка] получение выпадающего меню');
        state.loadingState = 'failed';
      }
    );

    builder.addCase(
      asyncActs.getImageSetChoises.fulfilled,
      (state, { payload }) => {
        state.loadingState = 'success';
        state.commands = [];
        state.commands = payload;
        console.log(state.commands)
      }
    );

    // getProfiles
    builder.addCase(asyncActs.getProfiles.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getProfiles.rejected, (state, { error }) => {
      console.log(error);
      state.errors.push({ id: state.errors.length, msg: 'error' });
      state.profilesList = [];
      // alert('[ошибка] получение профилей');
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getProfiles.fulfilled, (state, action) => {
      console.log('getProfiles: ', action.payload);

      // state.profilesList = action.payload?.listProfiles;
      state.profilesList = action.payload;
      state.defaultProflileID = action.payload.find((item) => item.isDefault === true)?.id || -1;
      // state.company = action.payload?.company;
      state.formData.cabinetNums = action.payload?.cabinetNums;
      state.loadingState = 'success';

      action.payload?.forEach((profile) => {
        if (profile.isDefault) {
          state.prifileType = profile.profileType
            .replace('(', '')
            .replace(/'()/g, '')
            .split(', ')[0];
        }
      });

      if (state.prifileType === '') {
        state.prifileType = action.payload[0].profileType;
      }

      // action.payload?.listProfiles?.forEach((profile) => {
      //   if (profile.id === action.payload?.defaultProfile) {
      //     state.prifileType = profile.type
      //       .replace('(', '')
      //       .replace(/'()/g, '')
      //       .split(', ')[0];

      //     console.log(state.prifileType);
      //   }
      // });
    });

    builder.addCase(asyncActs.getListField.pending, (state) => {
      // state.loadingState = 'loading';
      state.listFields = [];
      state.listMine = [];
      state.listWell= [];
    });

    builder.addCase(asyncActs.getListField.rejected, (state) => {
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getListField.fulfilled, (state, action) => {
      state.listFields = action.payload;
      state.loadingState = 'success';
    })

    builder.addCase(asyncActs.getListMine.pending, (state) => {
      // state.loadingState = 'loading';
      state.listMine = [];
      state.listWell= [];
    });

    builder.addCase(asyncActs.getListMine.rejected, (state) => {
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getListMine.fulfilled, (state, action) => {
      state.listMine = action.payload;
      state.loadingState = 'success';
    })

    builder.addCase(asyncActs.getListWell.pending, (state) => {
      // state.loadingState = 'loading';
      state.listWell= [];
    });

    builder.addCase(asyncActs.getListWell.rejected, (state) => {
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getListWell.fulfilled, (state, action) => {
      state.listWell = action.payload;
      state.loadingState = 'success';
    })

    builder.addCase(asyncActs.getAllImgSets.pending, (state) => {
      state.loadingState = 'loading';
      state.imgSetsInfo= [];
    });

    builder.addCase(asyncActs.getAllImgSets.rejected, (state) => {
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getAllImgSets.fulfilled, (state, action) => {
      state.imgSetsInfo = action.payload;
      // console.log(action.payload)
      state.loadingState = 'success';
    })

    builder.addCase(asyncActs.getCropedImages.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getCropedImages.rejected, (state, { error }) => {
      console.log(error);
      state.errors.push({ id: state.errors.length, msg: 'error' });
      // state.cropedImages = [];
      state.loadingState = 'failed';
    });

    builder.addCase(
      asyncActs.getCropedImages.fulfilled,
      (state, { payload }) => {
        payload = toCamelCase(toCamelCase(payload));
        // state.cropedImages = [];
        console.log(payload);
        if (payload) {
          state.cropedImages = payload.map((d) => ({
            id: d.id,
            cstart: d.cstart,
            start: d.start,
            finish: d.finish,
            cfinish: d.cfinish,
            quantity: d.quantity,
            maskUpload: `${_baseURL}/media/${d.maskUpload}`,
            path: `${_baseURL}${d.cabinetUpload}`,
            cacks: d.items,
          }));
        }
        state.loadingState = 'success';
      }
    );

    // Get info
    builder.addCase(asyncActs.getFilesInfo.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getFilesInfo.rejected, (state, { error }) => {
      console.log(error);
      state.errors.push({ id: state.errors.length, msg: 'error' });
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getFilesInfo.fulfilled, (state, { payload }) => {
      state.files = [];
      let cmFiles: InfoFile[] = payload?.files?.map(toCamelCase);

      cmFiles = cmFiles?.map((file) => {
        const spDesc = file.fileDescription.split('.');
        return {
          ...file,
          ext: parseExtension(spDesc[spDesc.length - 1]),
        };
      });
      state.files = cmFiles;
      state.loadingState = 'success';
    });

    builder.addCase(asyncActs.getImgsInfo.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getImgsInfo.rejected, (state, { error }) => {
      console.log(error);
      state.errors.push({ id: state.errors.length, msg: 'error' });
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getImgsInfo.fulfilled, (state, { payload }) => {
      state.images = [];
      console.log(payload.storage);
      const mainInfo: any[] = payload.storage.map((field) => ({
        value: field[Object.keys(field)[0]],
        name: Object.keys(field)[0],
      }));

      let cmImages: InfoPhoto[] = payload?.images?.map(toCamelCase);
      state.globalInfoImgs = { ...payload, storage: mainInfo };
      cmImages = cmImages?.map((img) => {
        const sPath = img.upload.split('/');
        return {
          ...img,
          name: sPath[sPath.length - 1],
        };
      });
      state.images = cmImages;
      state.tableTitle = payload?.colomn_titles2;
      state.imgTable = [];


      state.prifileType = state.availableImageSets?.find(
        (item) => item.id === payload.id
      )?.typeDownloadprofile || state.prifileType;
        
      state.loadingState = 'success';
    });

    builder.addCase(asyncActs.getLegend.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getLegend.fulfilled, (state, { payload }) => {
      state.legend = toCamelCase(payload.data);
    });

    // Remove Images
    builder.addCase(asyncActs.removeImages.fulfilled, (state, action) => {
      action.payload.forEach(({ choised, id }) => {
        if (choised) {
          state.images = state.images.filter((img) => img.id !== id);
        }
      });
      state.loadingState = 'success';
    });

    builder.addCase(asyncActs.removeImages.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.updateImgData.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.updateImgData.fulfilled, (state) => {
      state.loadingState = 'success';
    });

    builder.addCase(asyncActs.updateImgTable.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.updateImgTable.fulfilled, (state) => {
      state.loadingState = 'success';
    });

    builder.addCase(asyncActs.getImageSets.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getImageSets.rejected, (state, { error }) => {
      console.log(error);
      state.errors.push({ id: state.errors.length, msg: 'error' });
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getImageSets.fulfilled, (state, { payload }) => {
      state.loadingState = 'success';
      state.availableImageSets = payload?.map(toCamelCase)?.map((imset) => ({
        ...imset,
        status: imset.status,
      }));
      state.firstAvailableImageSetId = state?.availableImageSets[0]?.id;
    });

    builder.addCase(asyncActs.getImgTable.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getImgTable.rejected, (state) => {
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getImgTable.fulfilled, (state, { payload }) => {
      state.loadingState = 'success';
      state.imgTable = payload;
      // console.log(payload);
    });

    builder.addCase(asyncActs.getDownloadFileCSV.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getDownloadFileCSV.rejected, (state) => {
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getDownloadFileCSV.fulfilled, (state, { payload }) => {
      state.loadingState = 'success';
      console.log(payload);

      const binaryData: any = [];
      binaryData.push(payload);
      const url = window.URL.createObjectURL(
        new Blob(binaryData, { type: 'csv' })
      );

      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'table.csv';
      document.body.append(anchor);
      // anchor.style = "display: none";
      anchor.click();
      anchor.remove();
    });

    builder.addCase(asyncActs.getDownloadFileXLSX.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getDownloadFileXLSX.rejected, (state) => {
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getDownloadFileXLSX.fulfilled, (state, { payload }) => {
      state.loadingState = 'success';
      console.log(payload.xls_url);

      const binaryData: any = [];
      binaryData.push(payload);
      // const url = window.URL.createObjectURL(
      //   new Blob(binaryData, { type: 'xlsx' })
      // );

      const anchor = document.createElement('a');
      anchor.href = _baseURL + payload.xls_url;
      anchor.download = 'table.xlsx';
      document.body.append(anchor);
      // anchor.style = "display: none";
      anchor.click();
      anchor.remove();
    });

    builder.addCase(asyncActs.getDownloadFileTXT.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getDownloadFileTXT.rejected, (state) => {
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getDownloadFileTXT.fulfilled, (state, { payload }) => {
      state.loadingState = 'success';
      console.log(payload);

      const binaryData: any = [];
      binaryData.push(payload);
      const url = window.URL.createObjectURL(
        new Blob(binaryData, { type: 'txt' })
      );

      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'table.txt';
      document.body.append(anchor);
      // anchor.style = "display: none";
      anchor.click();
      anchor.remove();
    });

    builder.addCase(asyncActs.downloadKernXlsx.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.downloadKernXlsx.rejected, (state) => {
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.downloadKernXlsx.fulfilled, (state, { payload }) => {
      state.loadingState = 'success';
      console.log(payload.xls_url);

      const binaryData: any = [];
      binaryData.push(payload);

      const anchor = document.createElement('a');
      anchor.href = _baseURL + payload.xls_url;
      anchor.download = 'table.xlsx';
      document.body.append(anchor);
      anchor.click();
      anchor.remove();
    });

    builder.addCase(asyncActs.getAnaliz.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getAnaliz.rejected, (state, { error }) => {
      console.log(error);
      state.table = [];
      state.errors.push({ id: state.errors.length, msg: 'error' });
      state.loadingState = 'failed';
    });

    builder.addCase(asyncActs.getAnaliz.fulfilled, (state, { payload }) => {
      const {
        cruiseDrilling,
        id,
        downloadProf,
        imgSet,
        usercreated,
        status,
        typeAnalize,
        typeChoice,
      } = toCamelCase(payload);
      state.tableInfo = {
        id,
        typeChoice,
        downloadProf,
        imgSet,
        usercreated,
        status,
        typeAnalize,
      };

      state.analizId = payload.img_set_inst;
      state.table = dataToTable(cruiseDrilling || []);
      state.loadingState = 'success';
    });

    builder.addCase(asyncActs.getTree.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.getTree.fulfilled, (state, { payload }) => {
      state.tablePhotos = payload.photos?.map(toCamelCase) || [];
      state.treeName = payload.name || '';
      state.loadingState = 'success';

      // state.selectedTreeItem = state.tablePhotos[0];
    });

    builder.addCase(
      asyncActs.getTree.rejected,
      (state, { payload, error }) => {
        console.log(error);
        state.tablePhotos = [];
        state.loadingState = 'failed';
      }
    );

    builder.addCase(
      asyncActs.updateTable.rejected,
      (state, { payload, error }) => {
        console.log(error);
        state.errors.push({ id: state.errors.length, msg: (payload as any)?.message });
        state.loadingState = 'failed';
      }
    );

    builder.addCase(asyncActs.updateTable.pending, (state) => {
      state.loadingState = 'loading';
    });

    builder.addCase(asyncActs.updateTable.fulfilled, (state, { payload }) => {
      state.analizId = payload.id;
      payload = toCamelCase(payload);
      state.table = dataToTable(payload.cruiseDrilling || []);

      state.loadingState = 'success';
    });
  },
});

// экспортишь все actions
export const {
  setImageSetId: setImageSetIdAct,
  checkValid: checkValidAct,
  addFormData: addFormDataAct,
  localLoadFiles: localLoadFilesAct,
  clearAll: clearAllAct,
  fillDragDrop: fillDragDropAct,
  overrideImgInfo: overrideImgInfoAct,
  checkImgInfo: checkImgInfoAct,
  setForwardRoutes: setForwardRoutesAct,
  saveSelectTreeItem: saveSelectTreeItemAct,
  setSelectLanguage: setSelectLanguageAct,
  setLoadingBarSize: setLoadingBarSizeAct,
  setProfileType: setProfileTypeAct,
  setLoadingBar: setLoadingBarAct,
  setimgTable: setimgTableAct,
  setCropType: setCropTypeAct,
  removeError: removeErrorAct,
  clearImgs: clearImgsAct,
  clearMine: clearMineAct,
  clearWell: clearWellAct,
  clearFeilds: clearFeildsAct,
  clearImgsTable: clearImgsTableAct,
  setImgSetStatus: setImgSetStatusAct,
  resetLoadingBar: resetLoadingBarAct,
  // setModifiedCracks: setModifiedCracksAct,
  setSelectedCompany: setSelectedCompanyAct,
  selectedTabelsCreated: selectedTabelsCreatedAct,
  selectedTabelsDeleted: selectedTabelsDeletedAct,
} = counterSlice.actions;
export default counterSlice.reducer;
