import { createSlice } from '@reduxjs/toolkit';
import { setActiveSubPath, setActivePath, setActiveId } from './actions';

export interface NavState {
  activePath: string;
  activeSubPath?: string;
  activeId?: number;
}

const initialState: NavState = {
  activePath: '/login',
  activeSubPath: '',
};

export const navSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setActiveSubPath,
    setActivePath,
    setActiveId,
  },
});

export const {
  setActiveSubPath: setSubPath,
  setActivePath: setActivePathAct,
  setActiveId: setActiveIdAct,
} = navSlice.actions;

export default navSlice.reducer;
