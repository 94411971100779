/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-template */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { setActiveIdAct, setActivePathAct, setSubPath } from 'src/store/reducers/navigation';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { callRoute, getAnalizMenu, getImageSetChoises, getImageSets, getListField, getListMine, getListWell } from 'src/store/reducers/files/async';
import { clearFeildsAct, clearMineAct, clearWellAct, setCropTypeAct, setForwardRoutesAct, setImageSetIdAct, setImgSetStatusAct, setSelectedCompanyAct } from 'src/store/reducers/files';
import { Loading } from 'src/components/UI/Loading';
import { Icon } from 'src/components/UI/Icon';
import { isConstructorDeclaration } from 'typescript';
import { getCompanies } from 'src/store/reducers/auth';
import { clearFeilds } from 'src/store/reducers/files/actions';


interface TableItem {
  name: string;
  uploadedImgs: string;
  uploadedFiles: string;
  status: string;
  uploadDate: string;
  id: number;
  typeDownloadprofile: string;
  menuOpened: boolean;
  choicesAlgorythm: string;
}

const algorythmsMap = new Map([
  ["core output", 'выход керна'],
  ["coreoutput", 'выход керна'],
  ["oxidation state", 'cтепень окисления'],
  ["crack types", 'типы трещин'],
  ["crack inclination angle", 'угол наклона трещины'],
  ["lithotypes", 'литотипы'],
  ["sulfides", 'сульфиды'],
  ["RQD", "RQD"],
]);

const Home: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const { companiesList, authKey } = useAppSelector((state) => state.auth)
  const { availableImageSets, loadingState, commands, selectLanguage, selectedCompany, listFields, listMine, listWell } = useAppSelector((state) => state.files);
  const [tableItems, setTableItems] = useState<TableItem[]>([])
  const [currOrganization, setCurrOrganization] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [selectedOrg, setSelectedOrg] = useState<number>(selectedCompany);
  const [selectedField, setSelectedField] = useState<string>('');
  const [selectedMine, setSelectedMine] = useState<string>('');
  const [selectedWell, setSelectedWell] = useState<string>('');
  const [selectedTreeItem, setSelectedTreeItem] = useState<string | number>(selectedCompany)

  const menuRefs = useRef([])
  const dispatch = useAppDispatch();

  const HeaderTitles: string[] = [
    selectLanguage === "Ru" ? 'Название' : 'Name',
    selectLanguage === "Ru" ? 'Загруженно изображений' : 'Images uploaded',
    selectLanguage === "Ru" ? 'Загруженно дополнительных файлов' : 'Additional files uploaded',
    selectLanguage === "Ru" ? 'Характеристики' : 'Specifications',
    selectLanguage === "Ru" ? 'Статус' : 'Status',
    selectLanguage === "Ru" ? 'Дата загрузки' : 'Date of download',
  ];

  const toggleItem = (id: number) => {
    setTableItems(tableItems.map((imSet) => {
      if (imSet.id === id) {
        return {
          ...imSet,
          menuOpened: !imSet.menuOpened
        }
      } 
      return {
        ...imSet,
        menuOpened: false,
      }
    }))
  }

  useEffect(() => {
    setCurrOrganization(
      companiesList?.find((item) => item.isdefault === true)?.title || ''
    );
    dispatch(
      setSelectedCompanyAct(
        companiesList?.find((item) => item.isdefault === true)?.id || 0
      )
    );
    // console.log(selectedCompany);
  }, [authKey, companiesList]);

  useEffect(() => {
    dispatch(getImageSets({ key: authKey || '', field: selectedField, mine: selectedMine, well: selectedWell}))
  }, [selectedCompany, selectedField, selectedMine, selectedWell, authKey])

  useEffect(() => {
    const algorythms: string[] = [];
    availableImageSets.forEach((imgSet) => {
      const tempArr: any[] = [];
      if (Array.isArray(imgSet.choicesAlgorythm)) {
        imgSet.choicesAlgorythm.forEach((item) => {
          tempArr.push(selectLanguage === 'Ru' ? algorythmsMap.get(item.value) : item.value)
        });
      }
      algorythms.push(tempArr.join(', '));
    });

    setTableItems(availableImageSets.map((imgSet, i) => ({
      name: imgSet.name,
      uploadedImgs: imgSet.countPhotos,
      uploadedFiles: imgSet.countFiles,
      choicesAlgorythm: algorythms[i],
      status: imgSet.status,
      uploadDate: imgSet.date.slice(0, 10) + ', ' + imgSet.date.slice(11, 16),
      id: imgSet.id,
      typeDownloadprofile: imgSet.typeDownloadprofile,
      menuOpened: false
    })))
  }, [availableImageSets, menuRefs, selectLanguage, selectedCompany, authKey])

  useEffect(() => {
    dispatch(getCompanies(authKey));
  }, [authKey]);

  useEffect(() => {
    console.log(commands);
  }, [commands])

  if (loadingState === 'loading') {
    return <Loading active />;
  }

  const routePage = (id: number) => {
    dispatch(setActivePathAct('/upload'))
    dispatch(setSubPath("#check"))
    dispatch(setImageSetIdAct(id))
    dispatch(setActiveIdAct(id))
  }

  const goToСharacteristics = (id: number) => {
    dispatch(setActivePathAct(`/check-lito/${id}`))
  }

  // const closeAll = () => {
  //   setTableItems(tableItems.map((item) => ({ ...item, menuOpened: false })))
  //   console.log('Close all');
  // }

  const loadChoises = (imageSetId: number) => {
    toggleItem(imageSetId);
    
    if (!tableItems.find((v) => v.id === imageSetId)?.menuOpened) {
      dispatch(getImageSetChoises({ key: authKey || '', imageSetId }))
    }
  }

  return (
    <Styled.Root>
      <Styled.Wrapper>

        <Styled.Tree>
          {companiesList?.map((item) => {
            if (selectedOrg === item.id) {
              return(
                <>
                  <Styled.TreeOrganization 
                    onClick={() => {
                      setSelectedOrg(selectedOrg !== item.id ? item.id : 0);
                      dispatch(setSelectedCompanyAct(0));
                      dispatch(getListField({ key: authKey }))
                      setSelectedField('')
                      setSelectedMine('')
                      setSelectedWell('')
                    }}
                    isactive={selectedTreeItem === item.id}
                  >
                    <Styled.TreeOrganizationIcon name='chevron-down' width={6} height={6} isactive/>
                    <Styled.TreeOrganizationText>
                      {item.title}
                    </Styled.TreeOrganizationText>
                  </Styled.TreeOrganization>
                  {listFields?.map((field) => {
                    if (selectedField === field) {
                      return(
                        <>
                          <Styled.TreeField 
                            onClick={() => {
                              setSelectedField(selectedField !== field ? field : '');
                              dispatch(getListMine({ key: authKey, field }))
                              setSelectedTreeItem(item.id)
                              setSelectedMine('')
                              setSelectedWell('')
                            }}
                            isactive={selectedTreeItem === field}
                          >
                            <Styled.TreeFieldIcon name='chevron-down' width={6} height={6} isactive/>
                            <Styled.TreeFieldText>
                              {field}
                            </Styled.TreeFieldText>
                          </Styled.TreeField>
                          {listMine?.map((mine) => {
                            if (selectedMine === mine && selectedMine !== '') {
                              return (
                                <>
                                   <Styled.TreeMine 
                                      onClick={() => {
                                        setSelectedMine(selectedMine !== mine ? mine : '');
                                        dispatch(getListWell({ key: authKey, field, mine }))
                                        setSelectedTreeItem(field)
                                        setSelectedWell('')
                                      }}
                                      isactive={selectedTreeItem === mine}
                                    >
                                      <Styled.TreeMineIcon name='chevron-down' width={6} height={6} isactive/>
                                      <Styled.TreeMineText>
                                        {mine}
                                      </Styled.TreeMineText>
                                    </Styled.TreeMine>
                                    {listWell?.map((well) => {
                                      return (
                                        <Styled.TreeWell 
                                          onClick={() => {
                                            setSelectedWell(selectedWell !== well ? well : '')
                                            setSelectedTreeItem(selectedTreeItem !== well ? well : mine) 
                                          }} 
                                          isactive={selectedTreeItem === well}
                                        >
                                          <Styled.TreeWellIcon 
                                            name='chevron-down' 
                                            isactive={selectedWell === well}
                                            width={6} 
                                            height={6}
                                          />
                                          <Styled.TreeWellText>
                                            {well}
                                          </Styled.TreeWellText>
                                        </Styled.TreeWell>
                                      )
                                    })}
                                </>
                              )
                            }
                            if (listMine[0] !== '') {
                              console.log(listMine);
                              return (
                                <Styled.TreeMine 
                                  onClick={() => {
                                    setSelectedMine(selectedMine !== mine ? mine : '');
                                    dispatch(getListWell({ key: authKey, field, mine }))
                                    setSelectedTreeItem(mine)
                                    setSelectedWell('')
                                  }}
                                >
                                  <Styled.TreeMineIcon name='chevron-down' width={6} height={6} />
                                  <Styled.TreeMineText>
                                    {mine}
                                  </Styled.TreeMineText>
                                </Styled.TreeMine>
                              )
                            } 
                            return (
                              <>
                                {listWell?.map((well) => {
                                  return (
                                    <Styled.TreeWell 
                                      onClick={() => {setSelectedWell(selectedWell !== well ? well : '')}}
                                      isactive={selectedTreeItem === well}
                                    >
                                      <Styled.TreeWellIcon 
                                        name='chevron-down' 
                                        isactive={selectedWell === well}
                                        width={6}
                                        height={6} 
                                      />
                                      <Styled.TreeWellText>
                                        {well}
                                      </Styled.TreeWellText>
                                    </Styled.TreeWell>
                                  )
                                })}
                              </>
                            )
                          })}
                        </>
                      )
                    }
                    return(
                      <Styled.TreeField 
                        onClick={() => {
                          setSelectedField(selectedField !== field ? field : '');
                          dispatch(getListMine({ key: authKey, field }))
                          setSelectedTreeItem(field)
                          setSelectedMine('')
                          setSelectedWell('')
                        }}
                        isactive={selectedTreeItem === field}
                      >
                        <Styled.TreeFieldIcon name='chevron-down' width={6} height={6} />
                        <Styled.TreeFieldText>
                          {field}
                        </Styled.TreeFieldText>
                      </Styled.TreeField>
                    )
                  })}
                </>
              )
            } 
              return (
                <Styled.TreeOrganization 
                    onClick={() => {
                      setSelectedOrg(selectedOrg !== item.id ? item.id : 0);
                      dispatch(setSelectedCompanyAct(item.id));
                      dispatch(getListField({ key: authKey }))
                      setSelectedTreeItem(item.id)
                      setSelectedField('')
                      setSelectedMine('')
                      setSelectedWell('')
                    }}
                  >
                    <Styled.TreeOrganizationIcon name='chevron-down' width={6} height={6} />
                    <Styled.TreeOrganizationText>
                      {item.title}
                    </Styled.TreeOrganizationText>
                  </Styled.TreeOrganization>
              )
            
          })}
        </Styled.Tree>

        <Styled.Table>
          <Styled.TableHeader>
            {HeaderTitles.map((title) => (
              <Styled.TableTextWrapper>
                <Styled.HeaderText key={title}>{title}</Styled.HeaderText>
              </Styled.TableTextWrapper>
            ))}

            <Styled.MenuWrapper onClick={() => setActiveMenu(!activeMenu)}>
              <Styled.Menu />
              <Styled.MenuList isactive={activeMenu}>
                <Styled.MenuItem
                  onClick={() => routePage(1)}
                >
                  <Styled.MenuItemText>
                    {selectLanguage === 'Ru'
                      ? "Добавить проект"
                      : "Add project"
                    }
                  </Styled.MenuItemText>
                </Styled.MenuItem>
              </Styled.MenuList>
            </Styled.MenuWrapper>
          </Styled.TableHeader>

          <Styled.TableBody>
            {tableItems.map((imagSet) => (
              <Styled.RowWrapper key={imagSet.id}>
                {Object.keys(imagSet)
                  .slice(0, 6)
                  .map((key) => (
                    <Styled.RowItemBox
                      key={key}
                      onClick={() => {
                        dispatch(setImageSetIdAct(imagSet.id))
                        dispatch(setImgSetStatusAct(imagSet.status));
                        dispatch(getAnalizMenu({ key: authKey || '', imageSetId: imagSet.id }))

                        if (imagSet.typeDownloadprofile !== 'ocr' && imagSet.status !== 'Ready') {
                          goToСharacteristics(imagSet.id)
                        } else {
                          dispatch(setForwardRoutesAct(undefined))
                          routePage(imagSet.id)
                        }
                      }}
                    >
                      <Styled.RowItem key={key} type={key}>
                        {imagSet[key]}
                      </Styled.RowItem>
                    </Styled.RowItemBox>
                ))}

                <Styled.MenuWrapper 
                  typeMenu="Row" 
                  onClick={() => {
                    loadChoises(imagSet.id);
                    dispatch(setImgSetStatusAct(imagSet.status));
                    dispatch(getAnalizMenu({ key: authKey || '', imageSetId: imagSet.id }))
                  }}
                >
                  <Styled.Menu
                    typeMenu="Row"
                  />
                  <Styled.MenuList isactive={imagSet.menuOpened} typeMenu="Row">
                    {
                      commands.map(({name, url, method}) => (
                        <Styled.MenuItem 
                          key={name}
                          isactive={!!method}
                          onClick={() => {
                            dispatch(setImageSetIdAct(imagSet.id))

                            if (url[0].split('/')[1] === 'get-imgs-list' || url[0].split('/')[1] === 'get-imgs-list-ocr') {
                              dispatch(setActivePathAct('upload#check'))
                              dispatch(setSubPath('#check'))
                            }
                            else if (url[0].split('/')[1] === 'analize') {
                              dispatch(setActivePathAct(`check-lito/${imagSet.id}`))
                            }

                            if (method) {
                              dispatch(callRoute({ key: authKey || '', method, imagSet: imagSet.id || 0, routes: url}));
                            }
                          }}
                        >
                          <Styled.MenuItemText isactive={!!method}>
                            {method ? name : `${name} in process`}
                          </Styled.MenuItemText>
                        </Styled.MenuItem>
                      ))
                    }
                  </Styled.MenuList>
                </Styled.MenuWrapper>
              </Styled.RowWrapper>
            ))}
          </Styled.TableBody>
        </Styled.Table>
      </Styled.Wrapper>
    </Styled.Root>
  );
};

export default Home;
