/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { stylesType } from 'src/styles/theme/baseVariables';
import { useTheme } from 'styled-components';
import { Icon } from '../UI/Icon';
import * as Styled from './styles';
import Play from './Play';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { logIn } from 'src/store/reducers/auth';
import { setSelectLanguageAct } from 'src/store/reducers/files';

interface DemoAccessProps {
  className?: string;
}

interface Link {
  text: string;
  path: string;
}

interface ErrorField {
  name: string;
  isInvalid: boolean
}

const DemoAccess: React.FC<DemoAccessProps> = ({ className }) => {
  const dispatch = useAppDispatch();

  const { selectLanguage } = useAppSelector((state) => state.files);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [lang, setLang] = useState<'Ru' | 'En' | string>(selectLanguage);
  const [_isRussian, _setIsRussian] = useState<boolean>(true);
  const [isOpened, setIsOpened] = useState<boolean>(false);

  // eslint-disable-next-line prettier/prettier
  const theme = useTheme() as stylesType; // рекомендую не юзать
  const [isPassword, setIsPassword] = useState<boolean>(true);
  const [errorFields, setErrorFields] = useState<ErrorField[]>([
    { name: 'email', isInvalid: false },
    { name: 'password', isInvalid: false }
  ]) // проверяем валидность полей

  const links: Link[] = [
    { text: selectLanguage === "Ru" ? 'О системе' : 'About the system', path: '#' },
    { text: selectLanguage === "Ru" ? 'Регистрация' : 'Registration', path: '#' },
    { text: selectLanguage === "Ru" ? 'Помощь' : 'Help', path: '#' },
  ];

  const emaiRegexp = RegExp(/.+@.+\..+/i); // рег для проверки правильности почты по типу: xxxx@xxx.xx

  useEffect(() => {
    dispatch(setSelectLanguageAct(lang))
    localStorage.setItem('selectLanguage', lang);
  }, [lang])

  // выполняет проверку когда юзер покидает поле ввода
  const onLeaveInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const { name, value } = e.target;
    setErrorFields(errorFields.map((field) => field.name !== name ? field : ({
      ...field,
      isInvalid: !emaiRegexp.test(value) || value.length === 0
    })));
  }

  // сбрасывает проверку когда юзер заходит на это поле
  const onFocusInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const { name } = e.target;
    setErrorFields(errorFields.map((field) => field.name !== name ? field : ({
      ...field,
      isInvalid: false
    })));
  }

  const toggleLang = () => {
    setLang(lang === "En" ? "Ru" : "En")
    setIsOpened(false)
  }

  return (
    <Styled.Root className={className}>
      <Styled.Header>
        {links.map((link, id) => (
          <Styled.Link key={id} href={link.path}>
            {link.text}
          </Styled.Link>
        ))}

        <Styled.LangBlock>
          <Icon name="lang" width={8} height={8} />
          {lang}
          <Styled.CustomIcon
            name="chevron-down"
            width={7}
            height={4}
            onClick={() => setIsOpened(!isOpened)}
          />
          {isOpened}
          <Styled.HiddenLang opened={isOpened} onClick={toggleLang}>
            {lang === "En" ? "Ru" : "En"}
          </Styled.HiddenLang>
        </Styled.LangBlock>
      </Styled.Header>

      <Styled.Wrapper>
        <Styled.LoginBlock>
          <Styled.LoginTitle>
            {selectLanguage === 'Ru'
              ? "Демо-доступ"
              : "Demo-access"
            }
          </Styled.LoginTitle>
        </Styled.LoginBlock>
        <Styled.LoginForm>
          <Styled.LoginGroup>
            <Styled.LoginLabel htmlFor='log-input-1'>
              {selectLanguage === 'Ru'
                ? "Введите код доступа к демо-версии"
                : "Enter the access code for the demo-version"
              }
            </Styled.LoginLabel>
            <Styled.LoginInputGroup>
              <Styled.LoginInput
                onChange={(e) => setPassword(e.target.value)}
                type={isPassword ? 'password' : 'text'}
                id='log-input-1'
                name="password"
              />
              <Styled.HideButton type="button" onClick={() => setIsPassword(!isPassword)}>
                <Icon name="eye" width={12} height={12} color={theme.colors.bgGray} />
              </Styled.HideButton>
            </Styled.LoginInputGroup>
          </Styled.LoginGroup>

          <Styled.LoginGroup>
            <Styled.LoginLabel htmlFor='log-input-2'>
              {selectLanguage === 'Ru'
                ? "Введите адрес электронной почты"
                : "Enter your email address"
              }
            </Styled.LoginLabel>
            <Styled.LoginInput
              id='log-input-2'
              name="email"
              onBlur={onLeaveInput}
              onFocus={onFocusInput}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={
                errorFields.find((item) => item.name === 'email')?.isInvalid
              }
            />
          </Styled.LoginGroup>

          <Styled.LoginButton 
            type='button'
            onClick={() => dispatch(logIn({ email, password }))}
          >
            {selectLanguage === 'Ru'
              ? "Войти"
              : "Enter"
            }
          </Styled.LoginButton>
          <Styled.LoginHint>
            {selectLanguage === 'Ru'
              ? "Проблемы с доступом?"
              : "Problems with access?"
            }
          </Styled.LoginHint>
        </Styled.LoginForm>
      </Styled.Wrapper>

      <Styled.PlayGroup>
        <Play />
        <Styled.PlayTitle>
          {selectLanguage === 'Ru'
            ? "Как это работает?"
            : "How does it work?"
          }
        </Styled.PlayTitle>
      </Styled.PlayGroup>
    </Styled.Root>
  );
};

export default DemoAccess;
