/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import * as Styled from './app.styles';
import { light } from './styles/theme';
import { GlobalStyle } from './styles/theme/globalStyles';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { AutorizationPage } from './routes/AutorizationPage';
import { UploadFilesPage } from './routes/UploadFilesPage';
import { useAppSelector } from './hooks/storeHooks';
import { Loading } from './components/UI/Loading';
import { Home } from './routes/Home';
import { CheckLito } from './routes/CheckLito';
import { CheckFilesPage } from './routes/CheckFilesPage';
import { Header } from './components/Header';
// import { Navigation } from './components/Navigation';
import { useDispatch } from 'react-redux';
import { setActivePathAct } from './store/reducers/navigation';
import { Administration } from './routes/Administration';
import { clearImgsAct } from './store/reducers/files';
// import { ErrorLog } from './components/UI/ErrorLog';

interface IRoute {
  path: string;
  name: string;
  element?: JSX.Element;
}

const routes: IRoute[] = [
  {
    path: '/login',
    name: 'auth',
    element: <AutorizationPage />,
  },
  {
    path: '/upload',
    name: 'upload',
    element: <UploadFilesPage />,
  },
  {
    path: '/home',
    name: 'home',
    element: <Home />,
  },
  {
    path: '/check-lito/:id',
    name: 'checkLito',
    element: <CheckLito />,
  },
  {
    path: '/check',
    name: 'check',
    element: <CheckFilesPage />,
  },
  {
    path: '/settings',
    name: 'settings',
    element: <Administration />,
  },
];

function App() {
  const [_showHint, _setShowHint] = useState<boolean>(false);
  const { isLogged, loadingState } = useAppSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { activePath, activeSubPath } = useAppSelector(
    (state) => state.navigation
  );
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogged) {
      if (pathname !== '/login') dispatch(setActivePathAct('/login'));
    } else if (isLogged && (pathname === '/login' || pathname === '/')) {
      dispatch(setActivePathAct('/home'));
    }

    dispatch(clearImgsAct());
  }, [navigate, pathname, isLogged, hash]);

  useEffect(() => {
    if (activePath === '/upload') {
      navigate(activePath + activeSubPath);
    } else {
      navigate(activePath);
    }
  }, [activePath, activeSubPath]);

  useEffect(() => {
    console.log(loadingState);
  }, [loadingState]);

  return (
    <ThemeProvider theme={light}>
      <GlobalStyle />
      <Loading active={loadingState === 'loading'} />
      {/* <ErrorLog /> */}
      {isLogged ? (
        <>
          <Header />
          <Styled.Wrapper logged={isLogged}>
            {/* <Navigation /> */}
            <Routes>
              {routes.map((route: IRoute) => (
                <Route
                  path={route.path}
                  element={route.element}
                  key={route.name}
                />
              ))}
            </Routes>
          </Styled.Wrapper>
        </>
      ) : (
        <Routes>
          <Route path="/login" element={<AutorizationPage />} />
        </Routes>
      )}
    </ThemeProvider>
  );
}

export default App;
