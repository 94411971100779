import styled from 'styled-components';

export const Root = styled.div`
  width: 100vw;
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 88px);
  display: flex;
  border-left: 20px solid ${({ theme }) => theme.colors.mainRed};
`;

export const BlockWrapper = styled.div`
  width: 100%;
`;
