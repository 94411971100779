/* eslint-disable prettier/prettier */
/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable prefer-template */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'src/hooks/domHooks';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { ICrackNew, setDeleteCracksAct, setModifiedCracksAct, setNewCracksAct } from 'src/store/reducers/cracksChange';
import { ICrack } from 'src/store/reducers/files';
import { Crack, ShelfItem } from '../Parameters/LitoParameters/LitoParameters';
import * as Styled from './styles';

// function generateRandomColor() {
//   const maxVal = 0xffffff; // 16777215
//   let randomNumber = Math.random() * maxVal;
//   randomNumber = Math.floor(randomNumber);
//   const randomNumber2 = randomNumber.toString(16);
//   const randColor = randomNumber2.padStart(6, '0');
//   return `#${randColor.toUpperCase()}`;
// }

type colorItem = {
  colorName: string,
  width: number,
};

interface Props {
  shelfItem: ShelfItem;
  showMasks: boolean;
}

// const createRulerSeparators = (from: number) => {
//   const newArr: number[] = [];
//   const eps = 1e-5;
//   for (let i = from; Math.abs(i - eps) <= from + 1; i += 0.1) {
//     newArr.push(+i.toFixed(2));
//   }
//   return newArr;
// };

const LitoShelfItem: React.FC<Props> = ({ shelfItem, showMasks }) => {
  const { typeCrop, legend, selectLanguage } = useAppSelector(
    (state) => state.files
  );
  const { authKey } = useAppSelector((state) => state.auth);
  const { newCracks, modifiedCracks, deleteCracks } = useAppSelector((state) => state.cracksChange);

  const dispatch = useAppDispatch();
  const [currCrack, setCurrCrack] = useState<number>(0);
  const [menuState, setMenuState] = useState<boolean>(false);
  const [isNewCrack, setIsNewCrack] = useState<boolean>(false);
  const [isDeleteCrack, setIsDeleteCrack] = useState<boolean>(false);
  const [selectCore, setSelectCore] = useState<EventTarget & HTMLDivElement>();

  const [posx, setPosx] = useState<number>(0);
  const [crackPosX, setCrackPosx] = useState<number>(0);
  const [cracks, setCracks] = useState<Crack[]>([]);
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    setCracks(shelfItem.cracks);
  }, [shelfItem.cracks]);

  useOnClickOutside(ref, () => setMenuState(false));

  const rightClick = (
    e: React.MouseEvent<HTMLImageElement | HTMLDivElement>,
    type: 'image' | 'crack',
    crackId?: number
  ) => {
    if (typeCrop === 'crack' || 'lythology') {
      e.preventDefault();
      setCurrCrack(crackId || 0);

      const img: HTMLElement | null = document.querySelector('.shelfImg');
      let targetCoords;

      if (img) {
        targetCoords = img.getBoundingClientRect();
      }

      if (e.type === 'contextmenu') {
        const posX = e.pageX - 410;
        if (type === 'image') {
          setIsNewCrack(true);
          setIsDeleteCrack(false);
        } else {
          setIsNewCrack(false);
          setIsDeleteCrack(true);
        }
        if (posx === posX) {
          setMenuState(!menuState);
        } else {
          setMenuState(true);
        }
        // eslint-disable-next-line prettier/prettier
        // setCrackPosx(e.pageX - (e.target as any).x)
        setCrackPosx(e.clientX - targetCoords.left);
        setPosx(posX);
      }

      if (typeCrop === 'lythology') {
        setSelectCore(e.currentTarget);
      }
    }
  };

  const newCrack = () => {
    const tempArr: ICrack[] = [...cracks];
    const tempItem: ICrack = {
      confidence: 1,
      layerColor: 'green',
      crackType: 'natural',
      id: (new Date()).getTime(),
      layerInstEndPix: crackPosX + 10,
      layerInstPixMiddle: crackPosX,
      layerInstStartPix: crackPosX - 10,
    }
    tempArr.push({...tempItem})

    dispatch(setNewCracksAct([...newCracks, {
      imgId: shelfItem.id,
      crack: tempItem,
    }]))

    setCracks(tempArr)
  };

  const deleteCrack = (crackId: number) => {
    if (typeCrop === 'crack') {
      const tempNewItem = newCracks.find(({ crack }) => crack.id === crackId);

      if(tempNewItem) {
        dispatch(setNewCracksAct(newCracks.filter(({ crack }) => crack.id !== crackId)))
      } else {
        dispatch(setDeleteCracksAct([...deleteCracks, crackId]))
      }

      setCracks(cracks.filter((crack: Crack) => crack.id !== crackId));
    } else {
      selectCore?.remove();
      // setCracks(cracks.filter((crack: Crack) => crack.id !== crackId));
    }
  };

  const updCrack = (crackId: number, newType: string, newColor: string) => {
    if (typeCrop === 'crack') {
      const crack = cracks.find(({ id }) => id === crackId);
      if (crack) {
        const newArr = cracks.map((crack: Crack) => {
          if (crack.id !== crackId) {
            return {
              ...crack,
            };
          }
          return {
            ...crack,
            crackType: newType,
          };
        });
        console.log(crack);
        setCracks(newArr);

        let tempArr: ICrack[] = [];
        const tempModifiedItem = modifiedCracks.find(({ id }) => id === crackId);
        const tempNewItem = newCracks.find(({ crack }) => crack.id === crackId);

        if (tempNewItem) {
          const tempNewArr: ICrackNew[] = newCracks.filter((item) => item.crack.id !== crack.id) || [];
          tempNewArr.push({
            ...tempNewItem,
            crack: {
              ...tempNewItem.crack,
              crackType: newType,
            }
          });

          dispatch(setNewCracksAct(tempNewArr))
        } else {
          if (modifiedCracks) {
            tempArr = modifiedCracks.filter((item) => item.id !== crack.id) || [];
          }
  
          if (tempModifiedItem) {
            tempArr.push({
              ...tempModifiedItem,
              crackType: newType,
            });
          } else {
            tempArr.push({
              ...crack,
              crackType: newType,
            });
          }
          dispatch(setModifiedCracksAct(tempArr));
        }
      }
    } else {
      const newArr = cracks.map((crack: Crack) => {
        if (crack.id !== crackId) {
          return {
            ...crack,
          };
        }
        return {
          ...crack,
          layerColor: newColor,
        };
      });
      setCracks(newArr);
    }
  };

  const mousedownMove = (el, crackId) => {
    const img: HTMLElement | null = document.querySelector('.shelfImg');
    let targetCoords;

    if (img) {
      targetCoords = img.getBoundingClientRect();
    }

    let prevX = el.clientX;

    function mousemove(e) {
      const newX = e.clientX;

      el.target.style.left =
        newX - targetCoords.left >= 660 - el.target.clientWidth / 2 - 4
          ? 660 - el.target.clientWidth - 4 + 'px'
          : newX - targetCoords.left - el.target.clientWidth / 2 <= 0
          ? '0 px'
          : prevX - el.target.clientWidth / 2 - targetCoords.left + 'px';

      prevX = e.clientX;
    }

    function mouseup(e) {
      const crack = el.target.style;
      const selectCrack = shelfItem.cracks.find(({ id }) => id === crackId);

      const start = +crack.left.substring(0, crack.left.length - 2);
      const width = selectCrack 
        ? selectCrack?.layerInstEndPix - selectCrack?.layerInstStartPix
        : 0;

      const tempNewItem = newCracks.find(({ crack }) => crack.id === crackId);

      if (tempNewItem) {
        const tempNewArr: ICrackNew[] = newCracks.filter((item) => item.crack.id !== crackId) || [];
        tempNewArr.push({
          ...tempNewItem,
          crack: {
            ...tempNewItem.crack,
            layerInstEndPix: start + (tempNewItem.crack.layerInstEndPix - tempNewItem.crack.layerInstStartPix),
            layerInstPixMiddle: start + (tempNewItem.crack.layerInstEndPix - tempNewItem.crack.layerInstStartPix) / 2,
            layerInstStartPix: start,
          }
        });

        dispatch(setNewCracksAct(tempNewArr))
      } else if (selectCrack) {
          let tempArr: ICrack[] = [];
          const tempItem = modifiedCracks.find(({ id }) => id === crackId);
  
          if (modifiedCracks) {
            tempArr = modifiedCracks.filter((item) => item.id !== crackId) || [];
          }
          if (tempItem) {
            tempArr.push({
              ...tempItem,
              layerInstEndPix: start + (tempItem.layerInstEndPix - tempItem.layerInstStartPix),
              layerInstPixMiddle: start + (tempItem.layerInstEndPix - tempItem.layerInstStartPix) / 2,
              layerInstStartPix: start,
            });
          } else {
            tempArr.push({
              ...selectCrack,
              layerInstEndPix: start + width,
              layerInstPixMiddle: start + width / 2,
              layerInstStartPix: start,
            });
          }
        dispatch(setModifiedCracksAct(tempArr));
      }

      window.removeEventListener('mousemove', mousemove);
      window.removeEventListener('mouseup', mouseup);
    }

    window.addEventListener('mousemove', mousemove);
    window.addEventListener('mouseup', mouseup);
  };

  const mousedownResize = (el, type, crackId) => {
    const img: HTMLElement | null = document.querySelector('.shelfImg');
    let targetCoords;

    if (img) {
      targetCoords = img.getBoundingClientRect();
    }

    const crack = el.currentTarget.parentElement;
    let prevX = el.clientX;

    function mousemove(e) {
      const rect = crack.getBoundingClientRect();

      if (type === 'left') {
        if (rect.width - 10 > 0 && e.clientX - targetCoords.left >= 0) {
          crack.style.width = rect.width + (prevX - e.clientX) + 'px';
          crack.style.left = e.clientX - targetCoords.left + 'px';
        } else if (
          e.clientX < el.clientX &&
          e.clientX - targetCoords.left >= 0
        ) {
          crack.style.width = rect.width + (prevX - e.clientX) + 'px';
          crack.style.left = prevX - targetCoords.left + 'px';
        }
      }
      if (type === 'right') {
        if (
          rect.width - 10 > 0 &&
          e.clientX - targetCoords.left < 660 - el.target.clientWidth + 4
        ) {
          crack.style.width = rect.width - (prevX - e.clientX) + 'px';
        } else if (
          e.clientX > el.clientX &&
          e.clientX - targetCoords.left < 660 - el.target.clientWidth + 4
        ) {
          crack.style.width = rect.width - (prevX - e.clientX) + 'px';
        }
      }

      prevX = e.clientX;
    }

    function mouseup(e) {
      const selectCrack = shelfItem.cracks.find(({ id }) => id === crackId);
      const tempNewItem = newCracks.find(({ crack }) => crack.id === crackId);

      if (tempNewItem) {
        const width = +crack.style.width.substring(0, crack.style.width.length - 2);
        const start = +crack.style.left.substring(0, crack.style.left.length - 2)
          ? +crack.style.left.substring(0, crack.style.left.length - 2)
          : tempNewItem.crack.layerInstStartPix;

        const tempNewArr: ICrackNew[] = newCracks.filter((item) => item.crack.id !== crackId) || [];
        tempNewArr.push({
          ...tempNewItem,
          crack: {
            ...tempNewItem.crack,
            layerInstEndPix: start + width,
            layerInstPixMiddle: start + width / 2,
            layerInstStartPix: start,
          }
        });

        dispatch(setNewCracksAct(tempNewArr))
      } else if (selectCrack) {
          const width = +crack.style.width.substring(0, crack.style.width.length - 2);
          const start = +crack.style.left.substring(0, crack.style.left.length - 2)
            ? +crack.style.left.substring(0, crack.style.left.length - 2)
            : selectCrack.layerInstStartPix;
  
          let tempArr: ICrack[] = [];
          if (modifiedCracks) {
            tempArr = modifiedCracks.filter((item) => item.id !== crackId) || [];
          }
          tempArr.push({
            ...selectCrack,
            layerInstEndPix: start + width,
            layerInstPixMiddle: start + width / 2,
            layerInstStartPix: start,
          });
          dispatch(setModifiedCracksAct(tempArr));
          console.log(tempArr)
        }

      window.removeEventListener('mousemove', mousemove);
      window.removeEventListener('mouseup', mouseup);
    }

    window.addEventListener('mousemove', mousemove);
    window.addEventListener('mouseup', mouseup);
  };

  const PrintGradBlock = () => {
    if (typeCrop === 'sulfides' || typeCrop === 'oxides') {
      return true;
    }
    return false;
  };

  return (
    <Styled.Root>
      {menuState && (
        <Styled.MenuBox xpos={posx} ref={ref}>
          <Styled.MenuItems>
            {isNewCrack && (
              <Styled.MenuItem onClick={newCrack}>
                {selectLanguage === 'Ru' ? 'Добавить трещину' : 'Add crack'}
              </Styled.MenuItem>
            )}
            {isDeleteCrack && (
              <>
                <Styled.MenuItem onClick={() => deleteCrack(currCrack)}>
                  {selectLanguage === 'Ru' ? 'Удалить' : 'Remove'}
                </Styled.MenuItem>
                <Styled.MenuItem>
                  {/* {selectLanguage === 'Ru'
                    ? 'Изменить тип трещины'
                    : 'Change crack type'} */}
                  {selectLanguage === 'Ru' ? 'Изменить тип' : 'Change type'}
                  <Styled.MenuItemMenu className="sub-menu">
                    <Styled.MenuItems>
                      {legend?.map((leg) => (
                        <Styled.MenuItem
                          onClick={() =>
                            updCrack(currCrack, leg.lythologyName, leg.color)
                          }
                          key={leg.lythologyName}
                          color={leg.color}
                        >
                          {leg.lythologyName}
                        </Styled.MenuItem>
                      ))}
                    </Styled.MenuItems>
                  </Styled.MenuItemMenu>
                </Styled.MenuItem>
              </>
            )}
          </Styled.MenuItems>
        </Styled.MenuBox>
      )}
      <Styled.Ruler>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item: number) =>
          item !== 10 ? (
            <>
              <Styled.RulerTextWrapper>
                <Styled.RulerMainText>{item}</Styled.RulerMainText>
                <Styled.RulerSeparator />
              </Styled.RulerTextWrapper>

              <Styled.RulerTextWrapper>
                <Styled.RulerSmallText>1</Styled.RulerSmallText>
                <Styled.RulerSmallSeparator />
              </Styled.RulerTextWrapper>

              <Styled.RulerTextWrapper>
                <Styled.RulerSmallText>2</Styled.RulerSmallText>
                <Styled.RulerSmallSeparator />
              </Styled.RulerTextWrapper>

              <Styled.RulerTextWrapper>
                <Styled.RulerSmallText>3</Styled.RulerSmallText>
                <Styled.RulerSmallSeparator />
              </Styled.RulerTextWrapper>

              <Styled.RulerTextWrapper>
                <Styled.RulerSmallText>4</Styled.RulerSmallText>
                <Styled.RulerSmallSeparator />
              </Styled.RulerTextWrapper>

              <Styled.RulerTextWrapper>
                <Styled.RulerSmallText>5</Styled.RulerSmallText>
                <Styled.RulerSmallSeparator />
              </Styled.RulerTextWrapper>

              <Styled.RulerTextWrapper>
                <Styled.RulerSmallText>6</Styled.RulerSmallText>
                <Styled.RulerSmallSeparator />
              </Styled.RulerTextWrapper>

              <Styled.RulerTextWrapper>
                <Styled.RulerSmallText>7</Styled.RulerSmallText>
                <Styled.RulerSmallSeparator />
              </Styled.RulerTextWrapper>

              <Styled.RulerTextWrapper>
                <Styled.RulerSmallText>8</Styled.RulerSmallText>
                <Styled.RulerSmallSeparator />
              </Styled.RulerTextWrapper>

              <Styled.RulerTextWrapper>
                <Styled.RulerSmallText>9</Styled.RulerSmallText>
                <Styled.RulerSmallSeparator />
              </Styled.RulerTextWrapper>
            </>
          ) : (
            <Styled.RulerTextWrapper>
              <Styled.RulerMainText>1</Styled.RulerMainText>
              <Styled.RulerSmallSeparator />
            </Styled.RulerTextWrapper>
          )
        )}
      </Styled.Ruler>

      <Styled.ShelfWrapper>
        <Styled.ImgGroup>
          <Styled.ShelfColumnFrom>
            <Styled.ShelfTopNumberFrom>
              {shelfItem.from}
            </Styled.ShelfTopNumberFrom>
            <Styled.ShelfBottomNumberFrom>
              {shelfItem.realFrom}
            </Styled.ShelfBottomNumberFrom>
          </Styled.ShelfColumnFrom>
          {cracks?.map((crack) =>
            typeCrop === 'crack' ? (
              <Styled.Crack
                onMouseDown={(e) => {
                  if (e.button === 0) {
                    mousedownMove(e, crack.id)
                  }
                }}
                onContextMenu={(e) => rightClick(e, 'crack', crack.id)}
                width={crack.layerInstEndPix - crack.layerInstStartPix}
                position={crack.layerInstStartPix}
                color={
                  legend.find((leg) => leg.lythologyName === crack.crackType)
                    ?.color || 'green'
                }
                key={crack.id}
              >
                <Styled.CrackActionLeft
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    if (e.button === 0) {
                      mousedownResize(e, 'left', crack.id);
                    }
                  }}
                />
                <Styled.CrackActionRight
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    if (e.button === 0) {
                      mousedownResize(e, 'right', crack.id);
                    }
                  }}
                />
              </Styled.Crack>
            ) : (
              typeCrop === 'lythology' && (
                <Styled.Core
                  onContextMenu={(e) => rightClick(e, 'crack', crack.id)}
                  start={crack.layerInstStartPix}
                  end={crack.layerInstEndPix}
                  color={crack.layerColor}
                >
                  <Styled.CrackActionLeft
                    onMouseDown={(e) => {
                      mousedownResize(e, 'left', crack.id);
                    }}
                  />
                  <Styled.CoreInfo
                    start={crack.layerInstStartPix}
                    end={crack.layerInstEndPix}
                    color={crack.layerColor}
                  >
                    <Styled.CoreInfoText>
                      {
                        legend.filter(
                          (item) => item.color === crack.layerColor
                        )[0]?.lythologyName
                      }
                    </Styled.CoreInfoText>
                  </Styled.CoreInfo>
                  <Styled.CrackActionRight
                    onMouseDown={(e) => {
                      mousedownResize(e, 'right', crack.id);
                    }}
                  />
                </Styled.Core>
              )
            )
          )}
          <Styled.ShelfImg
            className="shelfImg"
            src={showMasks ? shelfItem.maskUrl : shelfItem.url}
            alt="Shelf"
            onContextMenu={(e) =>
              typeCrop === 'crack' ? rightClick(e, 'image') : ''
            }
          />
          <Styled.ShelfColumnRightFrom>
            <Styled.ShelfTopNumberFrom>
              {shelfItem.to}
            </Styled.ShelfTopNumberFrom>
            <Styled.ShelfBottomNumberFrom>
              {shelfItem.realTo}
            </Styled.ShelfBottomNumberFrom>
          </Styled.ShelfColumnRightFrom>
        </Styled.ImgGroup>
        {PrintGradBlock() && (
          <Styled.GradBlock>
            {shelfItem.cracks?.map((s) => (
              <Styled.ColorBlock
                color={`#${s.color}`}
                text={`${Math.floor((s.quantity || 0) * 100)}%`}
                type={typeCrop}
              />
            ))}
          </Styled.GradBlock>
        )}
      </Styled.ShelfWrapper>

      <Styled.ShelfColorWrapper>
        {shelfItem.colors.map((color: colorItem) => (
          <Styled.ShelfColor
            shelfColorWidth={color.width}
            shelfColorName={color.colorName}
          />
        ))}
      </Styled.ShelfColorWrapper>
    </Styled.Root>
  );
};

export default React.memo(LitoShelfItem);
