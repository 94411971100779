/* eslint-disable prettier/prettier */
import { Icon } from 'src/components/UI/Icon';
import styled, { css } from 'styled-components';

interface Props {
  last?: number;
  contentType?: string;
  tabType?: string;
}

export const Root = styled.div``;

export const Wrapper = styled.div`
  width: calc(100vw - 100px);
  height: 77vh;
  margin: 40px;
`;

export const MainLitoTabs = styled.div`
  width: 100%;
  height: 30px;
  margin: 40px 20px 18px 20px;
  display: flex;
`;

export const TabWrapper = styled.div<Props>`
  width: 32.2%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.lightGray};
  transform: skew(45deg);
  position: relative;
  margin-left: 2px;
  padding-left: 12px;
  cursor: pointer;

  display: flex;
  justify-content:  space-between;

  &:first-child {
    padding-left: 0px;
  }

  &:first-child:after {
    content: '';
    position: absolute;
    top: 0px;
    left: -22px;
    height: 30px;
    width: 37px;
    transform: skew(-45deg);
    background-color: ${({ theme }) => theme.colors.gray};
  }

  &:last-child:after {
    content: '';
    position: absolute;
    top: 0px;
    right: -15px;
    height: 30px;
    width: 30px;
    transform: skew(-45deg);
    background-color: ${({ theme }) => theme.colors.gray};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;
  }

  &:first-child:hover:after {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.1s;
  }

  &:last-child:hover:after {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.1s;
  }

  ${({contentType, tabType}) => contentType === tabType && css`
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};
  `}

  ${({contentType}) => contentType === 'references' && css`
    &:first-child:after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
    }
  `}

  ${({contentType}) => contentType === 'other' && css`
    &:last-child:after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
    }
  `}
`;

export const TabText = styled.p`
  ${({ theme }) => theme.typography.text_16_19}
  font-weight: 700;
  margin: auto 15px;
  transform: skew(-45deg);
`;

export const TabIcon = styled(Icon)<Props>`
  transform: skew(-45deg);
  margin: auto 20px;
  color: ${({ theme }) => theme.colors.white};
  ${({last}) => last === 1 && css`
    margin: auto 13px;
  `}
`;

