/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface Props {
    dragging?: boolean;
    isValid?: boolean;
    variant?: 'primary' | 'danger'
}

export const UploadButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  color: ${({ theme }) => theme.colors.white};
  svg {
    margin-right: 20px;
  }
`

export const FilesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
`;

export const NotChoised = styled.div`
  position: absolute;
  cursor: no-drop;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: flex-start;
  padding: 25px;
  justify-content: center;
  font-size: 25px;
  background-color: #FFFFFF90;
`;

export const InvalidFilesWrapper = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.colors.mainRed};
  left: 10px;
  display: flex;
  flex-wrap: wrap;
  bottom: 10px;
`;

export const ShowFilesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
  display: grid;
  grid-gap: 9px;
  grid-template-columns: repeat(auto-fill, 126px);
  grid-template-rows: repeat(auto-fill, 75px);
  padding-bottom: 35px;
`;

export const ImageFile = styled.div`
  position: relative;
  width: 126px;
  height: 75px;
`;

export const ImageFooter = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 20px;
  padding: 3px 7px;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  color: ${({ theme }) => theme.colors.imgColor};
  ${({ theme }) => theme.typography.text_12_14}
`;

export const Image = styled.img`
  min-width: 126px;
  min-height: 75px;
  background-color: #C0C0C0;
`

export const File = styled.div`
  position: relative;
  width: 126px;
  height: 75px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.fileBg};
`;

export const RemoveInvalidFiles = styled.button`
  margin-left: 10px;
  padding: 4px;
  height: 20px;
  border: 2px solid ${({ theme }) => theme.colors.mainRed};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.mainRed};
`;

export const DragFooter = styled.footer`
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: flex-end;
  box-shadow: 0px -21px 8px -8px rgba(255, 255, 255, 1);
`;

export const FooterBtn = styled.button<Props>`
  background: ${({ theme, variant }) => variant === 'primary' ? theme.colors.lightBlue : theme.colors.mainRed};
  padding: 5px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  svg {
    margin-left: 10px;
  }
`;

export const AddBtn = styled.label`
  background: ${({ theme }) => theme.colors.lightBlue};
  padding: 5px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  svg {
    margin-left: 10px;
  }
`

export const Root = styled.div<Props>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  ${({ theme }) => theme.typography.text_16_19};
  ${({ dragging }) => dragging && css`border: 3px dashed #CCCCCC;`}
  ${({ isValid, theme }) => !isValid && css`border: 3px dashed ${theme.colors.mainRed}`}
`;

export const LabelFile = styled.label`
  margin-right: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.linkColor};
`;

export const Link = styled.input.attrs({
    type: 'file'
})`
    display: none;
`
