/* eslint-disable prettier/prettier */
import { Icon } from 'src/components/UI/Icon';
import styled, { css } from 'styled-components';

interface Props {
    isactive?: boolean;
    choised?: boolean;
    selected?: boolean;
    bottom?: string;
    typeProfile?: boolean;
    desableDownload?: number;
    columnRight?: string;
    disabiled?: boolean;
}

export const Root = styled.div`
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  height: calc(100vh - 88px);
  width: 100%;
  display: flex;
  padding: 49px 80px 63px 57px;
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border-left: 20px solid ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 9px;

  display: flex;
  justify-content: center;
`;

export const SearchIcon = styled(Icon)`
  margin: auto 10px;
`;

export const Title = styled.h2`
  ${({ theme }) => theme.typography.text_20_23}
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  margin: auto 10px;
`;

export const Body = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 120px);
  background-color: ${({ theme }) => theme.colors.gray};
  display: flex;
`;

export const ZoomToolIn = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  font-size: 35px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  cursor: pointer;

  z-index: 5;
  bottom: 10px;
  left: 10px;
`

export const ZoomToolOut = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  font-size: 35px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  cursor: pointer;

  z-index: 5;
  bottom: 10px;
  left: 60px;
`

export const ZoomToolReset = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  font-size: 26px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  cursor: pointer;

  z-index: 5;
  bottom: 10px;
  left: 110px;
`

export const BigImgItem = styled.div<Props>`
  position: absolute;
  // width: calc(100% - 298px);
  width: ${p => p.columnRight || 'calc(100% - 298px)'};
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  z-index: 4;
`

export const BigImgItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

export const BigImgItemPhoto = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const LeftColumn = styled.div`
  width: 282px;
  height: 100%;
  border-right: 10px solid ${({ theme }) => theme.colors.lightGray};
`

export const MiddleColumn = styled.div<Props>`
  width: calc(100% - 510px);
  height: 100%;
  overflow: hidden;
  border-right: 10px solid ${({ theme }) => theme.colors.lightGray};
`;

export const RighttColumn = styled.div<Props>`
  min-width: 288px;
  width: ${p => p.columnRight || '288px'};
  height: 100%;
`

export const RightColumn = styled.div`
  width: 440px;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: 10px;
  scrollbar-color: ${({ theme }) => theme.colors.mainRed} ${({ theme }) => theme.colors.lightGray};
`;

export const GeneralInfoTitle = styled.div`
  min-width: 282px;
  width: 100%;
  height: 27px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border-right: 10px solid ${({ theme }) => theme.colors.lightGray};
  cursor: pointer;
`

export const GeneralInfoWrapper = styled.div`
  width: 100%;
  height: calc(100% - 27px);
  padding: 4px 10px 4px 14px;
  overflow-y: auto;
  scrollbarbar-width: 10px;
  scrollbar-color: ${({ theme }) => theme.colors.mainRed} ${({ theme }) => theme.colors.lightGray};
`

export const GeneralTextTitle = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin: auto;
  line-height: 27px;
`

export const TabsWrapper = styled.div`
  width: 100%;
  height: 27px;
  display: flex;
`;

export const GalleryTab = styled.div<Props>`
  width: 50%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray};
  display: flex;
  cursor: pointer;

  ${({ theme, isactive }) => isactive && css`background-color: ${theme.colors.darkBlue}`}
`;

export const ListTab = styled.div<Props>`
  width: 50%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray};
  display: flex;
  cursor: pointer;

  ${({ theme, isactive }) => isactive && css`background-color: ${theme.colors.darkBlue}`}
`;

export const TabText = styled.p<Props>`
  ${({ theme }) => theme.typography.text_16_19}
  font-weight: 600;
  color: ${({ theme }) => theme.colors.lightTextGray};
  margin: auto;

  ${({ theme, isactive }) => isactive && css`color: ${theme.colors.white}`}
`;

export const ListFilesContrainer = styled.div`
  padding: 8px 0;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  overflow-y: scroll;
`

export const ListItem = styled.div<Props>`
  height: 23px;
  width: 100%;
  padding-left: 20px;
  border-left: 20px solid ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.lightGray};
  background-color: ${({ theme }) => theme.colors.disabilityGray};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 4px;
  border-radius: 1px;
  transition: all 0.04s;
  cursor: pointer;

  &:last-child {
    margin-bottom: 23px;
  }

  ${({ theme }) => theme.typography.text_16_19}
  ${({ theme, choised }) => !choised && css`
    border: 2px solid ${theme.colors.lightGray};
    border-left: 20px solid ${({ theme }) => theme.colors.lightGray};
    background-color: ${theme.colors.white};
    color: ${({ theme }) => theme.colors.lightGray};
  `}

  &:hover {
    border-left: 20px solid ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.lightGray};
    background-color: ${({ theme }) => theme.colors.disabilityGray};
    color: ${({ theme }) => theme.colors.white};
    // border: 0px;
    // border-left: 20px solid ${({ theme }) => theme.colors.lightGray};
    transition: 0.1s;
  }
`

export const GalletyPhotoList = styled.div`
  width: 100%;
  height: calc(100% - 27px);
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  padding: 9px 16px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, 192px);
  grid-auto-rows: 192px;
  grid-row-gap: 9px;
  overflow-y: auto;
  scrollbar-width: 10px;
  overflow-x: hidden;
  scrollbar-color: ${({ theme }) => theme.colors.mainRed} ${({ theme }) => theme.colors.lightGray};
`;

export const GalleryItem = styled.div`
  position: relative;
  width: 100%;
  min-height: 192px;
  height: 100%;
  display: flex;
  // align-items: flex-end;
  flex-direction: column;
  ${({ theme }) => theme.typography.text_16_19}

  cursor: pointer;
`

export const TablePhotoList = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 27px);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 9px 16px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, 192px);
  grid-auto-rows: 192px;
  grid-column-gap: 22px;
  grid-row-gap: 9px;

  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: 10px;
  scrollbar-color: ${({ theme }) => theme.colors.mainRed} ${({ theme }) => theme.colors.lightGray};
`

export const TableItem = styled.div`
  position: relative;

  width: 100%;
  min-height: 192px;
  height: 100%;

  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.typography.text_16_19}

  cursor: pointer;
`

export const TableItemWrapperName = styled.div`
  display: flex;
`

export const TableItemPoint = styled.div<Props>`
  position: relative;
  width: 17px;
  height: 17px;
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  margin-right: 10px;
  cursor: pointer;
`

export const TablePointChoise = styled.div`
    position: absolute;
    top: 1px;
    left: 1px;

    width: 13px;
    height: 13px;

    background-color: ${({ theme }) => theme.colors.lightBlue};
`

export const TablePointChoiseText = styled.div<Props>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  z-index: 1;

  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.typography.text_12_14};
`

export const TableAllPointChoise = styled.div`
  display: flex;

  background-color: ${({ theme }) => theme.colors.white};

  padding: 9px 16px 0 16px;
`

export const ImgItemButtons = styled.button<Props>`
  position: absolute;
  width: 112px;
  height: 22px;
  right: 8px;
  bottom: ${p => p.bottom || '8px'};
  display: flex;
  align-items: center;
  padding: 0 14px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  z-index: 3;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }
`

export const GalleryItemButtonsText =styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.white};
`

export const GalleryChoise = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 160px;
  position: absolute;
  left: 0;
  top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.typography.text_22_26};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  user-select: none;
  z-index: 2;

  display: flex;
  flex-direction: column;
`

export const GalleryChoiseText = styled.p`
  margin: 8px 0;
`;

export const GalleryPhoto = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
  ${({ theme }) => theme.typography.text_16_19}
`

export const GalleryItemName = styled.div`
  margin-bottom: 13px;
`

export const FileDownloadError = styled.div`
  position: absolute;
  bottom: -52px;
  left: 0px;
  width: 236px;
  height: 40px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.darkRed}
`

export const FileDownloadErrorText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.white};
`

export const FileDownloadErrorPoint = styled.div`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 44px;
  color: ${({ theme }) => theme.colors.white};
`

export const FileDownloadErrorWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.darkRed};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const FilesTitleWrapper = styled.div`
  position: relative;
  width: 236px;
  height: 27px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border-left: 10px solid ${({ theme }) => theme.colors.lightBlue};
  margin: 0 auto;
  margin-top: 49px;
  display: flex;
`;

export const FilesTitle = styled.p`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_16_19}
  font-weight: 600;
  margin: auto 10px;
`;

export const File = styled.div`
  position: relative;
  width: 77px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const FilesList = styled.div`
  position: relative;
  width: 236px;
  padding: 13px 23px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 auto;
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const ActionsButton = styled.button<Props>`
  width: 25%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  cursor: pointer;
  display: flex;

  &:hover {
    transition: 0.1s;
    background: ${({ theme }) => theme.colors.darkBlue};
  }

  ${({ typeProfile }) => typeProfile && css`
    width: 33%;
  `}

  ${({ desableDownload, theme }) => desableDownload === 0 && css`
    background: ${theme.colors.lightGray};
    pointer-events: none;
  `}
`;

export const DownloadButtonWrapper = styled.div`
  position: relative;

  width: 25%;
  height: 40px;
`;

export const DownloadButton = styled.div<Props>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  cursor: pointer;
  display: flex;

  &:hover {
    transition: 0.1s;
    background: ${({ theme }) => theme.colors.darkBlue};
  }

  ${({ desableDownload, theme }) => desableDownload === 0 && css`
    background: ${theme.colors.lightGray};
    pointer-events: none;
  `}

  ${({ isactive, theme }) => isactive && css`
    background: ${theme.colors.lightGray};

    &:hover {
      transition: 0.1s;
      background: ${theme.colors.darkGray};
    }
  `}
`;

export const DownloadButtonText = styled.p`
  ${({ theme }) => theme.typography.text_20_23}
  color: ${({ theme }) => theme.colors.white};
  margin: auto;
`;

export const DownloadOptionsWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 40px;
  left: 0;
  z-index: 5;
`;

export const DownloadOptionItem = styled.div<Props>`
  width: 100%;
  max-height: 0px;
  transition: 0.3s;

  display: flex;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.lightBlue};

  &:hover {
    transition: 0.1s;
    background: ${({ theme }) => theme.colors.darkBlue};
  }

  pointer-events: none;

  ${({ isactive }) => isactive && css`
    pointer-events: auto;
    max-height: 40px;
    transition: 0.3s;
  `}
`;

export const DownloadOptionText = styled.p<Props>`
  ${({ theme }) => theme.typography.text_20_23}
  color: ${({ theme }) => theme.colors.white};
  padding: 8px 0;
  margin: auto;

  opacity: 0;
  transition: 0.3s;

  ${({ isactive }) => isactive && css`
    opacity: 1;
    transition: 0.3s;
  `}
`
export const LabelFile = styled.label<Props>`
  width: 22.5%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  cursor: pointer;
  display: flex;

  &:hover {
    transition: 0.1s;
    background: ${({ theme }) => theme.colors.darkBlue};
  }

  ${({ theme }) => theme.typography.text_20_23}
  color: ${({ theme }) => theme.colors.white};

  ${({ typeProfile }) => typeProfile && css`
    width: 33%;
  `}
`;

export const ButtonFileText = styled.p`
  ${({ theme }) => theme.typography.text_20_23}
  color: ${({ theme }) => theme.colors.white};
  margin: auto;
`;

export const Link = styled.input.attrs({
    type: 'file'
})`
    display: none;
`

export const ButtonText = styled.p`
  ${({ theme }) => theme.typography.text_20_23}
  color: ${({ theme }) => theme.colors.white};
  margin: auto;
`;

export const ButtonIcon = styled(Icon)`
  margin: auto 0;
  margin-left: 15px;
`;

export const SaveButton = styled.button<Props>`
  width: 22.5%;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.mainRed};
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    transition: 0.1s;
    background: ${({ theme }) => theme.colors.darkRed};
  }

  ${({ typeProfile }) => typeProfile && css`
    width: 33%;
  `}

  ${({ desableDownload, theme }) => desableDownload === 0 && css`
    background: ${theme.colors.lightGray};
    pointer-events: none;
  `}
`;

export const SaveText = styled.p`
  ${({ theme }) => theme.typography.text_20_23}
  color: ${({ theme }) => theme.colors.white};
  margin: auto 0;
`;

export const TextWrapper = styled.div`
  width: 380px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  border-left: 10px solid ${({ theme }) => theme.colors.lightBlue};
  margin: 2px auto;
  display: flex;

  &:last-child {
    height: 80px;
  }
`;

export const Text = styled.p`
  ${({ theme }) => theme.typography.text_12_14}
  color: ${({ theme }) => theme.colors.darkTextGray};
  margin-left: 10px;

  &:last-child {
    margin: 5px 10px;
  }
`;

export const GeneralInfoItemWrapper = styled.div`
  min-width: 236px;
  width: 100%;
  height: 56px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};

  &:nth-last-child(3) {
    height: 92px;
  }
`;

export const InfoFileItemWrapper = styled.div<Props>`
  min-width: 236px;
  width: 100%;
  height: 56px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.disabilityGray};

  &:last-child {
    height: 92px;
  }

  ${({ theme, selected }) => selected && css`
    background-color: ${theme.colors.white};
  `}
`

export const GeneralInfoItemTitleWrapper = styled.div`
  position: relative;
  min-width: 186px;
  width: 186px;
  height: 19px;
  padding-left: 20px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 19px;
    background-color: ${({ theme }) => theme.colors.lightBlue};
    z-index: 1;
  }
`

export const GeneralInfoItemTitleText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.white};
`

export const GeneralInfoItemTextArea = styled.textarea`
  display: block;
  width: 236px;
  height: 100%;
  outline: none;
  color: ${({ theme }) => theme.colors.lightTextGray};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 4px 10px;
  text-align: start;
  resize: none;
`

export const InfoFileWrapper = styled.div`
width: 100%;
height: calc(100% - 27px);
padding: 4px 9px 4px 6px;
overflow-y: auto;
scrollbarbar-width: 10px;
scrollbar-color: ${({ theme }) => theme.colors.mainRed} ${({ theme }) => theme.colors.lightGray};
border-right: 10px solid ${({ theme }) => theme.colors.lightGray};
`

export const InfoFileItemTitleWrapper = styled.div<Props>`
  position: relative;
  min-width: 186px;
  width: 186px;
  height: 19px;
  padding-left: 20px;
  background-color: ${({ theme }) => theme.colors.darkGray};
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 19px;
    background-color: ${({ theme }) => theme.colors.lightGray};
    z-index: 1;
  }
  ${({ theme, selected }) => selected && css`
    background-color: ${theme.colors.darkBlue};
    &::before {
      background-color: ${theme.colors.lightBlue};
    }
  `}
`

export const InfoFileItemTextArea = styled.textarea<Props>`
  display: block;
  width: 236px;
  height: 100%;
  outline: none;
  color: ${({ theme }) => theme.colors.lightTextGray};
  background-color: ${({ theme }) => theme.colors.disabilityGray};
  border: none;
  padding: 4px 10px;
  text-align: start;
  resize: none;
  pointer-events: none;

  ${({ theme, selected }) => selected && css`
    background-color: ${theme.colors.white};
    pointer-events: auto;
  `}

  ${({ disabiled }) => disabiled && css`
    pointer-events: none;
  `}
`