/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';
import { Icon } from '../UI/Icon';

interface Props {
  isactive?: number;
  full?: boolean;
  color?: string;
}

export const Root = styled.div`
  position: relative;
  width: 100%;
  height: 70vh;
  background-color: ${({ theme }) => theme.colors.white};
`;

// ------ ActionsPanel ------

export const ActionsPanel = styled.div`
  width: 100%;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
`;

export const LegendWrapper = styled.div`
  position: relative;
  width: 30%;
  height: 100%;
`;

export const Legend = styled.div <Props>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  border-right: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  transform: skew(45deg);
  margin-left: 2px;
  padding-left: 5px;
  cursor: pointer;
  margin-left: 15px;
  position: relative;

  display: flex;
  justify-content:  space-between;

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: -15px;
    height: 30px;
    width: 30px;
    transform: skew(-45deg);
    background-color: ${({ theme }) => theme.colors.lightBlue};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.1s;

    &:after {
        background-color: ${({ theme }) => theme.colors.darkBlue};
        transition: 0.1s;
    }
  }

  ${({ isactive }) =>
		isactive &&
		css`
			background-color: ${({ theme }) => theme.colors.darkBlue};
			&:after {
				background-color: ${({ theme }) => theme.colors.darkBlue};
			}
	`}
`;

export const LegendText = styled.div`
  ${({ theme }) => theme.typography.text_16_19}
  font-weight: 700;
  margin: auto 15px;
  transform: skew(-45deg);
`;

export const LegendIcon =
  styled(Icon) <
  Props >
  `
  transform: skew(-45deg);
  transition: 0.3s;
  margin: auto 12px;
  color: ${({ theme }) => theme.colors.white};
  ${({ isactive }) =>
    isactive &&
    css`
      transform: skew(-45deg) rotate(180deg);
      transition: 0.3s;
    `}
`;

export const LegendInfo = styled.div <Props>`
  display: none;
  z-index: 0;
  position: absolute;
  overflow-y: auto;
  margin-top: 2px;
  padding: 5px 0;
  width: calc(100% + 30px);
  height: 150px;
  background-color: ${({ theme }) => theme.colors.lightBlue};

  border: 1px solid ${({ theme }) => theme.colors.white};
  border-left: none;

  ${({ isactive }) =>
    isactive &&
    css`
      display: block;
      z-index: 5;
    `}
`;

export const LegendItem = styled.div`
  height: 20px;
  margin: 0 10px;
  display: flex;
`;

export const LegendColor = styled.div <Props>`
  width: 20px;
  height: 10px;
  margin: auto 0;
  margin-right: 13px;
  border-radius: 5px;
  ${({color}) => css`
    background-color: ${color};
  `}
`;

export const LegendItemText = styled.div`
  ${({ theme }) => theme.typography.text_10_12}
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  margin: auto 0;
`;

export const ParametersWrapper = styled.div`
  position: relative;
	width: 33%;
	height: 100%;
`;

export const Parameters = styled.div<Props>`
	width: 100%;
	height: 100%;
	transform: skew(45deg);
	padding-left: 10px;
	margin-left: 13px;

	background-color: ${({ theme }) => theme.colors.lightBlue};
	border-right: 1px solid ${({ theme }) => theme.colors.white};
	border-left: 1px solid ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.white};

	cursor: pointer;
	position: relative;

	display: flex;
	justify-content:  space-between;

	&:hover {
		background-color: ${({ theme }) => theme.colors.darkBlue};
		transition: 0.1s;

		&:after {
				background-color: ${({ theme }) => theme.colors.darkBlue};
				transition: 0.1s;
		}
	}

	${({ isactive }) =>
		isactive &&
		css`
			background-color: ${({ theme }) => theme.colors.darkBlue};
			&:after {
				background-color: ${({ theme }) => theme.colors.darkBlue};
			}
	`}
`;

export const SelectedParameter = styled.div`
	${({ theme }) => theme.typography.text_16_19}
	font-weight: 700;
	margin: auto 15px;
	transform: skew(-45deg);
`;

export const ParametersList = styled.div<Props>`
	display: none;
	z-index: 0;
	position: absolute;
	margin-top: 2px;
	margin-left: 29px;

	width: calc(100% - 3px);
	// height: 1px;
	background-color: ${({ theme }) => theme.colors.lightBlue};

	border: 1px solid ${({ theme }) => theme.colors.white};
	border-left: none;

	${({ isactive }) =>
		isactive &&
		css`
			display: block;
			z-index: 5;
	`}
`;

export const ParameterOption = styled.div<Props>`
	width: 100%;
	height: 35px;
	display: flex;
	padding: 0 10px;

	cursor: pointer;
	transition: 0.1s;

	&:hover {
		background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.1s;
	}

	${({ isactive }) =>
		isactive &&
		css`
			background-color: ${({ theme }) => theme.colors.darkBlue};
	`}
`;

export const ParameterOptionText = styled.div`
	color: ${({ theme }) => theme.colors.white};
	${({ theme }) => theme.typography.text_14_16}
	font-weight: 700;

	margin: auto 0;
`;

export const EditButton = styled.div`
	width: 27%;
	height: 100%;
	transform: skew(45deg);
	padding-left: 10px;
	margin-left: 10px;

	background-color: ${({ theme }) => theme.colors.lightBlue};
	border-right: 1px solid ${({ theme }) => theme.colors.white};
	border-left: 1px solid ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.white};

	cursor: pointer;
	position: relative;

	display: flex;

	&:hover {
		background-color: ${({ theme }) => theme.colors.darkBlue};
		transition: 0.1s;

		&:after {
				background-color: ${({ theme }) => theme.colors.darkBlue};
				transition: 0.1s;
		}
	}
`;

export const EditButtonText = styled.div`
	${({ theme }) => theme.typography.text_16_19}
	font-weight: 700;
	margin: auto 20px;
	transform: skew(-45deg);
`;

export const MenuWrapper = styled.div`
  width: 11%;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Menu = styled.div`
  width: 7px;
  height: 7px;
  margin: 0 auto;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  transition: 0.1s;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 0;

    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;
  }

  &:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;

    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.1s;

    &:after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
      transition: 0.1s;
    }

    &:before {
      background-color: ${({ theme }) => theme.colors.darkBlue};
      transition: 0.1s;
    }
  }
`;

export const MenuList =
  styled.div <
  Props >
  `
  display: none;
  width: 145px;
  background-color: ${({ theme }) => theme.colors.menuGray};
  position: absolute;
  top: 32px;
  right: 0px;
  z-index: 0;

  ${({ isactive }) =>
    isactive &&
    css`
      display: block;
      z-index: 10;
    `}
`;

export const MenuItem = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.menuActiveGray};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.menuActiveGray};
    transition: 0.1s;
  }
`;

export const MenuItemText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_10_12}
  margin: auto 10px;
`;


export const HelpModal = styled.div<Props>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10001;

  display: none;
  flex-direction: column;

  min-width: 400px;
  max-width: 500px;
  width: 50%;
  height: auto;

  ${({ isactive }) =>
		isactive &&
		css`
			display: flex;
	`}
`

export const HelpModalTitle = styled.div`
  position: relative;

  width: 100%;
  height: 35px;

  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_16_18}
  line-height: 35px;
  background-color: ${({ theme }) => theme.colors.lightBlue};

  user-select: none;
`

export const HelpModalContent = styled.div`
  width: 100%;
  height: calc(100% - 35px);

  padding: 15px 10px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
`

export const HelpModalText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_14_16}
`

export const HelpModalExit = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);

  width: 20px;
  height: 20px;

  cursor: pointer;

  &::before {
    content: '';
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);

    width: 20px;
    height: 2px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};

    transition: 0.2s all
  }

  &::after {
    content: '';
    position: relative;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-100% - 1px)) rotate(-135deg);

    width: 20px;
    height: 2px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.white};

    transition: 0.2s all
  }

  &:hover{
    &::before, &::after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
    }
  }
`

export const OverlayPanel = styled.div<Props>`
  position: absolute;
  top: 30px;
  left: 0;

  display: none;
  
  width: 100%;
  height: 94.5%;
  background-color: ${({ theme }) => theme.colors.black};
  opacity: .75;
  z-index: 10001;

  ${({ isactive }) =>
		isactive &&
		css`
			display: block;
	`}
`
