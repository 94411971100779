// компонент иконки
import React from 'react';
import * as Styled from './styles';

const Play: React.FC = () => {
  return (
    <Styled.PlayRoot>
      <svg
        width="90"
        height="94"
        viewBox="0 0 90 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.26537 87.4686L4.26537 6.06218L74.7654 46.7654L4.26537 87.4686Z"
          stroke="white"
          strokeWidth="7"
        />
      </svg>
    </Styled.PlayRoot>
  );
};

export default Play;
