/* eslint-disable import/no-unresolved */
import React from 'react';
import { DemoAccess } from 'src/components/DemoAccess';
import { LogInSystem } from 'src/components/LogInSystem';
import * as Styled from './styles';
import Target from './Target';

const AutorizationPage: React.FC = () => {
  return (
    <Styled.Root>
      <Target />
      <DemoAccess />
      <LogInSystem />
    </Styled.Root>
  );
};

export default AutorizationPage;
