import React, { useEffect } from 'react';
import { IFormData } from 'src/store/reducers/files';
import * as Styled from './styles';

interface AdminReferenceTableProps {
  type: string;
  table: string[];
  activeAdd: boolean;
  imgSetsInfo: IFormData[];
}

const AdminReferenceTable: React.FC<AdminReferenceTableProps> = ({
  type,
  table,
  activeAdd,
  imgSetsInfo,
}) => {
  useEffect(() => {
    console.log(imgSetsInfo);
  }, []);
  const pendingTable = () => {
    if (type === 'organizations') {
      const newArr = imgSetsInfo?.filter(
        (e, i) =>
          imgSetsInfo.findIndex(
            (a) => a?.organization_name === e?.organization_name
          ) === i
      );
      return newArr?.map((item) => (
        <Styled.TableRow>
          <Styled.TableRowItemWrapper>
            <Styled.TableRowItemText>
              {item?.organization_name}
            </Styled.TableRowItemText>
          </Styled.TableRowItemWrapper>
        </Styled.TableRow>
      ));
    }
    if (type === 'field') {
      const newArr = imgSetsInfo?.filter(
        (e, i) => imgSetsInfo.findIndex((a) => a?.fieldd === e?.fieldd) === i
      );
      return newArr?.map((item) => (
        <Styled.TableRow>
          <Styled.TableRowItemWrapper>
            <Styled.TableRowItemText>
              {item?.organization_name}
            </Styled.TableRowItemText>
          </Styled.TableRowItemWrapper>
          <Styled.TableRowItemWrapper>
            <Styled.TableRowItemText>{item?.fieldd}</Styled.TableRowItemText>
          </Styled.TableRowItemWrapper>
        </Styled.TableRow>
      ));
    }
    if (type === 'mine') {
      const newArr = imgSetsInfo?.filter(
        (e, i) => imgSetsInfo.findIndex((a) => a?.mine === e?.mine) === i
      );
      return newArr?.map((item) => (
        <Styled.TableRow>
          <Styled.TableRowItemWrapper>
            <Styled.TableRowItemText>
              {item?.organization_name}
            </Styled.TableRowItemText>
          </Styled.TableRowItemWrapper>
          <Styled.TableRowItemWrapper>
            <Styled.TableRowItemText>{item?.fieldd}</Styled.TableRowItemText>
          </Styled.TableRowItemWrapper>
          <Styled.TableRowItemWrapper>
            <Styled.TableRowItemText>{item?.mine}</Styled.TableRowItemText>
          </Styled.TableRowItemWrapper>
        </Styled.TableRow>
      ));
    }
    const newArr = imgSetsInfo?.filter(
      (e, i) => imgSetsInfo.findIndex((a) => a?.well === e?.well) === i
    );
    return newArr?.map((item) => (
      <Styled.TableRow>
        <Styled.TableRowItemWrapper>
          <Styled.TableRowItemText>
            {item?.organization_name}
          </Styled.TableRowItemText>
        </Styled.TableRowItemWrapper>
        <Styled.TableRowItemWrapper>
          <Styled.TableRowItemText>{item?.fieldd}</Styled.TableRowItemText>
        </Styled.TableRowItemWrapper>
        <Styled.TableRowItemWrapper>
          <Styled.TableRowItemText>{item?.mine}</Styled.TableRowItemText>
        </Styled.TableRowItemWrapper>
        <Styled.TableRowItemWrapper>
          <Styled.TableRowItemText>{item?.well}</Styled.TableRowItemText>
        </Styled.TableRowItemWrapper>
      </Styled.TableRow>
    ));
  };

  return (
    <Styled.Root>
      <Styled.Table>
        <Styled.TableHeaders>
          {table?.map((item) => (
            <Styled.TableHeaderItemWrapper>
              <Styled.TableHeaderItemText>{item}</Styled.TableHeaderItemText>
            </Styled.TableHeaderItemWrapper>
          ))}
        </Styled.TableHeaders>

        <Styled.TableBody isactive={activeAdd}>
          {pendingTable()}
        </Styled.TableBody>
      </Styled.Table>
    </Styled.Root>
  );
};

export default AdminReferenceTable;
