/* eslint-disable react/button-has-type */
/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable prettier/prettier */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Icon } from 'src/components/UI/Icon';
import { Loading } from 'src/components/UI/Loading';
// import { UploadInfo } from 'src/components/UploadInfo';
import { UploadFileInfo } from 'src/components/UploadFileInfo';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';

import { checkImgInfoAct, overrideImgInfoAct, checkValidAct, fillDragDropAct, localLoadFilesAct, setimgTableAct, selectedTabelsCreatedAct, selectedTabelsDeletedAct, clearImgsTableAct } from 'src/store/reducers/files';
import { getImgsInfo, getFilesInfo, removeImages, updateImgData, sendNewImg, getImgTable, updateImgTable, getDownloadFileCSV, getDownloadFileTXT, getDownloadFileXLSX} from 'src/store/reducers/files/async';

import { fileExtensions, parseExtension } from 'src/utils/files';
import { ChoiseItem, Character } from 'src/store/types';
import * as Styled from './styles';
import { _baseURL } from 'src/env';
import { setActiveId } from 'src/store/reducers/navigation/actions';

const CheckFilesPage: React.FC = () => {
  const [isGalleryTab, setIsGalleryTab] = useState<boolean>(true);

  const [invalidFilenames, setInvalidFilenames] = useState<string[]>([]);
  const [filesCount, setFilesCount] = useState<number>(0);
  const [newFiles, setFiles] = useState<File[]>([]);

  const { authKey } = useAppSelector((state) => state.auth);
  const {
    images,
    files,
    forwardRoutes,
    imagesetId,
    imgInfo,
    loadingState,
    availableImageSets,
    prifileType,
    imgTable,
    selectLanguage,
    selectedTabels,
    imgSetStatus,
  } = useAppSelector((state) => state.files);
  const [imagesChoises, setImagesChoises] = useState<ChoiseItem[]>([]);
  const [pointTableChoises, setPointTableChoises] = useState<Map<number, boolean>>(new Map());
  const [pointNumbersTable, setPointNumbersTable] = useState<Map<number, number>>(new Map());
  const [pointNumber, setPointNumber] = useState<number>(1);
  const [activeAllTables, setActiveAllTabels] = useState<boolean>(false);
  const [activeImgId, setActiveImgId] = useState<number>(0);
  const [renderBigImg, setRenderBigImg] = useState<boolean>(false);
  const [renderBigImgUrl, setRenderBigImgUrl] = useState<string>('');
  const [viewCoreProfile, setViewCoreProfile] = useState<boolean>(true);
  const [activeDownloadMode, setActiveDownloadMode] = useState<boolean>(false);
  // const [fileDownloadError, setFileDonwloadError] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  const { formData, globalInfoImgs } = useAppSelector(
    (state) => state.files
  );
  const [mutInfo, setMutInfo] = useState<Character[]>([]);

  useEffect(() => {
    setMutInfo(imgInfo || []);
  }, [imgInfo]);

  useEffect(() => {
    pointNumbersTable.clear();
  }, []);

  useEffect(() => {
    let imgsType = availableImageSets?.find(
      (item) => item.id === imagesetId
    )?.typeDownloadprofile;
    
    if (!imgsType) {
      imgsType = prifileType;
    }

    setViewCoreProfile(imgsType === 'kern');
  }, [availableImageSets, imagesetId]);

  const mainInfo = useMemo(() => {
    return globalInfoImgs?.storage;
  }, [globalInfoImgs, formData]);

  const anyActive = useMemo(() => {
    return imagesChoises.reduce((prev, curr) => prev || curr.choised, false);
  }, [imgInfo, imagesChoises]);

  const handleChange = (id: number, newVal: any) => {
    const val = mutInfo?.map((inf) => {
      if (inf.idReqCharSettings === id) {
        return {
          ...inf,
          value: newVal,
        };
      }
      return inf;
    });
    setMutInfo((mutInfo) => val);
    dispatch(
      overrideImgInfoAct({
        imgId: activeImgId,
        id,
        value: newVal,
      })
    );
  };

  // useEffect(() => {
  //   console.log(mutInfo);
  // }, [mutInfo]);

  useEffect(() => {
    if (forwardRoutes && imagesetId) {
      dispatch(getImgsInfo({ imageSet: imagesetId, key: authKey, route: forwardRoutes[0] }));
      dispatch(getFilesInfo({ imageSet: imagesetId, key: authKey, route: forwardRoutes[1] }));
    } else if (!!imagesetId && !!authKey) {
      dispatch(getImgsInfo({ imageSet: imagesetId, key: authKey }));
      dispatch(getFilesInfo({ imageSet: imagesetId, key: authKey }));
    }
  }, [imagesetId, forwardRoutes]);

  useEffect(() => {
    if (images?.length > 0) {
      setImagesChoises(
        images?.map((imgInfo) => ({
          id: imgInfo.id,
          choised: false,
        }))
      );
    }
    // console.log(images);
  }, [images]);

  const choiseImg = (id: number) => {
    setImagesChoises(
      imagesChoises.map((imgInfo) => ({
        ...imgInfo,
        choised: id === imgInfo.id ? !imgInfo.choised : false,
      }))
    );
    setActiveImgId(activeImgId !== id ? id : 0)
    dispatch(checkImgInfoAct(id));
  };

  useEffect(() => {
    if ((images.find((item) => item.id === activeImgId))?.status === 'processing...') {
      dispatch(clearImgsTableAct())
    }
  }, [activeImgId])

  const choisePointText = (id: number) => {
    if (pointNumbersTable.get(id) === undefined) {
      setPointNumbersTable(new Map(pointNumbersTable.set(id, pointNumber)));
      setPointNumber((pointNumber) => pointNumber + 1);
    } else {
      const tempNumber = pointNumbersTable.get(id) || 0

      for (const value of pointNumbersTable.keys()) {
        if ((pointNumbersTable.get(value) || 0) >= tempNumber) {
          const temp = pointNumbersTable.get(value) || 0
          setPointNumbersTable(new Map(pointNumbersTable.set(value, temp - 1)))
        }
      }

      setPointNumbersTable((prev) => {
        const newState = new Map(prev);
        newState.delete(id);
        return newState;
      })

      setPointNumber((pointNumber) => pointNumber - 1);
    }
  }

  const choisePoint = (id: number) => {
    setPointTableChoises(new Map(pointTableChoises.set(id, !pointTableChoises.get(id))));

    if (selectedTabels.length > 0) {
      const temp = selectedTabels.find((item) => item === id)
  
      if (temp) {
        dispatch(selectedTabelsDeletedAct(id))
      } else {
        dispatch(selectedTabelsCreatedAct(id))
      }
    } else {
      dispatch(selectedTabelsCreatedAct(id))
    }

    choisePointText(id)
  };

  // const choiseAllPoint = () => {
  //   images?.forEach((tableImage) => {
  //     choisePoint(tableImage.id)
  //   })
  // }

  useEffect(() => {
    console.log(selectedTabels);
  }, [selectedTabels])

  const removeChoised = () => {
    if (imagesetId) {
      dispatch(removeImages({ items: imagesChoises, key: authKey || '', activeImgId, imagesetId}));
    }
  };

  const saveInfo = () => {
    if (prifileType !== 'ocr') {
      if (forwardRoutes) {
        dispatch(updateImgData({ key: authKey || '', imagesetId: Number(forwardRoutes[0].split('/')[forwardRoutes[0].split('/').length - 2]) }))
      } else {
        dispatch(updateImgData({ key: authKey || '', imagesetId: Number(imagesetId) || 0}))
      }
    } else {
      dispatch(updateImgTable({key: authKey || '', imgId: activeImgId, table: imgTable}))
    }
  }

  const handleSelect = (e: React.FormEvent<HTMLLabelElement>) => {
    e.preventDefault();    
   
    const target = e.target as HTMLInputElement;
    setInvalidFilenames([]);

    const fs = [...Array.from(target.files || [])].filter((file) => {
      const splitLen = file.name.split('.').length;
      const fileExt = parseExtension(
        file.name.split('.')[splitLen - 1].toLowerCase()
      );

      if ((fileExt !== "png" && fileExt !== "jpg") && !newFiles.includes(file)) {

        setInvalidFilenames([file.name, ...invalidFilenames]);
        return false;
      }

      return (fileExt === "png" || fileExt === "jpg") && !newFiles.includes(file);
    });

    const body = new FormData();
    setFilesCount(filesCount + fs.length);

    setFiles([...newFiles, ...fs]);
    fs.forEach((file: File) => body.append(file.name, file));

    dispatch(localLoadFilesAct(fs));
    dispatch(fillDragDropAct("img"))
    dispatch(checkValidAct())

    dispatch(
      sendNewImg({
        imagesetId: imagesetId || 0,
        key: authKey || '',
      })
    ).then(() => {
      if (forwardRoutes) {
        dispatch(getImgsInfo({ imageSet: imagesetId, key: authKey, route: forwardRoutes[0] }));
      } else if (!!imagesetId && !!authKey) {
        dispatch(getImgsInfo({ imageSet: imagesetId, key: authKey }));
      }
    })
  };

  // if (loadingState === 'loading' || images.length === 0) {
  //   return <Loading active />
  // }

  if (loadingState === 'loading') {
    return <Loading active />
  }

  return (
    <Styled.Root>
      <Styled.Wrapper>
        <Styled.Box>
          <Styled.TitleWrapper>
            <Styled.SearchIcon name="search" width={15} height={15} />
            <Styled.Title>
              {selectLanguage === 'Ru'
                ? "Проверьте или дополните введенные данные"
                : "Request or supplement the entered data"
              }
            </Styled.Title>
          </Styled.TitleWrapper>

          <Styled.Body>
            {renderBigImg ?
              <Styled.BigImgItem columnRight={viewCoreProfile ? 'calc(100% -  298px)' : 'calc(100% - 510px)'}>
                <Styled.BigImgItemWrapper>
                    <TransformWrapper
                      initialScale={1}
                      // initialPositionX={100}
                      // initialPositionY={50}
                    >
                      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <>
                          <Styled.ZoomToolIn onClick={() => zoomIn()}>+</Styled.ZoomToolIn>
                          <Styled.ZoomToolOut onClick={() => zoomOut()}>-</Styled.ZoomToolOut>
                          <Styled.ZoomToolReset onClick={() => resetTransform()}>x</Styled.ZoomToolReset>

                          <TransformComponent wrapperClass="zoomWrapper" contentClass="zoomContent">
                            <Styled.BigImgItemPhoto
                              src={`${_baseURL}/media/${renderBigImgUrl}`}
                            />
                          </TransformComponent>
                        </>
                      )}
                    </TransformWrapper>
                  <Styled.ImgItemButtons 
                    onClick={(e) => {
                      setRenderBigImg(false);
                    }}
                  >
                    <Icon name="collapse" width={6} height={6} />
                    <Styled.GalleryItemButtonsText>
                      {selectLanguage === 'Ru'
                        ? "Свернуть"
                        : "Collapse"
                      }
                    </Styled.GalleryItemButtonsText>
                  </Styled.ImgItemButtons>
                </Styled.BigImgItemWrapper>
              </Styled.BigImgItem> 
              : null
            }
            {viewCoreProfile ? (
              <Styled.LeftColumn>
                <Styled.GeneralInfoTitle>
                  <Styled.GeneralTextTitle>
                    {selectLanguage === 'Ru'
                      ? "Общая информация"
                      : "General information"
                    }
                  </Styled.GeneralTextTitle>
                </Styled.GeneralInfoTitle>
                <Styled.GeneralInfoWrapper>
                  <>
                    {mainInfo?.map(({ name, value }) => (
                      <Styled.GeneralInfoItemWrapper key={name}>
                        <Styled.GeneralInfoItemTitleWrapper>
                          <Styled.GeneralInfoItemTitleText>{name}</Styled.GeneralInfoItemTitleText>
                        </Styled.GeneralInfoItemTitleWrapper>
                        <Styled.GeneralInfoItemTextArea
                          value={value}
                          readOnly
                        >
                          {value}
                        </Styled.GeneralInfoItemTextArea>
                      </Styled.GeneralInfoItemWrapper>
                    ))}
                  </>

                  <Styled.FilesTitleWrapper>
                    <Styled.FilesTitle>
                      {selectLanguage === 'Ru'
                        ? "Загруженные файлы"
                        : "Uploaded files"
                      }
                    </Styled.FilesTitle>
                  </Styled.FilesTitleWrapper>
                  <Styled.FilesList>
                    {files?.map((file) => (
                      <Styled.File key={file.id}>
                        <Icon width={40} height={40} name={(file.ext as any) || 'docx'} />
                      </Styled.File>
                    ))}
                    {/* {fileDownloadError ? 
                      <Styled.FileDownloadError>
                        <Styled.FileDownloadErrorPoint>
                          !
                        </Styled.FileDownloadErrorPoint>
                        <Styled.FileDownloadErrorText>
                          Исправить неточности
                        </Styled.FileDownloadErrorText>
                        <Styled.FileDownloadErrorWrapper>
                          <Icon name="exclamation" width={12} height={12} />
                        </Styled.FileDownloadErrorWrapper>
                      </Styled.FileDownloadError>
                      : null
                    } */}
                  </Styled.FilesList>
                </Styled.GeneralInfoWrapper>
              </Styled.LeftColumn>
            ) : ''}

            <Styled.MiddleColumn>
              <Styled.TabsWrapper>
                <Styled.GalleryTab
                  isactive={isGalleryTab}
                  onClick={() => setIsGalleryTab(true)}
                >
                  <Styled.TabText isactive={isGalleryTab}>
                    {selectLanguage === 'Ru'
                      ? "Смотреть как галерею"
                      : "Watch as gallery"
                    }
                  </Styled.TabText>
                </Styled.GalleryTab>
                <Styled.ListTab
                  isactive={!isGalleryTab}
                  onClick={() => setIsGalleryTab(false)}
                >
                  <Styled.TabText isactive={!isGalleryTab}>
                    {selectLanguage === 'Ru'
                      ? "Смотреть как список"
                      : "Watch as list"
                    }
                  </Styled.TabText>
                </Styled.ListTab>
              </Styled.TabsWrapper>
              {isGalleryTab ? (
                viewCoreProfile ? (
                  <Styled.GalletyPhotoList>
                    {images?.map((image) => (
                      <Styled.GalleryItem key={image.id} onClick={() => {
                          choiseImg(image.id)
                          console.log(image)
                        }}>
                        <Styled.GalleryItemName>
                          {image.name}
                        </Styled.GalleryItemName>
                        {imagesChoises.filter((c) => c.id === image.id)[0]
                          ?.choised && (
                          <Styled.GalleryChoise>
                            {image.name}
                          </Styled.GalleryChoise>
                        )}
                        <Styled.GalleryPhoto
                          src={`${_baseURL}/media/${image.miniUpload}`}
                        />
                        <Styled.ImgItemButtons 
                          onClick={(e) => {
                            e.stopPropagation(); 
                            setRenderBigImg(true);
                            setRenderBigImgUrl(image.upload);
                            if (activeImgId !== image.id) {
                              choiseImg(image.id);
                            }
                          }}
                        >
                          <Icon name="expand" width={6} height={6} />
                          <Styled.GalleryItemButtonsText>
                            {selectLanguage === 'Ru'
                              ? "Развернуть"
                              : "Expand"
                            }
                          </Styled.GalleryItemButtonsText>
                        </Styled.ImgItemButtons>
                      </Styled.GalleryItem>
                    ))}
                  </Styled.GalletyPhotoList>
                ) : (
                  <>
                  {/* <Styled.TableAllPointChoise>
                    <Styled.TableItemPoint 
                      onClick={() => {
                        setActiveAllTabels(!activeAllTables)
                        choiseAllPoint()
                      }}>
                      {activeAllTables ? <Styled.TablePointChoise /> : null}
                    </Styled.TableItemPoint>
                    {selectLanguage === 'Ru' ? 'Выбрать все таблицы' : 'Select all tables'}
                  </Styled.TableAllPointChoise> */}
                    <Styled.TablePhotoList>
                      {images?.map((tableImage) => (
                        <Styled.TableItem key={tableImage.id} onClick={() => {
                          choiseImg(tableImage.id);
                          dispatch(getImgTable({ idImg: tableImage.id, key: authKey }))
                        }}>
                          <Styled.TableItemWrapperName>
                            {/* <Styled.TableItemPoint 
                              onClick={(e) => {
                                e.stopPropagation();
                                choisePoint(tableImage.id);
                              }}>
                              {pointTableChoises.get(tableImage.id) ? (
                                <Styled.TablePointChoise>
                                  <Styled.TablePointChoiseText>
                                    {pointNumbersTable.get(tableImage.id)}
                                  </Styled.TablePointChoiseText>
                                </Styled.TablePointChoise>
                                ) : null}
                            </Styled.TableItemPoint> */}
                            <Styled.GalleryItemName>
                              {tableImage.name}
                            </Styled.GalleryItemName>
                          </Styled.TableItemWrapperName>
                          {imagesChoises.filter((c) => c.id === tableImage.id)[0]
                            ?.choised && (
                            <Styled.GalleryChoise>
                              <Styled.GalleryChoiseText>
                                {tableImage.name}
                              </Styled.GalleryChoiseText>
                              <Styled.GalleryChoiseText>
                                {tableImage.status}
                              </Styled.GalleryChoiseText>
                            </Styled.GalleryChoise>
                          )}
                          <Styled.GalleryPhoto
                            src={`${_baseURL}/media/${tableImage.upload}`}
                          />
                          <Styled.ImgItemButtons 
                            onClick={(e) => {
                              e.stopPropagation(); 
                              setRenderBigImg(true);
                              setRenderBigImgUrl(tableImage.upload);
                              if (activeImgId !== tableImage.id) {
                                choiseImg(tableImage.id);
                              }
                              dispatch(getImgTable({ idImg: tableImage.id, key: authKey }))
                            }}
                          >
                            <Icon name="expand" width={6} height={6} />
                            <Styled.GalleryItemButtonsText>
                              {selectLanguage === 'Ru'
                                ? "Развернуть"
                                : "Expand"
                              }
                            </Styled.GalleryItemButtonsText>
                          </Styled.ImgItemButtons>
                        </Styled.TableItem>
                      ))}
                    </Styled.TablePhotoList>
                  </>
                )
              ) : (
                <Styled.ListFilesContrainer>
                  {viewCoreProfile ? (
                    images?.map((image) => (
                      <Styled.ListItem
                        key={image.id}
                        choised={
                          imagesChoises.filter((c) => c.id === image.id)[0]
                            ?.choised
                        }
                        onClick={() => choiseImg(image.id)}
                      >
                        {image.name}
                      </Styled.ListItem>
                    ))
                  ) : (
                    images?.map((image) => (
                      <Styled.ListItem
                        key={image.id}
                        choised={
                          imagesChoises.filter((c) => c.id === image.id)[0]
                            ?.choised
                        }
                        onClick={() => {
                          choiseImg(image.id)
                          dispatch(getImgTable({ idImg: image.id, key: authKey }))
                        }}
                      >
                        {image.name}
                      </Styled.ListItem>
                    ))
                  )}
                </Styled.ListFilesContrainer>
              )}
            </Styled.MiddleColumn>

            <Styled.RighttColumn columnRight={viewCoreProfile ? '288px' : '510px'}>
              <Styled.GeneralInfoTitle>
                <Styled.GeneralTextTitle>
                  {selectLanguage === 'Ru'
                    ? "Информация по файлу"
                    : "File Information"
                  }
                </Styled.GeneralTextTitle>
              </Styled.GeneralInfoTitle>

              {viewCoreProfile ? (
                <Styled.InfoFileWrapper>
                  <>
                    {mutInfo?.map((inf) => (
                      <Styled.InfoFileItemWrapper key={inf.idReqCharSettings} selected={anyActive}>
                        <Styled.InfoFileItemTitleWrapper selected={anyActive}>
                          <Styled.GeneralInfoItemTitleText>{inf.name}</Styled.GeneralInfoItemTitleText>
                        </Styled.InfoFileItemTitleWrapper>
                        <Styled.InfoFileItemTextArea
                          disabiled={imgSetStatus !== 'Ready'}
                          value={inf.value}
                          selected={anyActive}
                          onChange={(e) =>
                            handleChange(inf.idReqCharSettings, e.target.value)
                          }
                        />
                      </Styled.InfoFileItemWrapper>
                    ))}
                  </>
                </Styled.InfoFileWrapper>
              ) : (
                <UploadFileInfo activeImgId={activeImgId} />
              )}
            </Styled.RighttColumn>
          </Styled.Body>

          <Styled.ButtonsWrapper>
            <Styled.LabelFile typeProfile={viewCoreProfile} htmlFor="file-input" onChange={handleSelect}>
              <Styled.ButtonFileText>
                  {selectLanguage === 'Ru'
                    ? "Добавить фото"
                    : "Add photo"
                  }
              </Styled.ButtonFileText>
              <Styled.Link id="file-input" multiple />
            </Styled.LabelFile>

            <Styled.ActionsButton
              typeProfile={viewCoreProfile}
              onClick={() => {
                dispatch(clearImgsTableAct());
                choiseImg(activeImgId);
                removeChoised();
                setRenderBigImg(false);
              }}
              desableDownload={activeImgId}
            >
              {/* <Styled.ButtonIcon name="minus" width={15} height={15} /> */}
              <Styled.ButtonText>
                {selectLanguage === 'Ru'
                  ? "Удалить выбранное"
                  : "Delete selected"
                }
              </Styled.ButtonText>
            </Styled.ActionsButton>

            {!viewCoreProfile ? (
                <Styled.DownloadButtonWrapper>
                  <Styled.DownloadButton
                    onClick={() => setActiveDownloadMode(!activeDownloadMode)}
                    desableDownload={activeImgId}
                    isactive={activeDownloadMode}
                  >
                    <Styled.DownloadButtonText>
                      {selectLanguage === 'Ru'
                        ? "Скачать файл"
                        : "Download file"
                      }
                    </Styled.DownloadButtonText>
                  </Styled.DownloadButton>

                  <Styled.DownloadOptionsWrapper>
                      <Styled.DownloadOptionItem
                        onClick={() => {
                          setActiveDownloadMode(!activeDownloadMode)
                          dispatch(getDownloadFileTXT({idImg: activeImgId, key: authKey}))
                        }}
                        isactive={activeDownloadMode}
                      >
                        <Styled.DownloadOptionText isactive={activeDownloadMode}>
                          TXT
                        </Styled.DownloadOptionText>
                      </Styled.DownloadOptionItem>
                      <Styled.DownloadOptionItem
                        onClick={() => {
                          setActiveDownloadMode(!activeDownloadMode)
                          dispatch(getDownloadFileCSV({idImg: activeImgId, key: authKey}))
                        }}
                        isactive={activeDownloadMode}
                      >
                        <Styled.DownloadOptionText isactive={activeDownloadMode}>
                          CSV
                        </Styled.DownloadOptionText>
                      </Styled.DownloadOptionItem>
                      <Styled.DownloadOptionItem
                        onClick={() => {
                          setActiveDownloadMode(!activeDownloadMode)
                          dispatch(getDownloadFileXLSX({idImg: activeImgId, key: authKey}))
                        }}
                        isactive={activeDownloadMode}
                      >
                        <Styled.DownloadOptionText isactive={activeDownloadMode}>
                          XLSX
                        </Styled.DownloadOptionText>
                      </Styled.DownloadOptionItem>
                    </Styled.DownloadOptionsWrapper>
                </Styled.DownloadButtonWrapper>
              ) : (
                ''
              )
            }

            <Styled.SaveButton typeProfile={viewCoreProfile} desableDownload={activeImgId} onClick={saveInfo}>
              <Styled.SaveText>
                {viewCoreProfile ?
                    selectLanguage === 'Ru'
                    ? "Сохранить"
                    : "Save"
                  :
                    selectLanguage === 'Ru'
                    ? "Сохранить таблицу"
                    : "Save table"
                }
              </Styled.SaveText>
              {/* <Styled.ButtonIcon name="next" width={15} height={15} /> */}
            </Styled.SaveButton>
          </Styled.ButtonsWrapper>
        </Styled.Box>
      </Styled.Wrapper>
    </Styled.Root>
  );
};

export default CheckFilesPage;
