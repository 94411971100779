// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Raleway-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Raleway-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Raleway';\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\"); }\n\n@font-face {\n  font-family: 'Raleway';\n  font-weight: 700;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\"); }\n\n.zoomWrapper {\n  margin: 0 auto;\n  display: flex;\n  height: 100%;\n  width: 100%; }\n\n.zoomContent {\n  margin: 0 auto;\n  display: flex;\n  height: 100%;\n  width: 100%; }\n", "",{"version":3,"sources":["webpack://./src/styles/index.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,+DAAoE,EAAA;;AAGtE;EACE,sBAAsB;EACtB,gBAAgB;EAChB,+DAA2D,EAAA;;AAG7D;EACE,cAAc;EACd,aAAa;EACb,YAAY;EACZ,WAAW,EAAA;;AAGb;EACE,cAAc;EACd,aAAa;EACb,YAAY;EACZ,WAAW,EAAA","sourcesContent":["@font-face {\n  font-family: 'Raleway';\n  font-weight: 400;\n  src: url('./fonts/Raleway-VariableFont_wght.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Raleway';\n  font-weight: 700;\n  src: url('./fonts/Raleway-SemiBold.ttf') format('truetype');\n}\n\n.zoomWrapper {\n  margin: 0 auto;\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n\n.zoomContent {\n  margin: 0 auto;\n  display: flex;\n  height: 100%;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
