/* eslint-disable prettier/prettier */
import { Icon } from 'src/components/UI/Icon';
import styled, { css } from 'styled-components';

interface Props {
    isactive?: number;
}

interface CrackProps {
    width: number,
    position: number,
    variant: string
}

export const Root = styled.div`
    width: 100%;
    height: 95.5%;

    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: 1440px) {
        height: 94.5%;
    }
`;

export const BoxInfo = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
`;

export const BoxInfoWrapper = styled.div`
    min-width: 700px;

    display: flex;
    justify-content: space-between;
`

export const BoxInfoText = styled.p`
    font-weight: 700;
    font-size: 16px;

    color: ${({ theme }) => theme.colors.lightBlue};
`;

export const BoxImgWrapper = styled.div<Props>`
    width: 100%;
    display: flex;

    max-height: 100%;
    transition: 0.2s;

    position: relative;

    ${({ isactive }) =>
		!isactive &&
		css`
			max-height: 30px;
            transition: 0.2s;
	`}
`;

export const ShowMaskBtn = styled.button`
    width: 120px;
    height: 32px;
    background-color: ${({ theme }) => theme.colors.mainRed};
    border-radius: 20px;
    border: none;
    margin-top: 20px;
    margin-left: 40px;
    padding: 4px;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    &:disabled {
        opacity: 0.5;
    }
`

export const BoxImg = styled.img<Props>`
    object-fit: cover;
    width: 700px;
    margin: 0 auto;

    box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.lightBlue};
    border-radius: 10px;

    ${({ isactive }) =>
		!isactive &&
		css`
            filter: brightness(50%);
	`}
`;

export const HideActionButton = styled.div<Props>`
    position: absolute;
    cursor: pointer;

    width: 110px;
    height: 55px;
    bottom: -2px;
    right: 50%;
    transform: translate(50%, 0);

    background-color: ${({ theme }) => theme.colors.lightBlue};
    transition: 0.1s;
    border-radius: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    display: flex;
    flex-direction: column;
    
    &:hover {
        background-color: ${({ theme }) => theme.colors.darkBlue};
        transition: 0.1s;
    }

    ${({ isactive }) =>
		!isactive &&
		css`
            top: -3px;
            height: 50px;
            border-radius: 8px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
	`}
`;

export const HideActionArrow = styled(Icon)<Props>`
    transform: rotate(180deg);
    position: absolute;
    left: 35px;
    top: -3px;

    ${({ isactive }) =>
    !isactive &&
    css`
        transform: rotate(0deg);
        top: 15px;
        right: 0;
`}
`;

export const HideActionArrowText = styled.p<Props>`
    font-weight: 400;
    font-size: 14px;
    margin: 0 auto;
    margin-top: 30px;
    color: ${({ theme }) => theme.colors.white};

    ${({ isactive }) =>
    !isactive &&
    css`
        margin-top: 4px;
    `}
`;

export const Ruler = styled.div`
    width: 670px;
    height: 25px;

    border: 0.3px solid #286275;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    padding: 0 5px;
`;

export const RulerMainText = styled.p`
    margin: auto 0;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.black};
`;

export const RulerSmallText = styled.p`
    font-size: 7px;
    color: ${({ theme }) => theme.colors.black};
`;

export const ImageGroup = styled.div`
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Crack = styled.div<CrackProps>`
    position: absolute;
    height: 100%;
    border-radius: 3px;

    top: 0;
    ${({ width, position, variant }) => css`
        width: ${width}px;
        left: ${position - 4}px;
        border: 1px solid ${ variant === 'natural' ? '#35CB00' : '#2323FD' };
    `}
`

export const CropWrapper = styled.div`
  display: flex;
  margin-top: auto;
  width: 100%;
  padding: 0px 12px 30px 12px;
  align-items: center;
  flex-direction: column;
  margin-top: 0px;
`

export const CropImage = styled.img``

export const RulerTextWrapper = styled.div`
    position: relative;
`;

export const RulerSeparator = styled.div`
    position: absolute;
    content: '';
    width: 1px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.black};

    bottom: 0;
    left: 50%;
`;

export const RulerSmallSeparator = styled.div`
    position: absolute;
    content: '';
    width: 0.5px;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.black};

    bottom: 0;
    left: 50%;
`;