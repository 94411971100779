/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface Props {
  level?: number
  itemCount?: number;
  opened?: boolean;
  active?: boolean;
}

export const Root = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  scrollbar-color: ${({ theme }) => theme.colors.mainRed} ${({ theme }) => theme.colors.lightGray};
`;

export const Item = styled.div<Props>`
  width: 97%;
  height: 24px;
  max-height: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
  padding-left: 20px;
  color: ${({ theme }) => theme.colors.lightBlue};
  transition: all 0.2s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  ${({ active }) => active && css`
    background-color: ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.white};
  `}
  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const DropTitle = styled.p`
  width: 90%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => theme.typography.text_14_16}
`

export const DropHead = styled.div<Props>`
  height: 32px;
  background-color: ${({ theme, opened }) => opened ? theme.colors.darkBlue : theme.colors.white};
  transition: all 0.1s;
  color: ${({ theme, opened }) => opened ? theme.colors.white : theme.colors.lightBlue};
  padding: 10px;
  padding-bottom: 0;
  display: flex;
  margin-bottom: 3px;
  cursor: pointer;

  svg {
    transition: all 0.3s;
    color: ${({ theme }) => theme.colors.lightBlue};
    margin-left: auto;
    transform: rotate(${({ opened }) => !opened ? '180deg' : 0});
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};
    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const DropBody = styled.div<Props>`
  width: 100%;
  height: 100%;
  max-height: 0;
  transition: all .4s;
  overflow: hidden;
  padding: 0 5px;
  overflow-y: scroll;
  ${({ opened, itemCount }) => opened && css`
    max-height: ${24 * (itemCount || 0) + 20}px;
  `}
`;
