/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const Root = styled.div`
  width: 74%;
  height: 70vh;
  background-color: ${({ theme }) => theme.colors.white};

  display: flex;
  flex-direction: column;
`;
