/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface Props {
  isactive?: boolean;
}

export const Root = styled.div``;

export const Table = styled.div`
  width: 95%;
  margin: 15px auto;
  border: 2px solid ${({ theme }) => theme.colors.lightBlue};
`;

export const TableHeaders = styled.div`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightBlue};
`;

export const TableHeaderItemWrapper = styled.div`
  flex-basis: 100%;
  text-align: center;
  border-right: 1px solid ${({ theme }) => theme.colors.gray};

  &:last-child {
    border-right: none;
  }
`;

export const TableHeaderItemText = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_14_16}
`;

export const TableBody = styled.div<Props>`
  max-height: 55vh;
  overflow-y: auto;

  ${({ isactive }) => isactive && css`
		max-height: calc(55vh - 60px);
	`}
`;

export const TableRow = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    
    width: 95%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray};
    bottom: 0;
    right: 2.5%;
  }

  &:last-child {
    &::after {
      width: 0%;
    }
  }
`;

export const TableRowItemWrapper = styled.div`
  flex-basis: 100%;
  text-align: center;
  border-right: 1px solid ${({ theme }) => theme.colors.gray};

  &:last-child {
    border-right: none;
  }
`;

export const TableRowItemText = styled.p`
  margin: 6px 0;
  font-weight: 700;
  ${({ theme }) => theme.typography.text_12_14}
`;
