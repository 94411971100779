/* eslint-disable prettier/prettier */
import { Icon } from 'src/components/UI/Icon';
import styled, { css } from 'styled-components';

interface Props {
    isactive?: boolean;
    type?: string;
    typeMenu?: string;
    isError?: boolean;
    opened?: boolean;
}

export const Root = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const Wrapper = styled.div`
  margin: 40px;
  display: flex;
  // flex-direction: column;
`;

export const Tree = styled.div`
  width: 225px;
  height: 77vh;
  margin-top: 1.6vh;
  margin-right: 30px;
  padding: 10px 12px;
  background: ${({ theme }) => theme.colors.white};
`;

export const TreeOrganization = styled.div<Props>`
  display: flex;
  height: 25px;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgGray};

  ${({isactive}) => isactive  && css`
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlue};
  `}
`;

export const TreeOrganizationText = styled.p`
  font-weight: 700;
  ${({ theme }) => theme.typography.text_14_16};
  color: ${({ theme }) => theme.colors.lightBlue};
  margin: auto 0;
  margin-left: 10px;
`;

export const TreeField = styled.div<Props>`
  display: flex;
  min-height: 25px;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgGray};

  ${({isactive}) => isactive  && css`
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlue};
  `}
`;

export const TreeFieldText = styled.p`
  font-weight: 700;
  ${({ theme }) => theme.typography.text_14_16};
  color: ${({ theme }) => theme.colors.lightBlue};
  margin: auto 0;
  margin-left: 10px;
`;

export const TreeMine = styled.div<Props>`
  display: flex;
  height: 25px;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgGray};

  ${({isactive}) => isactive  && css`
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlue};
  `}
`;

export const TreeMineText = styled.p`
  font-weight: 700;
  ${({ theme }) => theme.typography.text_14_16};
  color: ${({ theme }) => theme.colors.lightBlue};
  margin: auto 0;
  margin-left: 10px;
`;

export const TreeWell = styled.div<Props>`
  display: flex;
  height: 25px;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgGray};

  ${({isactive}) => isactive  && css`
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlue};
  `}
`;

export const TreeWellText = styled.p`
  font-weight: 700;
  ${({ theme }) => theme.typography.text_14_16};
  color: ${({ theme }) => theme.colors.lightBlue};
  margin: auto 0;
  margin-left: 10px;
`;

export const TreeOrganizationIcon = styled(Icon)<Props>`
  margin: auto 0;
  color: ${({ theme }) => theme.colors.darkBlue};

  ${({isactive}) => isactive  && css`
    transform: rotate(-90deg);
  `}
`;

export const TreeFieldIcon = styled(Icon)<Props>`
  margin: auto 0;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-left: 10px;

  ${({isactive}) => isactive  && css`
    transform: rotate(-90deg);
  `}
`;

export const TreeMineIcon = styled(Icon)<Props>`
  margin: auto 0;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-left: 20px;

  ${({isactive}) => isactive  && css`
    transform: rotate(-90deg);
  `}
`;

export const TreeWellIcon = styled(Icon)<Props>`
  margin: auto 0;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-left: 30px;

  ${({isactive}) => isactive  && css`
    transform: rotate(-90deg);
  `}
`;

/// ------------------------------------------------------

export const Table = styled.div`
  width: 100%;
  height: 77vh;
  overflow: hidden;
  margin-top: 1.6vh;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const TableHeader = styled.div`
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  display: flex;
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 90px;
  overflow-y: auto;
`

export const TableTextWrapper = styled.div`
  width: 220px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    right: 0px;
    top: 25%;
    height: 50%;
    border-right: 2px solid ${({ theme }) => theme.colors.lightBlue};
  }
`;

export const HeaderText = styled.p`
  ${({ theme }) => theme.typography.text_16_19}
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  text-align: center;
  margin: 0 auto;
  padding: 0 10px;
`;

export const Menu = styled.div<Props>`
  width: 7px;
  height: 7px;
  margin: 0 auto;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  transition: 0.1s;

  &:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 0;

    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;
  }

  &:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;

    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;
  }

  ${({typeMenu}) => typeMenu === "Row" && css`
    background-color: ${({ theme }) => theme.colors.lightTextGray};
    transition: 0.1s;

    &:after {
      background-color: ${({ theme }) => theme.colors.lightTextGray};
      transition: 0.1s;
    }
  
    &:before {
      background-color: ${({ theme }) => theme.colors.lightTextGray};
      transition: 0.1s;
    }
  `}
`;

export const MenuWrapper = styled.div<Props>`
  width: 30px;
  height: 20px;
  display: flex;
  margin: auto 0;
  margin-left: auto;
  margin-right: 2vw;
  align-items: center;
  position: relative;
  cursor: pointer;

  ${({typeMenu}) => typeMenu === "Row" && css`
    height: 80px;
  `}

  &:hover {
    & div${Menu} {
      background-color: ${({ theme }) => theme.colors.lightBlue};
      transition: 0.1s;
      &:after {
        background-color: ${({ theme }) => theme.colors.lightBlue};
        transition: 0.1s;
      }
      &:before {
        background-color: ${({ theme }) => theme.colors.lightBlue};
        transition: 0.1s;
      }

      ${({typeMenu}) => typeMenu === "Row" && css`
        background-color: ${({ theme }) => theme.colors.darkTextGray};
        transition: 0.1s;
        &:after {
          background-color: ${({ theme }) => theme.colors.darkTextGray};
          transition: 0.1s;
        }
        &:before {
          background-color: ${({ theme }) => theme.colors.darkTextGray};
          transition: 0.1s;
        }
      `}
    }
  }
`;

export const MenuList = styled.div<Props>`
  display: none;
  width: 150px;
  max-height: 100px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.menuGray};
  position: absolute;
  top: 37px;
  right: -20px;
  z-index: 0;

  ${({isactive}) => isactive && css`
    display: block;
    z-index: 10;
  `}

  ${({typeMenu}) => typeMenu === "Row" && css`
    top: 50px;
    right: -30px;
  `}
`;

export const MenuItem = styled.div<Props>`
  width: auto;
  height: 20px;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.menuActiveGray};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.menuActiveGray};
    transition: 0.1s;
  }

  ${({isactive, theme}) => !isactive && css`
    background-color: ${theme.colors.gray};
    pointer-events: none;
    cursor: none;
  `}
`;

export const MenuItemText = styled.div<Props>`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_10_12}
  margin: auto 10px;

  ${({isactive, theme}) => !isactive && css`
    color: ${theme.colors.menuActiveGray};
  `}
`;

export const RowWrapper = styled.div`
  width: 100%;
  // min-height: 80px;
  display: flex;
  position: relative;

  &:last-child {
    margin-bottom: 3%;
  }

  &:after {
    content: '';
    position: absolute;
    width: 90%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.bgGray};
    top: 0;
    right: 90px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray};
    transition: 0.1s;
  }
`;

export const RowItemBox = styled.div`
  width: 220px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const RowItem = styled.p<Props>`
  ${({ theme }) => theme.typography.text_16_19}
  margin: 0 auto;
  color: darkTextGray;
  max-width: 80%;
  text-align: center;
  overflow: hidden;
  
  ${({type}) => type === "name" && css`
    color: ${({ theme }) => theme.colors.black};
    font-weight: 600;
  `}

  ${({type}) => type === "imgCount" && css`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-family: 'Arial';
    font-weight: 700;
  `}

  ${({type}) => type === "filesCount" && css`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-family: 'Arial';
    font-weight: 700;
  `}

  ${({type}) => type === "date" && css`
    font-family: 'Arial';
  `}
`
