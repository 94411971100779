/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Analytics } from 'src/components/Analytics';
import { Drilling } from 'src/components/Drilling';
import { Parameters } from 'src/components/Parameters';
import { Tree } from 'src/components/Tree';
import { Loading } from 'src/components/UI/Loading';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { setModifiedCracksAct } from 'src/store/reducers/cracksChange';
import { saveSelectTreeItemAct } from 'src/store/reducers/files';
import {
  getAnaliz,
  getCropedImages,
  getLegend,
  getTree,
} from 'src/store/reducers/files/async';
import * as Styled from './styles';

export interface TreeSet {
  idSet: number;
  fieldd: string;
  mine: string;
  well: string;
  images: string[];
}

export interface Tree {
  name: string;
  imageSets: TreeSet[];
}

const CheckLito: React.FC = () => {
  const [contentType, setContentType] = useState<string>('parameters');
  const [localLoader, setLocalLoader] = useState<boolean>(false);
  const [mutReporting] = useState<boolean>(true);
  const [search, setSearch] = useState<string>('');
  const dispatch = useAppDispatch();
  const { authKey } = useAppSelector((state) => state.auth);
  const { table, tablePhotos, typeCrop, selectLanguage, loadingState } =
    useAppSelector((state) => state.files);
  const { id: imageId } = useAppSelector(
    (state) => state.files.selectedTreeItem
  );
  const { state } = useLocation();
  const { id } = useParams();

  useEffect(() => {
    console.log(loadingState);
  }, []);

  useEffect(() => {
    if (!tablePhotos.find((item) => item.id === imageId)) {
      dispatch(
        saveSelectTreeItemAct({
          name: tablePhotos?.[0]?.imgName,
          url: tablePhotos?.[0]?.upload,
          id: tablePhotos?.[0]?.id,
          numBox: tablePhotos?.[0]?.numBox,
          range: tablePhotos?.[0]?.range,
        })
      );
    }
  }, [tablePhotos, imageId]);

  useEffect(() => {
    if (imageId) {
      dispatch(
        getCropedImages({
          key: authKey || '',
          imageId,
          type: typeCrop,
        })
      );
    }
  }, [tablePhotos]);

  useEffect(() => {
    setLocalLoader(true);
    setTimeout(() => setLocalLoader(false), 400);
    dispatch(setModifiedCracksAct([]));

    // let legendType = 'all';
    // if (contentType === 'analytics') {
    //   legendType = 'all';
    // } else {
    //   legendType = typeCrop;
    // }

    console.log('Id: ', id);
    if (id) {
      dispatch(getTree({ key: authKey || '', imageSetId: id }));

      dispatch(
        getAnaliz({
          key: authKey || '',
          imageSetId: Number(id),
          typeCrop,
        })
      );
      dispatch(
        getLegend({
          key: authKey || '',
          imageSetId: Number(id),
          type: typeCrop,
        })
      );
    }
  }, [state, imageId, typeCrop, contentType]);

  // useEffect(() => {
  //   if (table.length === 0 && typeCrop !== '') {
  //     dispatch(
  //       getAnaliz({
  //         key: authKey || '',
  //         imageSetId: Number(id),
  //         typeCrop,
  //       })
  //     );
  //   }
  // }, [table]);

  const ChoiceContent: React.FC = () => {
    if (contentType === 'analytics') {
      return <Analytics data={table} />;
    } else if (contentType === 'parameters') {
      return <Parameters />;
    } else {
      return <Drilling />;
    }
  };

  return (
    <Styled.Root>
      <Loading active={loadingState === 'loading' || localLoader} />

      <Styled.Wrapper>
        <Styled.LitoNavigation>
          <Styled.SearchBarWrapper>
            <Styled.SearchInput
              placeholder="Поиск"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Styled.SearchButton>
              <Styled.SearchIcon name="search" width={10} height={10} />
            </Styled.SearchButton>
          </Styled.SearchBarWrapper>
          <Styled.ProjectsList>
            <Tree tree={tablePhotos} searchStr={search} />
          </Styled.ProjectsList>
        </Styled.LitoNavigation>

        <Styled.Box>
          <Styled.MainLitoTabs>
            <Styled.TabWrapper
              contentType={contentType}
              tabType="parameters"
              onClick={() => setContentType('parameters')}
            >
              <Styled.TabText>
                {selectLanguage === 'Ru' ? 'Параметры ящика' : 'Box parameters'}
              </Styled.TabText>
              <Styled.TabIcon
                name="settings-mini"
                width={11}
                height={8}
                lastitem={0}
              />
            </Styled.TabWrapper>
            <Styled.TabWrapper
              contentType={contentType}
              tabType="analytics"
              onClick={() => setContentType('analytics')}
            >
              <Styled.TabText>
                {selectLanguage === 'Ru'
                  ? 'Аналитика по скважине'
                  : 'Well analytics'}
              </Styled.TabText>
              <Styled.TabIcon
                name="analytics"
                width={8}
                height={7}
                lastitem={0}
              />
            </Styled.TabWrapper>
            <Styled.TabWrapper
              contentType={contentType}
              tabType="drilling"
              disabled={mutReporting ? 1 : 0}
              onClick={() => {
                return mutReporting ? null : setContentType('drilling');
              }}
            >
              <Styled.TabText>
                {selectLanguage === 'Ru' ? 'Отчетность' : 'Reporting'}
              </Styled.TabText>
              <Styled.TabIcon
                name="drilling"
                width={11}
                height={8}
                lastitem={1}
              />

              <Styled.DisabiledInfo disabled={mutReporting ? 1 : 0}>
                <Styled.DisabiledInfoText>
                  {selectLanguage === 'Ru'
                    ? 'Недоступно в Демо версии'
                    : 'Not available in the Demo version'}
                </Styled.DisabiledInfoText>
              </Styled.DisabiledInfo>
            </Styled.TabWrapper>
          </Styled.MainLitoTabs>
          <ChoiceContent />
        </Styled.Box>
      </Styled.Wrapper>
    </Styled.Root>
  );
};

export default CheckLito;
