import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import {
  IImgTable,
  ITableTitle,
  setimgTableAct,
} from 'src/store/reducers/files';
import * as Styled from './styles';

interface UploadFileInfoProps {
  activeImgId: number;
}

const UploadFileInfo: React.FC<UploadFileInfoProps> = ({
  activeImgId,
}: UploadFileInfoProps) => {
  const { tableTitle, imgTable } = useAppSelector((state) => state.files);
  const [fieldsTable, setFieldsTable] = useState<IImgTable[][]>([]);
  const [maxDepth, setMaxDepth] = useState<number>(1);
  const [maxLengthChildren, setMaxLengthChildren] = useState<number>(0);
  const [tempMap, setTempMap] = useState<Map<number, number>>(new Map());
  const [maxChildren, setMaxChildren] = useState<Map<number, number>>(
    new Map()
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log(imgTable);
  }, [imgTable]);

  const getMaxDepth = (data: ITableTitle[]) => {
    const dfs = (data: ITableTitle) => {
      if (!data.children || data.children?.length === 0) {
        return 1;
      }

      let maxD = 0;
      data.children?.forEach((item) => {
        maxD = Math.max(maxD, dfs(item));
      });

      return maxD + 1;
    };

    let maxD = 0;
    data?.forEach((item) => {
      maxD = Math.max(maxD, dfs(item));
    });

    return maxD;
  };

  const setSumChildren = (data: ITableTitle[]) => {
    const dfs = (data: ITableTitle) => {
      if (!data.children || data.children?.length === 0) {
        setMaxChildren(maxChildren.set(data.id, 1));
        setTempMap(tempMap.set(data.id, data.num));
        return 1;
      }

      let sum = 0;
      data.children?.forEach((item) => {
        sum += dfs(item);
      });

      setMaxChildren(maxChildren.set(data.id, sum));
      return sum;
    };

    let sum = 0;
    data?.forEach((item) => {
      sum += dfs(item);
    });

    setMaxLengthChildren(sum);
  };

  const renderTableTitel = (data: ITableTitle[]) => {
    const renderTableItems = (
      data: ITableTitle[],
      level = 1,
      columnMin = 1,
      columnMax = 1
    ) => {
      const items = data?.map((item) => {
        if (!item.children) {
          columnMin = columnMax;
          columnMax++;
          return (
            <Styled.TableTitleText
              key={item.id}
              rowMin={`${level}`}
              rowMax={`${maxDepth + 1}`}
              columnMin={`${columnMin}`}
              columnMax={`${columnMax}`}
            >
              {item.title}
            </Styled.TableTitleText>
          );
        }

        let lengthChildrens = 0;
        if (maxChildren.get(item.id)) {
          lengthChildrens = maxChildren.get(item.id) || 0;
        }

        columnMin = columnMax;
        columnMax += lengthChildrens;

        return (
          <>
            <Styled.TableTitleText
              key={item.id}
              rowMin={`${level}`}
              rowMax={`${level + 1}`}
              columnMin={`${columnMin}`}
              columnMax={`${columnMax}`}
            >
              {item.title}
            </Styled.TableTitleText>
            {renderTableItems(
              item.children,
              level + 1,
              columnMin,
              columnMax - lengthChildrens
            )}
          </>
        );
      });

      return items;
    };

    return (
      <Styled.TableTitle
        rowLenght={`${maxDepth}`}
        columnLenght={`${maxLengthChildren}`}
      >
        {renderTableItems(data)}
      </Styled.TableTitle>
    );
  };

  useEffect(() => {
    setMaxChildren((prev) => {
      const newState = new Map(prev);
      newState.clear();
      return newState;
    });

    setTempMap((prev) => {
      const newState = new Map(prev);
      newState.clear();
      return newState;
    });

    setMaxDepth(getMaxDepth(tableTitle));
    setSumChildren(tableTitle);
  }, [tableTitle]);

  const fillingFields = (data: any) => {
    setFieldsTable([]);

    const newFullTable: any = [];
    for (let i = 0; i < data.length; i++) {
      const newArrTableItems: any = [];
      [...tempMap.keys()]?.forEach((id) => {
        const newObj = {
          value: '',
          confidence: 0,
          column_inst__id: id,
          color: '',
        };
        newArrTableItems.push(newObj);
      });
      newFullTable.push(newArrTableItems);
    }

    newFullTable?.forEach((item, i) => {
      item.forEach((table, j) => {
        const value = data[i].find(
          (rowItem) =>
            rowItem.column_inst__id === newFullTable[i][j].column_inst__id
        );

        if (value) {
          newFullTable[i][j] = { ...value };
        }
      });
    });
    setFieldsTable([...newFullTable]);
  };

  useEffect(() => {
    fillingFields(activeImgId !== 0 ? imgTable : []);
  }, [imgTable]);

  return (
    <Styled.InfoFileWrapper>
      {renderTableTitel(tableTitle)}
      <Styled.TableContentWrapper>
        {fieldsTable?.map((item, i) => (
          <Styled.TebleContentItemWrapper
            key={i}
            columnLenght={`${maxLengthChildren}`}
          >
            {item.map((info, j) => (
              <Styled.TebleContentItem
                value={info.value || ''}
                // selected={anyActive}
                bg={info.color}
                onChange={(e) => {
                  const tempTable = [...fieldsTable];
                  tempTable[i][j] = {
                    ...tempTable[i][j],
                    value: e.target.value,
                    confidence: 1,
                    color: 'white',
                    edit: 1,
                  };
                  // if (tempTable[i][j].value !== '') {
                  //   tempTable[i][j] = {
                  //     ...tempTable[i][j],
                  //     value: e.target.value,
                  //     confidence: 1,
                  //     color: 'white',
                  //     edit: tempTable[i][j].edit !== 2 ? 1 : 2,
                  //   };
                  // } else {
                  //   tempTable[i][j] = {
                  //     ...tempTable[i][j],
                  //     value: e.target.value,
                  //     confidence: 1,
                  //     color: 'white',
                  //     edit: 2,
                  //   };
                  // }
                  setFieldsTable([...tempTable]);
                  dispatch(setimgTableAct(fieldsTable));
                }}
              />
            ))}
          </Styled.TebleContentItemWrapper>
        ))}
      </Styled.TableContentWrapper>
      <Styled.TableBottomPanel />
    </Styled.InfoFileWrapper>
  );
};

export default UploadFileInfo;
