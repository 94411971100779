const typography = {
  text_20_23: {
    fontSize: '20px',
    lineHeight: '23px',
  },
  text_14_16: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  text_16_19: {
    fontSize: '16px',
    lineHeight: '19px',
  },
  text_16_18: {
    fontSize: '16px',
    lineHeight: '18px',
  },
  text_15_18: {
    fontSize: '15px',
    lineHeight: '18px',
  },
  text_12_14: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  text_10_12: {
    fontSize: '10px',
    lineHeight: '12px',
  },
  text_8_10: {
    fontSize: '8px',
    lineHeight: '10px',
  },
  text_38_45: {
    fontSize: '38px',
    lineHeight: '45px',
  },
  text_82_95: {
    fontSize: '82px',
    lineHeight: '95px',
  },
};

export default typography;
