/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { AdminOtherSettings } from 'src/components/AdminOtherSettings';
import { AdminPersonalSettings } from 'src/components/AdminPersonalSettings';
import { AdminReferences } from 'src/components/AdminReferences';
import { getAllImgSets } from 'src/store/reducers/files/async';
import * as Styled from './styles';

const Administration: React.FC = () => {
  const [contentType, setContentType] = useState<string>('references');
  const { selectLanguage } = useAppSelector((state) => state.files);
  const { authKey } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const ChoiceContent: React.FC = () => {
    if (contentType === 'references') {
      return <AdminReferences />;
    } else if (contentType === 'personal_settings') {
      return <AdminPersonalSettings />;
    } else {
      return <AdminOtherSettings />;
    }
  };

  useEffect(() => {
    dispatch(getAllImgSets({ key: authKey || '' }));
  }, []);

  return (
    <Styled.Root>
      <Styled.Wrapper>
        <Styled.MainLitoTabs>
          <Styled.TabWrapper
            contentType={contentType}
            tabType="references"
            onClick={() => setContentType('references')}
          >
            <Styled.TabText>
              {selectLanguage === 'Ru' ? 'Справочники' : 'References'}
            </Styled.TabText>
            <Styled.TabIcon name="analytics" width={8} height={7} />
          </Styled.TabWrapper>
          <Styled.TabWrapper
            contentType={contentType}
            tabType="personal_settings"
            onClick={() => setContentType('personal_settings')}
          >
            <Styled.TabText>
              {selectLanguage === 'Ru'
                ? 'Личные настройки'
                : 'Personal settings'}
            </Styled.TabText>
            <Styled.TabIcon name="settings-mini" width={11} height={8} />
          </Styled.TabWrapper>
          <Styled.TabWrapper
            contentType={contentType}
            tabType="other"
            onClick={() => setContentType('other')}
          >
            <Styled.TabText>
              {selectLanguage === 'Ru' ? 'Прочие' : 'Other'}
            </Styled.TabText>
            <Styled.TabIcon name="drilling" width={11} height={8} last={1} />
          </Styled.TabWrapper>
        </Styled.MainLitoTabs>

        <ChoiceContent />
      </Styled.Wrapper>
    </Styled.Root>
  );
};

export default Administration;
