import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { logOut } from './actions';
import axios from 'axios';
import { ICompany } from 'src/store/types';

export interface AuthState {
  isLogged: boolean;
  loadingState: 'loading' | 'failed' | 'success';
  authKey?: string;
  error?: string;
  login?: string;
  companiesList?: ICompany[];
}

const initialState: AuthState = {
  isLogged: false,
  loadingState: 'success',
  companiesList: [],
};

export const logIn = createAsyncThunk(
  'auth/login',
  async ({ email, password }: { email: string, password: string }) => {
    console.log('login');

    const { data } = await axios.post('/auth/login/', { email, password });

    return { key: data.key, email };
  }
);

export const getCompanies = createAsyncThunk(
  'auth/getCompanies',
  async (authKey?: string) => {
    const { data } = await axios.get('/auth/companies-list/', {
      headers: {
        Authorization: `Token ${authKey}`,
      },
    });

    return data;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut,
  },
  extraReducers: (builder) => {
    builder.addCase(logIn.pending, (state) => {
      state.loadingState = 'loading';
      console.log('loading');
    });

    builder.addCase(logIn.rejected, (state, action) => {
      state.loadingState = 'failed';
      console.log('failed');

      state.error = action.error.code;
      state.isLogged = false;
    });

    builder.addCase(logIn.fulfilled, (state, { payload }) => {
      console.log(payload);
      state.loadingState = 'success';
      state.isLogged = true;
      state.login = payload.email;
      state.authKey = payload.key;
      console.log(state.authKey);
    });

    // builder.addCase(getCompanies.pending, (state) => {
    //   state.loadingState = 'loading';
    // });

    builder.addCase(getCompanies.rejected, (state) => {
      state.loadingState = 'failed';
    });

    builder.addCase(getCompanies.fulfilled, (state, action) => {
      console.log('getCompanies: ', action.payload);
      state.companiesList = action.payload;
      state.loadingState = 'success';
    });
  },
});

export const { logOut: logout } = authSlice.actions;

export default authSlice.reducer;
