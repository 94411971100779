/* eslint-disable prettier/prettier */
import { Icon } from 'src/components/UI/Icon';
import styled, { css } from 'styled-components';

interface Props {
  isactive?: boolean;
}

export const Root = styled.div`
  width: 74%;
  height: 70vh;
  background-color: ${({ theme }) => theme.colors.white};

  display: flex;
  flex-direction: column;
`;

export const SortSettingsItemWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;

  &::after {
    position: absolute;
    z-index: 2;
    content: '';
    width: 100%;
    height: 1px;
    bottom: -20px;
    left: 0;
    background-color: ${({ theme }) => theme.colors.gray};
  }
`;

export const SortSettingsItemTitle = styled.p`
  font-weight: 700;
  ${({ theme }) => theme.typography.text_16_19}
`;

export const SortSettingsItemBody = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
`;

export const MultipleCheckBoxItem = styled.div`
  margin: 15px 0 0 0;
  display: flex;
`;

export const CheckBox = styled.div<Props>`
  width: 15px;
  height: 15px;
  border: 1.5px solid ${({ theme }) => theme.colors.darkBlue};

  cursor: pointer;

  ${({isactive}) => isactive && css`
    background-color: ${({ theme }) => theme.colors.darkBlue};
  `}
`;

export const CheckBoxText = styled.p`
  font-weight: 700;
  ${({ theme }) => theme.typography.text_16_19}
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 0 0 0 25px;
`;

export const SelectSortBox = styled.div`
  position: relative;
  cursor: pointer;

  margin-top: 15px;
  width: 270px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.darkBlue};

  display: flex;
  justify-content: space-between;
`;

export const SelectSortBoxText = styled.p`
  margin: auto;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_16_19}
`;

export const SelectIcon = styled(Icon)<Props>`
  margin: auto 0;
  margin-right: 5px;
  transition: 0.3s;

  ${({isactive}) => isactive && css`
    transform: rotate(180deg);
    transition: 0.3s;
  `}
`;

export const SelectSortOptionsBox = styled.div<Props>`
  position: absolute;
  max-height: 0px;
  transition: 0.3s;
  top: 95%;
  z-index: 5;

  display: flex;
  flex-direction: column;

  width: 270px;
  background-color: ${({ theme }) => theme.colors.lightBlue};

  ${({isactive}) => isactive && css`
    max-height: 300px;
    transition: 0.3s;
  `}
`;

export const SelectBoxOption = styled.div<Props>`
  width: 100%;
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }

  ${({isactive}) => !isactive && css`
    pointer-events: none;
  `}
`;

export const SelectBoxOptionText = styled.p<Props>`
  margin: 5px auto;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_16_19}

  ${({isactive}) => !isactive && css`
    font-size: 0;
    transition: 1s;
  `}
`;

export const SortSettingsItemInput = styled.input`
  width: 270px;
  height: 32px;
  background: ${({ theme }) => theme.colors.bgGray};
  border: 1.5px solid ${({ theme }) => theme.colors.darkBlue};

  margin-top: 15px;
  padding: 0 10px;

  outline: none;
  cursor: pointer;
  font: inherit;

  font-weight: 700;
  ${({ theme }) => theme.typography.text_16_19}
`;