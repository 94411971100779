/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';
import { Icon } from '../UI/Icon';

interface Props {
  activeCheckbox?: boolean;
}

export const Root = styled.div`
  width: 50vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.dirtyWhite};
  padding-top: 25px;
`;

export const LoginTitle = styled.h2`
  ${({ theme }) => theme.typography.text_20_23}
  color: ${({ theme }) => theme.colors.lightBlue};

  margin: 0px;
`;

export const AccessRequest = styled.a`
  text-decoration: none;

  ${({ theme }) => theme.typography.text_14_16}
  color: ${({ theme }) => theme.colors.mainRed};

  position: relative;

  &:after {
    content: '';
    height: 1px;
    width: 100%;

    background: ${({ theme }) => theme.colors.mainRed};
    position: absolute;

    bottom: 1px;
    right: 0px;
  }
`;

export const LoginHint = styled.p`
  ${({ theme }) => theme.typography.text_14_16}
  color: ${({ theme }) => theme.colors.black};

  padding-top: 20px;
  margin-bottom: 8px;
`;

export const PasswordHint = styled.p`
  ${({ theme }) => theme.typography.text_14_16}
  color: ${({ theme }) => theme.colors.black};

  padding-top: 5px;
  margin-bottom: 8px;
`;

export const LoginInput = styled.input`
  background: ${({ theme }) => theme.colors.white};
  height: 30px;
  width: 350px;
  padding: 0;

  padding-left: 4px;

  outline: none;
  border: 0;
`;

export const PasswordInput = styled.input`
  background: ${({ theme }) => theme.colors.white};
  height: 30px;
  width: 320px;
  padding: 0;

  padding-left: 4px;

  outline: none;
  border: 0;
`;

export const CustomIcon = styled(Icon)`
  padding-top: 4px;
  margin-left: 3px;
`;

export const IconWrapper = styled.div`
  height: 32px;
  width: 30px;
  background: ${({ theme }) => theme.colors.bgGray};

  cursor: pointer;
`;

export const FlexPassword = styled.div`
  display: flex;
`;

export const LoginButton = styled.button`
  ${({ theme }) => theme.typography.text_16_19}
  width: 350px;
  height: 30px;
  text-align: center;
  background: ${({ theme }) => theme.colors.mainRed};
  color: ${({ theme }) => theme.colors.white};

  margin-top: 8px;
  outline: none;
  border: 0;

  cursor: pointer;

  &:disabled {
    background: ${({ theme }) => theme.colors.mainRedDisabled};
  }
`;

export const AdditionalOptions = styled.div`
  display: flex;
  justify-content: space-between;

  width: 350px;
`;

export const CastomCheckboxWrapper = styled.div`
  display: flex;
`;

export const CastomCheckbox = styled.div<Props>`
  margin-top: 11px;
  margin-right: 5px;
  cursor: pointer;

  width: 9px;
  height: 9px;
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  ${({ activeCheckbox }) =>
    activeCheckbox
      ? css`
          background: ${({ theme }) => theme.colors.lightBlue};
        `
      : css`
          background: 0;
        `}
`;

export const CastomCheckboxText = styled.p`
  ${({ theme }) => theme.typography.text_10_12}
  color: ${({ theme }) => theme.colors.lightBlack};
  width: 82px;
`;

export const ButtonProblems = styled.a`
  text-decoration: none;
  ${({ theme }) => theme.typography.text_10_12}
  color: ${({ theme }) => theme.colors.lightBlack};

  padding-top: 1em;
`;

export const LoginWrapper = styled.div`
  position: absolute;
  right: 0;

  padding-top: 196px;
  padding-right: 80px;
`;

export const LoginFooter = styled.div`
  display: flex;
  justify-content: space-between;

  width: 50vw;
  position: absolute;
  bottom: 15px;
`;

export const DataStoragePolicy = styled.a`
  ${({ theme }) => theme.typography.text_14_16}
  color: ${({ theme }) => theme.colors.black};
  width: 237px;
  text-decoration-line: underline;
  text-decoration-style: solid;

  padding-top: 58px;
  padding-left: 15px;
`;

export const LogoIcon = styled(Icon)`
  width: 135px;
  height: 100px;
  color: ${({ theme }) => theme.colors.black};
  margin-right: 20px;
`;
