/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable no-await-in-loop */
/* eslint-disable unused-imports/no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Crack } from 'src/components/Parameters/LitoParameters/LitoParameters';
import { ChoiseItem } from 'src/store/types';
import { toCamelCase } from 'src/utils';
import { IImgTable, setLoadingBarAct, setLoadingBarSizeAct } from '.';
import { setActivePathAct, setSubPath } from '../navigation';

// export const addCrack = createAsyncThunk(
//   'files/add-crack',
//   async ({
//     key,
//     imageId,
//     crackPos,
//   }: {
//     key: string,
//     imageId: number,
//     crackPos: number,
//   }) => {
//     const newCrack: Crack = {
//       confidence: 100,
//       crackType: 'natural',
//       layerColor: '',
//       layerInstEndPix: crackPos + 15,
//       layerInstPixMiddle: crackPos + 3,
//       layerInstStartPix: crackPos - 10,
//     };
//     const snackeCrack = {
//       layer_inst__pix_middle: newCrack.layerInstPixMiddle,
//       layer_inst__start_pix: newCrack.layerInstStartPix,
//       layer_inst__end_pix: newCrack.layerInstEndPix,
//       crack_type: newCrack.crackType,
//       description: '',
//     };
//     const { data } = await axios.post(
//       `io/crack/create/${imageId}/`,
//       snackeCrack,
//       {
//         headers: {
//           Authorization: `Token ${key}`,
//         },
//       }
//     );

//     newCrack.id = data.id;
//     return {
//       id: imageId,
//       newCrack,
//     };
//   }
// );

// export const updateCrack = createAsyncThunk(
//   'file/update-crack',
//   async ({ key, cracks }: { key: string, cracks: Crack[] }) => {
//     const snackeCracks: any = [];

//     cracks.forEach((item) => {
//       snackeCracks.push({
//         crack_id: item.id,
//         layer_inst__pix_middle: item.layerInstPixMiddle,
//         layer_inst__start_pix: item.layerInstStartPix,
//         layer_inst__end_pix: item.layerInstEndPix,
//         crack_type: item.crackType,
//         description: '',
//       });
//     });

//     const { data } = await axios.post(`io/crack/update/`, snackeCracks, {
//       headers: {
//         Authorization: `Token ${key}`,
//       },
//     });
//     return {
//       cracks,
//     };
//   }
// );

// export const removeCrack = createAsyncThunk(
//   'files/remove-crack',
//   async ({
//     key,
//     imageId,
//     crackId,
//   }: {
//     key: string,
//     imageId: number,
//     crackId: number,
//   }) => {
//     const { data } = await axios.delete(`io/crack/delete/${crackId}/`, {
//       headers: {
//         Authorization: `Token ${key}`,
//       },
//     });
//     console.log(data);
//     return {
//       imageId,
//       crackId,
//     };
//   }
// );

export const getCropedImages = createAsyncThunk(
  'files/croped',
  async ({
    key,
    imageId,
    type,
  }: {
    key: string,
    imageId: number,
    type: string,
  }) => {
    const { data } = await axios.get(
      `/io/get-croped-images/${imageId}/type:${type === '' ? 'crop' : type}/`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );

    return data;
  }
);

// создаем асинхронный action -> первый аргумент - имя состоящее из имя редусера и метода
export const uploadFiles = createAsyncThunk(
  'files/postFiles',
  async (
    { profileId, key }: { profileId: number, key: string },
    { getState, dispatch }
  ) => {
    const { files }: any = getState();
    const type1 = files.prifileType === 'ocr' ? 'ocr' : 'downloadprofile';
    const type2 = files.prifileType === 'ocr' ? '-ocr' : '';
    let imagesetId;

    try {
      const { data } = await axios.post(
        `/${type1}/create-img-set${type2}/`,
        {
          download_profile: profileId,
          ...files.formData,
          organization: files.selectedCompany,
        },
        {
          headers: {
            Authorization: `Token ${key}`,
          },
        }
      );
      imagesetId = data?.id;
    } catch (error) {
      console.log(error);
    }

    try {
      const keysArr: string[] = Array.from(files.uploadedFilesNow.keys());
      const step = 100 / Math.ceil(keysArr.length / 5);
      let size = 0;

      console.log(keysArr.length);
      dispatch(setLoadingBarAct(true));

      for (let i = 0; i < keysArr.length; i += 5) {
        console.log('after upload: ', name);
        const img = new FormData();

        if (i + 4 < keysArr.length) {
          for (let j = i; j < i + 5; j++)
            img.append(keysArr[j], files.uploadedFilesNow.get(keysArr[j]));
        } else {
          for (let j = i; j < keysArr.length; j++) {
            img.append(keysArr[j], files.uploadedFilesNow.get(keysArr[j]));
          }
        }

        size += step;
        dispatch(setLoadingBarSizeAct(size));

        const res = await axios.post(
          `${type1}/send-img-data${type2}/${imagesetId || 0}/`,
          img,
          {
            headers: {
              Authorization: `Token ${key}`,
            },
          }
        );
      }

      dispatch(setLoadingBarAct(false));
      dispatch(setSubPath('#check'));
    } catch (error: any) {
      const file = error?.response?.data?.error
        ?.replace('The file ', '')
        ?.replace(' already exsits', '');
      return Promise.reject(file);
    }
    return imagesetId;
  }
);

export const sendNewImg = createAsyncThunk(
  'files/postNewFiles',
  async (
    { imagesetId, key }: { imagesetId: number, key: string },
    { getState, dispatch }
  ) => {
    const { files }: any = getState();

    let imgsType = files.availableImageSets?.find(
      (item) => item.id === imagesetId
    )?.typeDownloadprofile;

    imgsType = imgsType || files.prifileType;

    console.log(imgsType);

    const type1 = imgsType === 'ocr' ? 'ocr' : 'downloadprofile';
    const type2 = imgsType === 'ocr' ? '-ocr' : '';

    try {
      for (const key of files.uploadedFilesNow.keys()) {
        console.log('after upload: ', key);
      }
      const res = await axios.post(
        `${type1}/send-img-data${type2}/${imagesetId || 0}/`,
        files.uploadedFilesNow,
        {
          headers: {
            Authorization: `Token ${key}`,
          },
        }
      );
      dispatch(setSubPath('#check'));
    } catch (error: any) {
      const file = error?.response?.data?.error
        ?.replace('The file ', '')
        ?.replace(' already exsits', '');
      return Promise.reject(file);
    }
    return imagesetId;
  }
);

export const getProfiles = createAsyncThunk(
  'files/getProfiles',
  async ({ key }: { key: string | undefined }, { getState }) => {
    const { files }: any = getState();
    const { data } = await axios.get(
      `/downloadprofile/profiles-list/${files.selectedCompany}/`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );

    return toCamelCase(data);
  }
);

export const getListField = createAsyncThunk(
  'files/getListField',
  async ({ key }: { key: string | undefined }, { getState }) => {
    const { files }: any = getState();

    const { data } = await axios.get(
      `/downloadprofile/get-list-field/${files.selectedCompany}`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );

    return toCamelCase(data);
  }
);

export const getListMine = createAsyncThunk(
  'files/getListMine',
  async (
    { key, field }: { key: string | undefined, field: string },
    { getState }
  ) => {
    const { files }: any = getState();

    const { data } = await axios.get(
      `/downloadprofile/get-list-mine/field=${field}/${files.selectedCompany}`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );

    return toCamelCase(data);
  }
);

export const getListWell = createAsyncThunk(
  'files/getListWell',
  async (
    {
      key,
      field,
      mine,
    }: { key: string | undefined, field: string, mine: string },
    { getState }
  ) => {
    const { files }: any = getState();

    const { data } = await axios.get(
      `/downloadprofile/get-list-well/field=${field}/mine=${mine}/${files.selectedCompany}`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );

    return toCamelCase(data);
  }
);

// export const getImgsInfo = createAsyncThunk(
//   'files/getInfoImages',
//   async ({
//     imageSet,
//     key,
//     route,
//   }: {
//     imageSet?: number,
//     key?: string,
//     route?: string,
//   }) => {
//     const { data } = await axios.get(
//       route || `ocr/get-imgs-list-ocr/${imageSet}/`,
//       {
//         headers: {
//           Authorization: `Token ${key}`,
//         },
//       }
//     );

//     return data;
//   }
// );

export const getImgsInfo = createAsyncThunk(
  'files/getInfoImages',
  async (
    {
      imageSet,
      key,
      route,
    }: {
      imageSet?: number,
      key?: string,
      route?: string,
    },
    { getState }
  ) => {
    const { files }: any = getState();

    let imgsType = files.availableImageSets?.find(
      (item) => item.id === imageSet
    )?.typeDownloadprofile;

    imgsType = imgsType || files.prifileType;

    const type1 = imgsType === 'ocr' ? 'ocr' : 'downloadprofile';
    const type2 = imgsType === 'ocr' ? '-ocr' : '';

    const { data } = await axios.get(
      route || `${type1}/get-imgs-list${type2}/${imageSet}/`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    console.log(data);

    return data;
  }
);

export const getFilesInfo = createAsyncThunk(
  'files/getInfoFiles',
  async ({
    imageSet,
    key,
    route,
  }: {
    imageSet: number,
    key?: string,
    route?: string,
  }) => {
    const { data } = await axios.get(
      route || `downloadprofile/get-datafiles-list/${imageSet}/`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    return data;
  }
);

export const getImgTable = createAsyncThunk(
  'files/getImgTable',
  async ({ idImg, key }: { idImg: number, key?: string }) => {
    const { data } = await axios.get(`ocr/img-table/${idImg}/`, {
      headers: {
        Authorization: `Token ${key}`,
      },
    });
    return data;
  }
);

export const getDownloadFileCSV = createAsyncThunk(
  'files/getDownloadFileCSV',
  async ({ idImg, key }: { idImg: number, key?: string }) => {
    const { data } = await axios.get(`ocr/download-table/${idImg}/csv/`, {
      headers: {
        Authorization: `Token ${key}`,
      },
    });
    return data;
  }
);

export const getDownloadFileXLSX = createAsyncThunk(
  'files/getDownloadFileXLSX',
  async ({ idImg, key }: { idImg: number, key?: string }) => {
    const { data } = await axios.get(`ocr/download-table/${idImg}/xls/`, {
      headers: {
        Authorization: `Token ${key}`,
      },
    });
    return data;
  }
);

export const getDownloadFileTXT = createAsyncThunk(
  'files/getDownloadFileTXT',
  async ({ idImg, key }: { idImg: number, key?: string }) => {
    const { data } = await axios.get(`ocr/download-table/${idImg}/txt/`, {
      headers: {
        Authorization: `Token ${key}`,
      },
    });
    return data;
  }
);

export const removeImages = createAsyncThunk(
  'files/removeImages',
  async (
    {
      items,
      key,
      activeImgId,
      imagesetId,
    }: {
      items: ChoiseItem[],
      key: string,
      activeImgId: number,
      imagesetId: number,
    },
    { getState }
  ) => {
    try {
      const { files }: any = getState();

      const imgsType = files.availableImageSets?.find(
        (item) => item.id === imagesetId
      )?.typeDownloadprofile;

      const type1 = imgsType === 'ocr' ? 'ocr' : 'downloadprofile';
      const type2 = imgsType === 'ocr' ? '-ocr' : '';

      const res = await axios.delete(
        `${type1}/del-img${type2}/${activeImgId}/`,
        {
          headers: {
            Authorization: `Token ${key}`,
          },
        }
      );
      console.log(res);
    } catch (error) {
      console.log(error);
    }
    return items;
  }
);

export const updateImgData = createAsyncThunk(
  'files/update-img',
  async (
    { key, imagesetId }: { key: string, imagesetId: number },
    { getState, dispatch }
  ) => {
    const state: any = getState();
    await axios.post(
      `downloadprofile/update-imgs-info/${imagesetId}/`,
      { ...state.files.newImgInfo },
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    // dispatch(setActivePathAct('/home'));
    // dispatch(setSubPath(''));
  }
);

export const updateImgTable = createAsyncThunk(
  'files/update-img-table',
  async (
    { key, imgId, table }: { key: string, imgId: number, table: IImgTable[][] },
    { getState }
  ) => {
    const state: any = getState();
    await axios.post(
      `ocr/update-table/${imgId}/`,
      { table },
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    // dispatch(setActivePathAct('/home'));
    // dispatch(setSubPath(''));
  }
);

// export const getImageSets = createAsyncThunk(
//   'files/get-image-sets',
//   async ({ key }: { key: string }, { getState }) => {
//     const state: any = getState();
//     const { data } = await axios.get(
//       `downloadprofile/imageset/list/${state.files.selectedCompany}/`,
//       {
//         headers: {
//           Authorization: `Token ${key}`,
//         },
//       }
//     );
//     return data;
//   }
// );

export const getImageSets = createAsyncThunk(
  'files/get-image-sets',
  async (
    {
      key,
      field,
      mine,
      well,
    }: { key: string, field: string, mine: string, well: string },
    { getState }
  ) => {
    const state: any = getState();
    const { data } = await axios.get(
      `downloadprofile/imagesetlist/?organization_id=${
        state.files.selectedCompany
      }${field !== '' ? `&fieldd=${field}` : ''}${
        mine !== '' ? `&mine=${mine}` : ''
      }${well !== '' ? `&well=${well}` : ''}`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    return data;
  }
);

export const getImageSetChoises = createAsyncThunk(
  'files/choises',
  async ({ key, imageSetId }: { key: string, imageSetId: number }) => {
    const { data } = await axios.get(
      `/downloadprofile/actions-list/${imageSetId}/`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    return data;
  }
);

export const getAnaliz = createAsyncThunk(
  'files/get-analiz',
  async ({
    key,
    imageSetId,
    typeCrop,
  }: {
    key: string,
    imageSetId: number,
    typeCrop: string,
  }) => {
    const { data } = await axios.get(
      `/io/analize/${imageSetId}/${typeCrop === '' ? 'crop' : typeCrop}/`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    return data;
  }
);

export const getAnalizMenu = createAsyncThunk(
  'files/get-analiz-menu',
  async ({ key, imageSetId }: { key: string, imageSetId: number }) => {
    const { data } = await axios.get(
      `io/get-list-analysis-menu/${imageSetId}/`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    return data;
  }
);

export const getTree = createAsyncThunk(
  'files/get-tree',
  async ({ key, imageSetId }: { key: string, imageSetId: string }) => {
    const { data } = await axios.get(
      `downloadprofile/imageset-tree-cla/${imageSetId}/ordering:box-number/`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    return { photos: data?.photos, name: data?.name };
  }
);

export const getAllImgSets = createAsyncThunk(
  'files/get-all-imgsets',
  async ({ key }: { key: string }) => {
    const { data } = await axios.get(`downloadprofile/all-imagesets-list/`, {
      headers: {
        Authorization: `Token ${key}`,
      },
    });
    return data;
  }
);

export const downloadKernXlsx = createAsyncThunk(
  'files/download-kern-xlsx',
  async ({
    key,
    imagesetId,
    typeCrop,
  }: {
    key: string,
    imagesetId: number,
    typeCrop: string,
  }) => {
    const { data } = await axios.get(
      `io/download-xlsx/${imagesetId}/${typeCrop !== '' ? typeCrop : 'crop'}/`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    return data;
  }
);

export const updateTable = createAsyncThunk(
  'files/update-table',
  async ({
    key,
    analizId,
    drillingId,
    value,
    choiseType,
  }: {
    choiseType: string,
    key: string,
    analizId: number,
    drillingId?: number,
    value: number,
  }) => {
    console.log(drillingId);
    const { data } = await axios.post(
      `io/analizes-to-update/${analizId}/${drillingId}/`,
      {
        value_to: value,
      },
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    return data;
  }
);

export const getLegend = createAsyncThunk(
  'files/get-legend',
  async ({
    key,
    imageSetId,
    type,
  }: {
    key: string,
    imageSetId: number,
    type: string,
  }) => {
    const { data } = await axios.get(
      `io/get-list-lythoypes/${imageSetId}/${type === '' ? 'crop' : type}/`,
      {
        headers: {
          Authorization: `Token ${key}`,
        },
      }
    );
    return {
      data,
      type,
    };
  }
);

export const callRoute = createAsyncThunk(
  'files/call-route',
  async ({
    key,
    routes,
    imagSet,
    method,
  }: {
    key: string,
    imagSet: number,
    method: string,
    routes: string[],
  }) => {
    const out = await Promise.all(
      routes.map(async (route) => {
        switch (method) {
          case 'GET': {
            const { data } = await axios.get(`${route}`, {
              headers: {
                Authorization: `Token ${key}`,
              },
            });
            return data;
            break;
          }
          case 'POST': {
            const { data } = await axios.post(
              `${route}`,
              {
                img_set_id: imagSet,
              },
              {
                headers: {
                  Authorization: `Token ${key}`,
                },
              }
            );
            return data;
            break;
          }
          case 'DELETE': {
            const { data } = await axios.delete(`${route}`, {
              headers: {
                Authorization: `Token ${key}`,
              },
            });
            return data;
            break;
          }
          default:
            break;
        }
      })
    );
    return out;
  }
);
