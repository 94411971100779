import { PayloadAction } from '@reduxjs/toolkit';
import { CounterState } from '.';

export const incrementAction = (state: CounterState) => {
  state.value += 1;
};

export const decrementAction = (state: CounterState) => {
  state.value -= 1;
};

export const valueIncAcntion = (
  state: CounterState,
  { payload }: PayloadAction<number>
) => {
  state.value += payload;
};
