import styled, { css } from 'styled-components';

interface Props {
  opened?: boolean;
  isAcive?: boolean;
}

export const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  padding: 40px 80px 53px 0px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
`;

export const MainBlock = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;

export const SaveButton = styled.button`
  margin-top: 20px;
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.1s;
  background-color: ${({ theme }) => theme.colors.mainRed};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_20_23}
  cursor: pointer;
  &:disabled {
    opacity: 0.8;
    cursor: no-drop;
  }
  &:active {
    &:not(&:disabled) {
      transform: scale(0.98);
    }
  }
  svg {
    margin-left: 12px;
  }
`;

export const HeaderTop = styled.div`
  display: flex;
  position: relative;
`;

// eslint-disable-next-line prettier/prettier
export const HeaderTopDropdownWrapper = styled.div<Props>`
  position: absolute;
  right: 0;
  bottom: 0;
  transition: all 0.2s;
  transform: ${({ opened }) =>
    opened ? 'translateY(103%)' : 'translateY(90%)'};
  visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
  opacity: ${({ opened }) => Number(opened)};
  
  width: 100%;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  display: flex;
  flex-direction: column;
`;

// eslint-disable-next-line prettier/prettier
export const HeaderTopDropdownItem = styled.div<Props>`
  width: 100%;
  padding: 0 4px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  padding: 2px;
  ${({ theme }) => theme.typography.text_16_19}
  transition: color .1s;
  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    bottom: -1px;
    width: 100%;
    left: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:last-child::after {
    display: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightBlue};
  }

  ${({ theme, isAcive }) =>
    isAcive &&
    css`
      color: ${theme.colors.lightBlue};
    `}
`;

export const LeftBlock = styled.div`
  border-left: 10px solid ${({ theme }) => theme.colors.lightBlue};
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.white};
  padding: 4px 9px 4px 7px;
  ${({ theme }) => theme.typography.text_16_19}
`;

export const RightBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 8px 16px 5px 7px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.typography.text_12_14}
`;

// eslint-disable-next-line prettier/prettier
export const OpenButton = styled.button<Props>`
  padding: 0;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    transition: all .2s;
    transform: rotate(${({ opened }) => opened && '180deg'});
  }
`;

export const FilesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.7fr 0.3fr;
  grid-row-gap: 20px;
`;
