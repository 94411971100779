/* eslint-disable prettier/prettier */
import styled, { css } from "styled-components";

interface Props {
  isactive?: boolean;
  selected?: boolean;
  color?: string;
  bg?: string;
  rowMin?: string;
  rowMax?: string;
  columnMin?: string;
  columnMax?: string;
  rowLenght?: string;
  columnLenght?: string;
}

export const InfoFileWrapper = styled.div`
  width: 100%;
  height: calc(100% - 27px);
  overflow-y: auto;
  scrollbarbar-width: 10px;
  scrollbar-color: ${({ theme }) => theme.colors.mainRed} ${({ theme }) => theme.colors.lightGray};
  border-right: 10px solid ${({ theme }) => theme.colors.lightGray};
`
export const TableTitle = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(${p => p.columnLenght || '1'}, minmax(0, 1fr));
  grid-template-rows: repeat(${p => p.rowLenght || '1'}, 35px);
  align-items: center;

  width: 100%;
  gap: 1px;
  background-color: ${({theme}) => theme.colors.white};

  ${({ theme, selected }) => selected && css`
    background-color: ${theme.colors.darkGray};
  `}
`

export const TableTitleText = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3px;

  grid-column: ${p => p.columnMin || 'auto'} / ${p => p.columnMax || 'auto'};
  grid-row: ${p => p.rowMin || '1'} / ${p => p.rowMax || '2'};
  font-weight: 700;
  ${({ theme }) => theme.typography.text_8_10};
  text-align: center;
  color: ${({theme}) => theme.colors.white};
  background-color: ${({theme}) => theme.colors.lightBlue};
  height: 100%;
`
export const TableContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 15px;
  row-gap: 1px;
  width: 100%;
  background-color: #000;
`

export const TebleContentItemWrapper = styled.div<Props>`
  display: grid;
  // grid-template-columns: repeat(3, 1fr) repeat(2, 2fr);
  grid-template-columns: repeat(${p => p.columnLenght}, 1fr);
  grid-template-rows: 1fr;
`

export const TebleContentItem = styled.input<Props>`
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  border: none;
  background-color: ${props => props.bg || 'white'};
  color: ${props => props.color || '#000'};
  cursor: pointer;
  pointer-events: auto;
  &:focus {
    position: relative;
    outline: 2px solid ${({theme}) => theme.colors.lightBlue};
    z-index: 1;
  }

  ${({ theme, selected }) => selected && css`
    background-color: ${theme.colors.disabilityGray};
    pointer-events: none;
  `}
`

export const TableBottomPanel = styled.div<Props>`
  width: 100%;
  height: 15px;
  background-color: ${({theme}) => theme.colors.lightBlue};

  ${({ theme, selected }) => selected && css`
    background-color: ${theme.colors.lightGray};
  `}
`