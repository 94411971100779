/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable unused-imports/no-unused-vars */
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { getCompanies } from 'src/store/reducers/auth';
import {
  addFormDataAct,
  checkValidAct,
  setSelectedCompanyAct,
} from 'src/store/reducers/files';
import {
  getProfiles,
  getListField,
  getListMine,
  getListWell,
} from 'src/store/reducers/files/async';
import { ICompany, IProfile } from 'src/store/types';
import { Icon } from '../UI/Icon';
import * as Styled from './styles';

interface UploadFormsProps {
  activeProfile: IProfile;
}

// interface Props {
//   onClick: (arg: any) => void;
// }

const UploadForms: React.FC<UploadFormsProps> = ({ activeProfile }) => {
  const dispatch = useAppDispatch();
  const [currOrganization, setCurrOrganization] = useState<string>('');
  const [currMine, setCurrMine] = useState<string>('');
  const [currDeposit, setCurrDeposit] = useState<string>('');
  const [currBorehole, setCurrBorehole] = useState<string>('');
  const [currComment, setCurrComment] = useState<string>('');
  const [storage, setStorage] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [typeField, setTypeField] = useState<string>('');
  const [miningIsOpen, setMiningIsOpen] = useState<Map<string, boolean>>(
    new Map()
  );
  // const [inputs, setInputs] = useState<string[]>([]);
  const { selectLanguage, listFields, listMine, listWell } = useAppSelector(
    (state) => state.files
  );
  const { organization, well, mine, fieldd, description } = useAppSelector(
    (state) => state.files.formData
  );
  const { companiesList, authKey } = useAppSelector((state) => state.auth);
  const { selectedCompany } = useAppSelector((state) => state.files);

  useEffect(() => {
    const arr: string[] = [];
    if (activeProfile?.isfieldd) {
      arr.push(selectLanguage === 'Ru' ? 'Месторождение' : 'Deposit');
    }
    if (activeProfile?.ismine) {
      arr.push(selectLanguage === 'Ru' ? 'Рудник' : 'Mine');
    }
    if (activeProfile?.iswell) {
      arr.push(selectLanguage === 'Ru' ? 'Скважина' : 'Well');
    }

    setStorage(arr);
    miningIsOpen.clear();
    arr.forEach((item) => {
      setMiningIsOpen(miningIsOpen.set(item, false));
    });
  }, [activeProfile, selectLanguage]);

  useEffect(() => {
    dispatch(getCompanies(authKey));
  }, [authKey]);

  useEffect(() => {
    setCurrOrganization(
      companiesList?.find((item) => item.isdefault === true)?.title || ''
    );
    dispatch(
      setSelectedCompanyAct(
        companiesList?.find((item) => item.isdefault === true)?.id || 0
      )
    );
  }, [companiesList]);

  useEffect(() => {
    if (selectedCompany !== 0) {
      dispatch(getListField({ key: authKey }));
      dispatch(
        getListMine({
          key: authKey,
          field: currDeposit !== '' ? currDeposit : 'false',
        })
      );
      dispatch(
        getListWell({
          key: authKey,
          field: currDeposit !== '' ? currDeposit : 'false',
          mine: currMine !== '' ? currMine : 'false',
        })
      );
      setCurrOrganization(
        companiesList?.find((item) => item.id === selectedCompany)?.title || ''
      );
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (currDeposit !== '') {
      dispatch(
        getListMine({
          key: authKey,
          field: currDeposit !== '' ? currDeposit : 'false',
        })
      );
    }
    if (currMine !== '') {
      dispatch(
        getListWell({
          key: authKey,
          field: currDeposit !== '' ? currDeposit : 'false',
          mine: currMine !== '' ? currMine : 'false',
        })
      );
    }
  }, [currDeposit, currMine]);

  useEffect(() => {
    dispatch(
      addFormDataAct({
        organization: currOrganization,
        well: currBorehole,
        mine: currMine,
        fieldd: currDeposit,
        description: currComment,
      })
    );
    dispatch(checkValidAct());
  }, [currOrganization, currBorehole, currMine, currDeposit, currComment]);

  const selectInputType = (name: string) => {
    if (name === 'Месторождение' || name === 'Deposit') {
      return (
        <Styled.FormInput
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setCurrDeposit(e.target.value)
          }
          value={fieldd || currDeposit}
        />
      );
    }
    if (name === 'Рудник' || name === 'Mine') {
      return (
        <Styled.FormInput
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setCurrMine(e.target.value)
          }
          value={mine || currMine}
        />
      );
    }
    return (
      <Styled.FormInput
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setCurrBorehole(e.target.value)
        }
        value={well || currBorehole}
      />
    );
  };

  const hideAllLists = () => {
    for (const value of miningIsOpen.keys()) {
      setMiningIsOpen(new Map(miningIsOpen.set(value, false)));
    }
  };

  const hideList = (input) => {
    for (const value of miningIsOpen.keys()) {
      if (input !== value) {
        setMiningIsOpen(new Map(miningIsOpen.set(value, false)));
      }
    }
  };

  const dropdownBlock = () => {
    return (
      <Styled.Box>
        <Styled.FormWrapper>
          <Styled.FormText>
            {selectLanguage === 'Ru' ? 'Организация' : 'Organization'}
          </Styled.FormText>
          <Styled.OpenButton
            opened={isOpen}
            onClick={() => {
              setIsOpen(!isOpen);
              hideAllLists();
            }}
          >
            <Icon name="down" color="#FFFFFF" width={14} height={14} />
          </Styled.OpenButton>
          <Styled.HeaderTopDropdownWrapper opened={isOpen}>
            {companiesList?.map((item) => (
              <Styled.HeaderTopDropdownItem
                key={item.id}
                // isAcive={true || undefined}
                onClick={() => {
                  setCurrOrganization(item.title);
                  setIsOpen(false);
                  dispatch(setSelectedCompanyAct(item.id));
                  dispatch(getProfiles({ key: authKey }));
                }}
              >
                {item.title}
              </Styled.HeaderTopDropdownItem>
            ))}
          </Styled.HeaderTopDropdownWrapper>
        </Styled.FormWrapper>
        <Styled.FormInput value={organization || currOrganization} readOnly />
      </Styled.Box>
    );
  };

  const currChiose = (list: string[], item: string) => {
    if (list.toString() === listFields?.toString()) {
      setCurrDeposit(item);
    }
    if (list.toString() === listMine?.toString()) {
      setCurrMine(item);
    }
    if (list.toString() === listWell?.toString()) {
      setCurrBorehole(item);
    }
  };

  const renderListFields = (list: string | string[], input: string) => {
    let items;

    if (list === 'Месторождение' || list === 'Deposit') {
      list = listFields?.filter((item) => item !== '') || [];
    }
    if (list === 'Рудник' || list === 'Mine') {
      list = listMine?.filter((item) => item !== '') || [];
    }
    if (list === 'Скважина' || list === 'Well') {
      list = listWell?.filter((item) => item !== '') || [];
    }

    if (typeof list !== 'string') {
      items = list?.map((item, i) => {
        return (
          <Styled.HeaderTopDropdownItem
            key={i}
            onClick={() => {
              if (typeof list !== 'string') {
                currChiose(list, item);
              }
              setMiningIsOpen(
                new Map(miningIsOpen.set(input, !miningIsOpen.get(input)))
              );
            }}
          >
            {item}
          </Styled.HeaderTopDropdownItem>
        );
      });
    }

    return items;
  };

  const mining = () => {
    const items = storage.map((input) => (
      <Styled.Box key={input}>
        <Styled.FormWrapper>
          <Styled.FormText>{input}</Styled.FormText>
          <Styled.OpenButton
            opened={miningIsOpen.get(input)}
            onClick={() => {
              hideList(input);
              setMiningIsOpen(
                new Map(miningIsOpen.set(input, !miningIsOpen.get(input)))
              );
              setIsOpen(false);
              setTypeField(input);
            }}
          >
            <Icon name="down" color="#FFFFFF" width={14} height={14} />
          </Styled.OpenButton>
          <Styled.HeaderTopDropdownWrapper opened={miningIsOpen.get(input)}>
            {typeField.length > 0 ? renderListFields(typeField, input) : null}
          </Styled.HeaderTopDropdownWrapper>
        </Styled.FormWrapper>
        {selectInputType(input)}
      </Styled.Box>
    ));

    return items;
  };

  return (
    <Styled.Root>
      <Styled.TitleWrapper>
        <Styled.Title>
          {selectLanguage === 'Ru' ? 'Заполните форму' : 'Fill the form'}
        </Styled.Title>
        <Styled.PenIcon name="draw" />
      </Styled.TitleWrapper>

      {dropdownBlock()}
      {mining()}

      <Styled.Box>
        <Styled.FormWrapper>
          <Styled.FormText>
            {selectLanguage === 'Ru' ? 'Номер кабинета' : 'Cabinet number'}
          </Styled.FormText>
        </Styled.FormWrapper>
        <Styled.FormInput
          disabled
          value={activeProfile?.cabinetNums || ''}
          readOnly
        />
      </Styled.Box>

      <Styled.TextAreaWrapper>
        <Styled.FormTextArea
          placeholder={selectLanguage === 'Ru' ? 'Комментарий' : 'Comment'}
          value={currComment || description}
          onChange={(e) => setCurrComment(e.target.value)}
        />
      </Styled.TextAreaWrapper>
    </Styled.Root>
  );
};

export default UploadForms;
