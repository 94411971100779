import styled, { css, keyframes } from 'styled-components';
import { Icon } from '../UI/Icon';

interface Props {
  isInvalid?: boolean;
  opened?: boolean;
}

export const Root = styled.div`
  width: 50vw;
  height: 100%;
  background: ${({ theme }) => theme.colors.mainRed};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 32px;
`;

export const Header = styled.header`
  width: 100%;
  padding: 56px 64px ${({ theme }) => theme.baseStyles.paddings.mainSide} 80px;
  display: flex;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.white};
  margin-right: auto;
  text-decoration: none;
  ${({ theme }) => theme.typography.text_20_23}
  z-index: 5;
`;

export const LangBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 0;
  height: 100%;
  position: relative;
  color: ${({ theme }) => theme.colors.white} !important;
  ${({ theme }) => theme.typography.text_20_23}
  svg {
    margin: 0 5px;
  }
`;

// eslint-disable-next-line prettier/prettier
export const CustomIcon = styled(Icon)<Props>`
  transition: all 0.2s;
  cursor: pointer;
  transform: rotate(${({ opened }) => (opened ? '180deg' : '0')});
`;

// eslint-disable-next-line prettier/prettier
export const HiddenLang = styled.span<Props>`
  position: absolute;
  top: 0px;
  right: 18px;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.hiddenColor};
  ${({ opened }) =>
    opened
      ? css`
          transform: translateY(102%);
          opacity: 1;
          visibility: visible;
        `
      : css`
          transform: translateY(0%);
        `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 65px 0 31px ${({ theme }) => theme.baseStyles.paddings.mainSide};
`;

export const LoginBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 350px;
  color: ${({ theme }) => theme.colors.white};
`;

export const LoginTitle = styled.p`
  margin: 0;
  font-weight: 700;
  ${({ theme }) => theme.typography.text_20_23}
  margin-bottom: 48px;
`;

export const LoginForm = styled.form`
  display: flex;
  width: 350px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const LoginGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  &:nth-child(2) {
    margin-top: 17px;
  }
`;

export const LoginLabel = styled.label`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 7px;
  ${({ theme }) => theme.typography.text_14_16}
`;

export const LoginInputGroup = styled.div`
  position: relative;
  width: 100%;
`;

export const HideButton = styled.a`
  position: absolute;
  height: 30px;
  width: 30px;
  right: 0;
  top: 0;
  border: none;
  padding: 3px;
  background-color: ${({ theme }) => theme.colors.bgGray};
`;

const invalidAnim = keyframes`
  0% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(10px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0px);
  }
`;

export const PlayGroup = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 80px;
`;

export const PlayTitle = styled.p`
  margin: 0;
  max-width: 213px;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_38_45};
  z-index: 10;
`;

const invalidInput = (theme) => css`
  color: ${theme.colors.mainRed};
  animation: ${invalidAnim} 0.2s;
`;

// eslint-disable-next-line prettier/prettier
export const LoginInput = styled.input<Props>`
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 0;
  background-color: ${({ theme }) => theme.colors.white};
  padding-left: 4px;
  transition: all .2s;
  ${({ theme, isInvalid }) => isInvalid && invalidInput(theme)}

  &:active,
  &:focus {
    outline: none;
  }
  &:first-child {
    width: calc(100% - 30px);
  }
`;

export const LoginButton = styled.button`
  border: none;
  width: 100%;
  height: 30px;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.colors.bgButton};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_16_19}
  cursor: pointer;
  &:active,
  &:focus {
    outline: none;
  }
`;

export const LoginHint = styled.a`
  margin-left: auto;
  text-decoration: none;
  margin-top: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.hintColor};
  ${({ theme }) => theme.typography.text_10_12}
`;

export const PlayRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding-left: 25px;
  border: 8px solid ${({ theme }) => theme.colors.white};
  min-width: 174px;
  min-height: 176px;
  border-radius: 50%;
  margin-right: 32px;
`;
