/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';
import { Icon } from '../UI/Icon';

interface Props {
  isactive?: number;
  full?: boolean;
  color?: string;
}

interface MenuProps {
  xpos: number;
  ypos: number;
}

export const Root = styled.div`
  width: 100%;
  height: 70vh;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const MenuBox = styled.div<MenuProps>`
    width: 140px;
    height: auto;
    z-index: 1000000;
    position: absolute;
    // bottom: 400px;
    ${({ xpos }) => css`left: ${xpos}px;`}
    ${({ ypos }) => css`top: ${ypos}px;`}
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray};
`

export const MenuItems = styled.ul`
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 2px 0;
`

export const MenuItemR = styled.li<{color?: string}>`
    width: 100%;
    color: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.typography.text_12_14};
    padding: 0 2px;
    cursor: pointer;
    position: relative;

    ${({ color }) => color && css`
        &::before {
            content: '';
            position: absolute;
            right: 10px;
            width: 10px;
            height: 10px;
            background: ${color};
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    `}

    &:hover {
        background-color: ${({ theme }) => theme.colors.gray};
        .sub-menu {
            display: flex;
        }
    }
`

export const MenuItemMenu = styled.div`
    width: 160px;
    min-height: 16px;
    height: auto;
    z-index: 1000000;
    position: absolute;
    left: 100%;
    display: none;
    top: 0;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray};
`

// ------ ActionsPanel ------

export const ActionsPanel = styled.div`
  width: 100%;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
  // justify-content: space-between;
`;

export const LegendWrapper = styled.div`
  position: relative;
  width: 36%;
  height: 100%;
`;

export const Legend = styled.div<Props>`
  width: 100%%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  border-right: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  transform: skew(45deg);
  margin-left: 2px;
  padding-left: 5px;
  cursor: pointer;
  margin-left: 15px;
  position: relative;

  display: flex;
  justify-content:  space-between;

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: -15px;
    height: 30px;
    width: 30px;
    transform: skew(-45deg);
    background-color: ${({ theme }) => theme.colors.lightBlue};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.1s;

    &:after {
        background-color: ${({ theme }) => theme.colors.darkBlue};
        transition: 0.1s;
    }
  }

  ${({isactive}) => isactive && css`
    background-color: ${({ theme }) => theme.colors.darkBlue};
    &:after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
    }
  `}
`;

export const LegendText = styled.p`
  ${({ theme }) => theme.typography.text_16_19}
  font-weight: 700;
  margin: auto 15px;
  transform: skew(-45deg);
`;

export const LegendIcon = styled(Icon)<Props>`
  transform: skew(-45deg);
  transition: 0.3s;
  margin: auto 12px;
  color: ${({ theme }) => theme.colors.white};
  ${({isactive}) => isactive && css`
    transform: skew(-45deg) rotate(180deg);
    transition: 0.3s;
  `}
`;

export const LegendInfo = styled.div<Props>`
  display: none;
  z-index: 0;
  position: absolute;
  margin-top: 2px;
  padding: 5px 0;
  width: 105%;
  overflow-y: auto;
  height: 150px;
  background-color: ${({ theme }) => theme.colors.lightBlue};

  border: 1px solid ${({ theme }) => theme.colors.white};
  border-left: none;

  ${({isactive}) => isactive && css`
    display: block;
    z-index: 5;
  `}
`;

export const LegendItem = styled.div`
  height: 20px;
  margin: 0 10px;
  display: flex;
`;

export const LegendColor = styled.div<Props>`
  width: 20px;
  height: 10px;
  margin: auto 0;
  margin-right: 13px;
  border-radius: 5px;
  ${({color}) => css`
    background-color: ${color};
  `}
`;

export const LegendItemText = styled.p`
  ${({ theme }) => theme.typography.text_10_12}
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  margin: auto 0;
`;

export const ParametersWrapper = styled.div`
  position: relative;
	width: 33%;
	height: 100%;
`;

export const Parameters = styled.div<Props>`
	width: 100%;
	height: 100%;
	transform: skew(45deg);
	padding-left: 10px;
	// margin-left: 13px;

	background-color: ${({ theme }) => theme.colors.lightBlue};
	border-right: 1px solid ${({ theme }) => theme.colors.white};
	// border-left: 1px solid ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.white};

	cursor: pointer;
	position: relative;

	display: flex;
	justify-content:  space-between;

	&:hover {
		background-color: ${({ theme }) => theme.colors.darkBlue};
		transition: 0.1s;

		&:after {
				background-color: ${({ theme }) => theme.colors.darkBlue};
				transition: 0.1s;
		}
	}

	${({ isactive }) =>
		isactive &&
		css`
			background-color: ${({ theme }) => theme.colors.darkBlue};
			&:after {
				background-color: ${({ theme }) => theme.colors.darkBlue};
			}
	`}
`;

export const SelectedParameter = styled.div`
	${({ theme }) => theme.typography.text_16_19}
	font-weight: 700;
	margin: auto 15px;
	transform: skew(-45deg);
`;

export const ParametersList = styled.div<Props>`
	display: none;
	z-index: 0;
	position: absolute;
	margin-top: 2px;
	margin-left: 15px;

	width: 100%;
	background-color: ${({ theme }) => theme.colors.lightBlue};
	border: 1px solid ${({ theme }) => theme.colors.white};

	${({ isactive }) =>
		isactive &&
		css`
			display: block;
			z-index: 5;
	`}
`;

export const ParameterOption = styled.div<Props>`
	width: 100%;
	height: 35px;
	display: flex;
	padding: 0 10px;

	cursor: pointer;
	transition: 0.1s;

	&:hover {
		background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.1s;
	}

	${({ isactive }) =>
		isactive &&
		css`
			background-color: ${({ theme }) => theme.colors.darkBlue};
	`}
`;

export const ParameterOptionText = styled.div`
	color: ${({ theme }) => theme.colors.white};
	${({ theme }) => theme.typography.text_14_16}
	font-weight: 700;

	margin: auto 0;
`;

export const MenuWrapper = styled.div`
  width: 11%;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;
`;

export const Menu = styled.div`
  width: 7px;
  height: 7px;
  margin: 0 auto;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  transition: 0.1s;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 0;

    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;
  }

  &:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;

    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.1s;
    
    &:after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
      transition: 0.1s;
    }

    &:before {
      background-color: ${({ theme }) => theme.colors.darkBlue};
      transition: 0.1s;
    }
  }
`;

export const MenuList = styled.div<Props>`
  display: none;
  width: 145px;
  background-color: ${({ theme }) => theme.colors.menuGray};
  position: absolute;
  top: 32px;
  right: 0px;
  z-index: 0;

  ${({isactive}) => isactive && css`
    display: block;
    z-index: 10;
  `}
`;

export const MenuItem = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.menuActiveGray};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.menuActiveGray};
    transition: 0.1s;
  }
`;

export const MenuItemText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_10_12}
  margin: auto 10px;
`;

// ------ Table ------

export const TableWrapper = styled.div`
  width: 100%;
  height: 65vh;
  overflow-y: auto;
  scrollbar-width: 2px;
  scrollbar-color: ${({ theme }) => theme.colors.mainRed} ${({ theme }) => theme.colors.lightGray};
`;

export const Table = styled.div`
  width: 710px;
  margin: 10px auto;
  border: 2px solid ${({ theme }) => theme.colors.lightBlue};
  border-top: none;
`;

export const TableHeader = styled.div`
  width: 100%;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
`;

export const TableTextWrapper = styled.div`
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const HeaderText = styled.p`
  ${({ theme }) => theme.typography.text_14_16}
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
`;

export const RowWrapper = styled.div<Props>`
  width: 100%;
  height: 22px;
  display: flex;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 97%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.bgGray};
    top: 0px;
    right: 11px;
  }

  &:last-child:after {
    height: 0px;
  }

  ${({full}) => full && css`
    background-color: ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.white};
  `}
`;

export const RowItemBox = styled.div<Props>`
  width: 10%;
  height: 100%;
  display: flex;
  border-right: 1px solid ${({ theme }) => theme.colors.bgGray};
  ${({ color }) => css`
    background-color: ${color};
  `}
`;

export const RowItemColorBoxWrapper = styled.div`
  position: relative;
  width: 10%;
  height: 100%;

  &:hover {
    & p {
      opacity: 1;
    }
  }
`;

export const RowItemColorBox = styled.div<Props>`
  width: 100%;
  height: 100%;
  display: flex;
  border-right: 1px solid ${({ theme }) => theme.colors.bgGray};
  position: relative;


  ${({ color }) => css`
    background-color: ${color};

    &:after {
      z-index: 3;
      content: '';
      position: absolute;
      width: 100%;
      height: 5px;
      background-color: ${color};
      top: -2px;
      right: 0;
    }
  `}
`;

export const RowItemHint = styled.p`
  position: absolute;
  top: 0;
  left: 100%;
  padding: 5px 15px;
  margin: 0;
  opacity: 0;
  z-index: 100;
  pointer-events: none;
  ${({ theme }) => theme.typography.text_12_14}
  color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.55);
`;

export const RowItem = styled.p`
  margin: auto;
  ${({ theme }) => theme.typography.text_12_14}
  font-weight: 700;
`;

export const RowItemInput = styled.input`
  ${({ theme }) => theme.typography.text_12_14}
  font-weight: 700;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.white};
  border: 0;
  outline: none;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  cursor: pointer;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
