/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface Props {
  isactive?: boolean;
}

export const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Navigation = styled.div`
  width: 24%;
  height: 70vh;
  padding: 20px 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const NavigationItem = styled.div<Props>`
  display: flex;
  cursor: pointer;
  position: relative;
  color:  ${({ theme }) => theme.colors.lightBlue};

  &::after {
    content: '';
    position: absolute;
    
    width: 90%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray};
    bottom: 0;
    right: 5%;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;

    &::after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
      transition: 0.1s;
    }
  }

  ${({isactive}) => isactive && css`
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};

    &::after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
    }
  `}
`;

export const NavigationItemText = styled.p<Props>`
  font-weight: 700;
  margin: 7px 0 7px 25px;
`;

export const Box = styled.div`
  width: 74%;
  height: 70vh;
  background-color: ${({ theme }) => theme.colors.white};

  display: flex;
  flex-direction: column;
`;

/* ------------------------------------ */

export const ActionsPanel = styled.div`
  width: 100%;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  display: flex;
`;

export const FirstActionButton = styled.div <Props>`
  width: 31%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  border-right: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  transform: skew(45deg);
  margin-left: 2px;
  padding-left: 5px;
  cursor: pointer;
  margin-left: 15px;
  position: relative;

  display: flex;
  justify-content:  space-between;

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: -15px;
    height: 30px;
    width: 30px;
    transform: skew(-45deg);
    background-color: ${({ theme }) => theme.colors.lightBlue};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.1s;

    &:after {
        background-color: ${({ theme }) => theme.colors.darkBlue};
        transition: 0.1s;
    }
  }

  ${({ isactive }) =>
		isactive &&
		css`
			background-color: ${({ theme }) => theme.colors.darkBlue};
			&:after {
				background-color: ${({ theme }) => theme.colors.darkBlue};
			}
	`}
`;

export const ActionButton = styled.div`
	width: 31%;
	height: 100%;
	transform: skew(45deg);
	padding-left: 10px;

	background-color: ${({ theme }) => theme.colors.lightBlue};
	border-right: 1px solid ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.white};

	cursor: pointer;
	position: relative;

	display: flex;

	&:hover {
		background-color: ${({ theme }) => theme.colors.darkBlue};
		transition: 0.1s;

		&:after {
				background-color: ${({ theme }) => theme.colors.darkBlue};
				transition: 0.1s;
		}
	}
`;

export const ActionButtonText = styled.p`
	${({ theme }) => theme.typography.text_16_19}
	font-weight: 700;
	margin: auto 20px;
	transform: skew(-45deg);
`;

export const MenuWrapper = styled.div`
  width: 11%;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Menu = styled.div`
  width: 7px;
  height: 7px;
  margin: 0 auto;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  transition: 0.1s;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 0;

    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;
  }

  &:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 0;

    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.1s;

    &:after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
      transition: 0.1s;
    }

    &:before {
      background-color: ${({ theme }) => theme.colors.darkBlue};
      transition: 0.1s;
    }
  }
`;

export const MenuList =
  styled.div <
  Props >
  `
  display: none;
  width: 145px;
  background-color: ${({ theme }) => theme.colors.menuGray};
  position: absolute;
  top: 32px;
  right: 0px;
  z-index: 0;

  ${({ isactive }) =>
    isactive &&
    css`
      display: block;
      z-index: 10;
    `}
`;

export const MenuItem = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.menuActiveGray};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.menuActiveGray};
    transition: 0.1s;
  }
`;

export const MenuItemText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_10_12}
  margin: auto 10px;
`;

export const AddPanel = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  margin: 0 25px;
  margin-top: 10px;
`;

export const SelectOrganization = styled.div`
  width: 15%;
  height: 35px;
  margin: auto 0;
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  margin-right: 15px;
`;

export const FieldInput = styled.input`
  width: 15%;
  height: 35px;
  margin: auto 0;
  outline: none;
  border: 1.5px solid ${({ theme }) => theme.colors.darkBlue};
  margin-right: 15px;
  padding: 0 7px;
`;

export const ButtonSend = styled.button`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  margin: auto 0;
  margin-right: 15px;
  transition: 0.3s;
  cursor: pointer;
  height: 35px;
  width: 10%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.3s;
  }
`;

export const ButtonClose = styled.button`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  margin: auto 0;
  margin-right: 15px;
  transition: 0.3s;
  cursor: pointer;
  height: 35px;
  width: 10%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.3s;
  }
`;
