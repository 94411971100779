import React from 'react';
import * as Styled from './styles';

const Target: React.FC = () => {
  return (
    <Styled.TargetRoot>
      <svg
        width="461"
        height="461"
        viewBox="0 0 461 461"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M230.5 139.861C180.494 139.861 139.861 180.546 139.861 230.5C139.861 280.454 180.494 321.14 230.5 321.14C280.454 321.14 321.14 280.454 321.14 230.5C321.14 180.546 280.454 139.861 230.5 139.861ZM230.5 323.234C179.394 323.234 137.766 281.606 137.766 230.5C137.766 179.394 179.394 137.766 230.5 137.766C281.606 137.766 323.234 179.394 323.234 230.5C323.234 281.606 281.606 323.234 230.5 323.234Z"
          fill="#286275"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M235.789 50.0063C331.298 52.7291 408.271 129.702 410.994 225.211L420.733 224.897C417.801 124.256 336.744 43.1991 236.103 40.2668L235.789 50.0063ZM410.942 238.04C407.014 332.502 330.565 408.271 235.789 410.994L236.103 420.733C336.115 417.853 416.754 337.791 420.629 238.04H410.942ZM222.96 410.941C129.231 407.067 53.9335 331.769 50.0587 238.04H40.3716C44.1941 337.163 123.838 416.806 222.96 420.629V410.941ZM50.0063 225.211C52.7292 130.435 128.498 53.9335 222.96 50.0586V40.3716C123.209 44.2464 43.1468 124.885 40.2669 224.897L50.0063 225.211Z"
          fill="#286275"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.5 132.268C284.748 132.268 328.732 176.253 328.732 230.5C328.732 284.748 284.748 328.732 230.5 328.732C176.252 328.732 132.268 284.748 132.268 230.5C132.268 176.253 176.252 132.268 230.5 132.268ZM230.5 126.979C287.68 126.979 334.021 173.32 334.021 230.5C334.021 287.68 287.68 334.021 230.5 334.021C173.32 334.021 126.979 287.68 126.979 230.5C126.979 173.32 173.32 126.979 230.5 126.979Z"
          fill="#286275"
        />
        <path
          d="M240.24 0.313965H220.708V178.556H240.24V0.313965Z"
          fill="#F2F2F2"
        />
        <path d="M233.851 0H227.149V178.556H233.851V0Z" fill="#286275" />
        <path d="M240.24 282.758H220.708V461H240.24V282.758Z" fill="#F2F2F2" />
        <path
          d="M233.851 282.444H227.149V461H233.851V282.444Z"
          fill="#286275"
        />
        <path
          d="M0.30695 220.73L0.30695 240.274L178.543 240.274L178.543 220.73L0.30695 220.73Z"
          fill="#F2F2F2"
        />
        <path
          d="M0 227.139L0 233.86L178.542 233.86L178.542 227.139L0 227.139Z"
          fill="#286275"
        />
        <path
          d="M282.763 220.73L282.763 240.274L460.999 240.274L460.999 220.73L282.763 220.73Z"
          fill="#F2F2F2"
        />
        <path
          d="M282.456 227.139L282.456 233.86L460.999 233.86L460.999 227.139L282.456 227.139Z"
          fill="#286275"
        />
        <rect width="231" height="461" fill="#A0191F" />
        <g clipPath="url(#clip0_25_70)">
          <path
            d="M230.5 139.861C180.494 139.861 139.861 180.546 139.861 230.5C139.861 280.454 180.494 321.14 230.5 321.14C280.454 321.14 321.14 280.454 321.14 230.5C321.14 180.546 280.454 139.861 230.5 139.861ZM230.5 323.234C179.394 323.234 137.766 281.606 137.766 230.5C137.766 179.394 179.394 137.766 230.5 137.766C281.606 137.766 323.234 179.394 323.234 230.5C323.234 281.606 281.606 323.234 230.5 323.234Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M235.789 50.0063C331.298 52.7291 408.271 129.702 410.994 225.211L420.733 224.897C417.801 124.256 336.744 43.1991 236.103 40.2668L235.789 50.0063ZM410.942 238.04C407.014 332.502 330.565 408.271 235.789 410.994L236.103 420.733C336.115 417.853 416.754 337.791 420.629 238.04H410.942ZM222.96 410.941C129.231 407.067 53.9335 331.769 50.0587 238.04H40.3716C44.1941 337.163 123.838 416.806 222.96 420.629V410.941ZM50.0063 225.211C52.7292 130.435 128.498 53.9335 222.96 50.0586V40.3716C123.209 44.2464 43.1468 124.885 40.2669 224.897L50.0063 225.211Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M230.5 132.268C284.748 132.268 328.732 176.253 328.732 230.5C328.732 284.748 284.748 328.732 230.5 328.732C176.252 328.732 132.268 284.748 132.268 230.5C132.268 176.253 176.252 132.268 230.5 132.268ZM230.5 126.979C287.68 126.979 334.021 173.32 334.021 230.5C334.021 287.68 287.68 334.021 230.5 334.021C173.32 334.021 126.979 287.68 126.979 230.5C126.979 173.32 173.32 126.979 230.5 126.979Z"
            fill="white"
          />
          <path
            d="M240.24 0.313965H220.708V178.556H240.24V0.313965Z"
            fill="#A0191F"
          />
          <path d="M233.851 0H227.149V178.556H233.851V0Z" fill="white" />
          <path
            d="M240.24 282.758H220.708V461H240.24V282.758Z"
            fill="#A0191F"
          />
          <path
            d="M233.851 282.444H227.149V461H233.851V282.444Z"
            fill="white"
          />
          <path
            d="M0.30695 220.73L0.30695 240.274L178.543 240.274L178.543 220.73L0.30695 220.73Z"
            fill="#A0191F"
          />
          <path
            d="M0 227.139L0 233.86L178.542 233.86L178.542 227.139L0 227.139Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_25_70">
            <rect width="231" height="461" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Styled.TargetRoot>
  );
};

export default Target;
