import React, { useState } from 'react';
import { HomeSettings } from './HomeSettings';
import { LoadSettings } from './LoadSettings';
import { SortSettings } from './SortSettings';
import * as Styled from './styles';

type NavItem = {
  id: number,
  text: string,
  type: string,
  selected: boolean,
};

const AdminPersonalSettings: React.FC = () => {
  const [navItems, setNavItems] = useState<NavItem[]>([
    {
      id: 1,
      text: 'Личный кабинет',
      type: 'Home',
      selected: true,
    },
    {
      id: 2,
      text: 'Параметры загрузки',
      type: 'Load',
      selected: false,
    },
    {
      id: 3,
      text: 'Параметры сортировки',
      type: 'Sort',
      selected: false,
    },
  ]);

  const changeActiveNavItem = (id: number) => {
    const newArr = navItems.map((item: NavItem) => ({
      ...item,
      selected: item.id === id,
    }));
    setNavItems(newArr);
  };

  const changeActiveWindow = () => {
    const selectedNavItem = navItems.find(
      (item: NavItem) => item.selected === true
    );
    if (selectedNavItem && selectedNavItem.type === 'Home')
      return <HomeSettings />;
    if (selectedNavItem && selectedNavItem.type === 'Load')
      return <LoadSettings />;
    return <SortSettings />;
  };

  return (
    <Styled.Root>
      <Styled.Navigation>
        {navItems.map((item: NavItem) => (
          <Styled.NavigationItem
            onClick={() => changeActiveNavItem(item.id)}
            isactive={item.selected}
          >
            <Styled.NavigationItemText>{item.text}</Styled.NavigationItemText>
          </Styled.NavigationItem>
        ))}
      </Styled.Navigation>

      {changeActiveWindow()}
    </Styled.Root>
  );
};

export default AdminPersonalSettings;
