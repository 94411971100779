import React, { useEffect } from 'react';
import * as Styled from './styles';
import { useAppSelector } from '../../../hooks/storeHooks';

interface Props {
  active?: boolean;
}

const Loading: React.FC<Props> = ({ active }) => {
  const { loadingBar, loadingBarSize } = useAppSelector((state) => state.files);

  useEffect(() => {
    console.log(loadingBarSize);
  }, [loadingBarSize]);

  return (
    <Styled.Root active={active}>
      <Styled.LoadRect />
      {loadingBar ? (
        <Styled.LoadProgress>{Math.trunc(loadingBarSize)}%</Styled.LoadProgress>
      ) : (
        ''
      )}
    </Styled.Root>
  );
};

export default Loading;
