import React from 'react';
import * as Styled from './styles';

interface DrillingProps {
  className?: string;
}

const Drilling: React.FC<DrillingProps> = ({ className }) => {
  return <Styled.Root className={className}>Drilling</Styled.Root>;
};

export default Drilling;
