/* eslint-disable prettier/prettier */
/* eslint-disable no-else-return */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useState } from 'react';
import { useAppSelector } from 'src/hooks/storeHooks';
import { AdminReferenceTable } from './AdminReferenceTable';
import * as Styled from './styles';

type NavItem = {
  id: number,
  text: string,
  type: string,
  selected: boolean,
};

const AdminReferences: React.FC = () => {
  const { imgSetsInfo, selectLanguage } = useAppSelector(
    (state) => state.files
  );
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const [activeAdd, setActiveAdd] = useState<boolean>(false);
  const [navItems, setNavItems] = useState<NavItem[]>([
    {
      id: 1,
      text: selectLanguage === 'Ru' ? 'Органиации' : 'Organizations',
      type: 'organizations',
      selected: true,
    },
    {
      id: 2,
      text: selectLanguage === 'Ru' ? 'Месторождения' : 'Fields',
      type: 'field',
      selected: false,
    },
    {
      id: 3,
      text: selectLanguage === 'Ru' ? 'Рудники' : 'Mines',
      type: 'mine',
      selected: false,
    },
    {
      id: 4,
      text: selectLanguage === 'Ru' ? 'Скважины' : 'Wells',
      type: 'well',
      selected: false,
    },
  ]);

  const [tabeleOrganisations, setTableOrganisations] = useState<string[]>(
    selectLanguage === 'Ru' ? ['Организация'] : ['Organization']
  );
  const [tabeleDeposits, setTableDeposits] = useState<string[]>(
    selectLanguage === 'Ru'
      ? ['Организация', 'Месторождение']
      : ['Organization', 'Field']
  );
  const [tabeleMines, setTableMines] = useState<string[]>(
    selectLanguage === 'Ru'
      ? ['Организация', 'Месторождение', 'Рудник']
      : ['Organization', 'Field', 'Mine']
  );
  const [tabeleBoreholes, setTableBoreholes] = useState<string[]>(
    selectLanguage === 'Ru'
      ? ['Организация', 'Месторождение', 'Рудник', 'Скважина']
      : ['Organization', 'Field', 'Mine', 'Well']
  );

  const [selectedTable, setSelectedTable] = useState(tabeleOrganisations);

  const changeActiveNavItem = (id: number) => {
    const newArr = navItems.map((item: NavItem) => ({
      ...item,
      selected: item.id === id,
    }));
    setNavItems(newArr);

    if (id === 1) {
      setSelectedTable(tabeleOrganisations);
    } else if (id === 2) {
      setSelectedTable(tabeleDeposits);
    } else if (id === 3) {
      setSelectedTable(tabeleMines);
    } else {
      setSelectedTable(tabeleBoreholes);
    }
  };

  const renderAddInputs = () => {
    const type = navItems?.filter((item) => item.selected)[0]?.type;
    if (type === 'field') {
      return (
        <>
          <Styled.FieldInput placeholder="Организация" />
          <Styled.FieldInput placeholder="Месторождение" />
        </>
      );
    }
    if (type === 'mine') {
      return (
        <>
          <Styled.FieldInput placeholder="Организация" />
          <Styled.FieldInput placeholder="Месторождение" />
          <Styled.FieldInput placeholder="Рудник" />
        </>
      );
    }
    if (type === 'well') {
      return (
        <>
          <Styled.FieldInput placeholder="Организация" />
          <Styled.FieldInput placeholder="Месторождение" />
          <Styled.FieldInput placeholder="Рудник" />
          <Styled.FieldInput placeholder="Скважина" />
        </>
      );
    }
    return '';
  };

  return (
    <Styled.Root>
      <Styled.Navigation>
        {navItems.map((item: NavItem) => (
          <Styled.NavigationItem
            onClick={() => {
              changeActiveNavItem(item.id);
              setActiveAdd(false);
            }}
            isactive={item.selected}
          >
            <Styled.NavigationItemText>{item.text}</Styled.NavigationItemText>
          </Styled.NavigationItem>
        ))}
      </Styled.Navigation>

      <Styled.Box>
        <Styled.ActionsPanel>
          <Styled.FirstActionButton onClick={() => setActiveAdd(true)}>
            <Styled.ActionButtonText>
              {selectLanguage === 'Ru' ? 'Добавить' : 'Add'}
            </Styled.ActionButtonText>
          </Styled.FirstActionButton>

          <Styled.ActionButton>
            <Styled.ActionButtonText>
              {selectLanguage === 'Ru' ? 'Изменить' : 'Change'}
            </Styled.ActionButtonText>
          </Styled.ActionButton>

          <Styled.ActionButton>
            <Styled.ActionButtonText>
              {selectLanguage === 'Ru' ? 'Удалить' : 'Delete'}
            </Styled.ActionButtonText>
          </Styled.ActionButton>

          <Styled.MenuWrapper>
            <Styled.Menu onClick={() => setActiveMenu(!activeMenu)} />
            <Styled.MenuList isactive={activeMenu}>
              <Styled.MenuItem>
                <Styled.MenuItemText>Какое-то действие</Styled.MenuItemText>
              </Styled.MenuItem>
            </Styled.MenuList>
          </Styled.MenuWrapper>
        </Styled.ActionsPanel>

        {activeAdd &&
        navItems?.filter((item) => item.selected)[0]?.type !== 'organizations' ? (
          <Styled.AddPanel>
            {/* <Styled.SelectOrganization /> */}
            {renderAddInputs()}
            <Styled.ButtonSend>Сохранить</Styled.ButtonSend>
            <Styled.ButtonClose onClick={() => setActiveAdd(false)}>
              Отмена
            </Styled.ButtonClose>
          </Styled.AddPanel>
        ) : (
          ''
        )}

        <AdminReferenceTable
          table={selectedTable}
          imgSetsInfo={imgSetsInfo}
          type={navItems?.filter((item) => item.selected)[0].type}
          activeAdd={activeAdd}
        />
      </Styled.Box>
    </Styled.Root>
  );
};

export default AdminReferences;
