import { DefaultTheme } from 'styled-components';
import { baseVariables } from '../baseVariables';
import colors from './colors';

const variables: DefaultTheme = {
  ...baseVariables,
  colors: {
    ...baseVariables.colors,
    ...colors,
  },
};

export default variables;
