/* eslint-disable prettier/prettier */
/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { checkValidAct, clearAllAct, fillDragDropAct, localLoadFilesAct } from 'src/store/reducers/files';
import { fileExtensions, parseExtension } from 'src/utils/files';
import { Icon } from '../UI/Icon';
import * as Styled from './styles';

const imgExtensions = ['jpg', 'jpeg', 'JPG', 'JPEG', 'png'];
const docExtensions = ['xls', 'xlsx', 'docx', 'csv'];

interface DragDropProps {
  className?: string;
  // accessExtension?: string;
  dragType: 'img' | 'file';
  mustFiles?: boolean
}

const NotChoised: React.FC = () => {
  const { selectLanguage } = useAppSelector((state) => state.files);
  return <Styled.NotChoised>{selectLanguage === 'Ru' ? "Выберите профиль загрузки" : "Choose a download profile"}</Styled.NotChoised>;
};

const InvalidList: React.FC<{ fileNames: string[], onClick: () => void }> = ({
  fileNames,
  onClick,
}) => {
  const { selectLanguage } = useAppSelector((state) => state.files);
  return (
    <Styled.InvalidFilesWrapper>
      {selectLanguage === 'Ru' ? "Некорректные файлы: " : "Invalid files: "}
      {fileNames.join(', ')}
      <Styled.RemoveInvalidFiles onClick={onClick}>
        {selectLanguage === 'Ru' ? "Удалить" : "Delete"}
      </Styled.RemoveInvalidFiles>
    </Styled.InvalidFilesWrapper>
  );
};

const Files: React.FC<{ filesNames: string[], dragType: string }> = ({
  filesNames,
  dragType,
}) => {
  return (
    <Styled.ShowFilesWrapper>
      {dragType === 'img'
        ? filesNames.map((base, idx) => (
            <Styled.ImageFile key={idx}>
              <Styled.Image src="blalvla" alt="img" />
              <Styled.ImageFooter>{base}</Styled.ImageFooter>
            </Styled.ImageFile>
          ))
        : filesNames.map((name) => (
            <Styled.File key={name}>
              <Icon width={17} height={17} name="docx" />
            </Styled.File>
          ))}
    </Styled.ShowFilesWrapper>
  );
};

const DragDrop: React.FC<DragDropProps> = ({
  className,
  // accessExtension,
  dragType,
  mustFiles
}) => {
  const dispatch = useAppDispatch();
  const { formData, activeProfile, selectLanguage } = useAppSelector((state) => state.files);
  const [dragging, setDragging] = useState<boolean>(false);
  const [filesCount, setFilesCount] = useState<number>(0);

  const [files, setFiles] = useState<File[]>([]);
  const [notChoised, setNotChoised] = useState<boolean>(true);
  const [invalidFilenames, setInvalidFilenames] = useState<string[]>([]);

  useEffect(() => {
    // console.log(formData);
    setNotChoised(!!activeProfile);
  }, [activeProfile]);

  const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (notChoised) return;
    setDragging(true);
  };

  const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    // if (notChoised) return;
    setDragging(false);
    const fs = [...e.dataTransfer.files].filter((file) => {
      const splitLen = file.name.split('.').length;
      const fileExt = file.name.split('.')[splitLen - 1].toLowerCase();
      
      if (dragType === 'img' && !imgExtensions.includes(fileExt) && !files.includes(file)) {
        setInvalidFilenames([file.name, ...invalidFilenames]);
        return false;
      }
      if (dragType === 'file' && !docExtensions.includes(fileExt) && !files.includes(file)) {
        setInvalidFilenames([file.name, ...invalidFilenames]);
        return false;
      }

      return true;
    });
    const body = new FormData();
    setFilesCount(filesCount + fs.length);

    setFiles([...files, ...fs]);
    fs.forEach((file: File) => body.append(file.name, file));

    dispatch(localLoadFilesAct(fs));
    dispatch(fillDragDropAct(dragType))
    dispatch(checkValidAct())
  };

  const handleSelect = (e: React.FormEvent<HTMLLabelElement>) => {
    e.preventDefault();    
    // if (notChoised) return;

    // eslint-disable-next-line prettier/prettier
    const target = e.target as HTMLInputElement;
    setInvalidFilenames([]);

    const fs = [...Array.from(target.files || [])].filter((file) => {
      const splitLen = file.name.split('.').length;
      const fileExt = file.name.split('.')[splitLen - 1].toLowerCase();

      if (dragType === 'img' && !imgExtensions.includes(fileExt) && !files.includes(file)) {
        setInvalidFilenames([file.name, ...invalidFilenames]);
        return false;
      }
      if (dragType === 'file' && !docExtensions.includes(fileExt) && !files.includes(file)) {
        setInvalidFilenames([file.name, ...invalidFilenames]);
        return false;
      }

      return true;
    });


    const body = new FormData();
    setFilesCount(filesCount + fs.length);

    setFiles([...files, ...fs]);
    fs.forEach((file: File) => body.append(file.name, file));

    dispatch(localLoadFilesAct(fs));
    dispatch(fillDragDropAct(dragType))
    dispatch(checkValidAct())
  };

  const removeInvalid = () => {
    setInvalidFilenames([]);
  };

  const clearAll = () => {
    setFiles([]);
    setFilesCount(0);
    dispatch(clearAllAct(dragType));
  };

  useEffect(() => {
    console.log(files)
  }, [files])

  return (
    <Styled.Root
      className={className}
      onDragOver={handleDragIn}
      onDragLeave={handleDragOut}
      onDrop={handleDrop}
      dragging={dragging}
      isValid={invalidFilenames.length === 0}
    >
      {notChoised && <NotChoised />}
      {(filesCount > 0 || !mustFiles) ? (
        <>
          <Files
            filesNames={files.map((file) => file.name)}
            dragType={dragType}
          />
          <Styled.DragFooter>
            <Styled.AddBtn htmlFor={dragType} onChange={handleSelect}>
              {selectLanguage === 'Ru'
                ? "Добавить"
                : "Add"
              }
              <Icon name="add" width={12} height={12} />
              <Styled.Link id={dragType} multiple />
            </Styled.AddBtn>

            <Styled.FooterBtn variant="danger" onClick={clearAll}>
              {selectLanguage === 'Ru'
                ? "Удалить все"
                : "Delete everything"
              }
              <Icon name="minus" width={12} height={12} />
            </Styled.FooterBtn>
          </Styled.DragFooter>
        </>
      ) : (
        <>
          <Styled.LabelFile htmlFor={dragType} onChange={handleSelect}>
            {selectLanguage === 'Ru' ? "Выберите": "Select"}
            <Styled.Link id={dragType} multiple />
          </Styled.LabelFile>
          {selectLanguage === 'Ru' ? "или перенесите" : "or reschedule"}{' '}
          {dragType !== 'img'
            ? selectLanguage === 'Ru' ? "файлы": "files"
            : selectLanguage === 'Ru' ? "фото": "photo"}
        </>
      )}
      {invalidFilenames.length > 0 && (
        <InvalidList fileNames={invalidFilenames} onClick={removeInvalid} />
      )}
    </Styled.Root>
  );
};

export default DragDrop;
