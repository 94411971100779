import styled, { css } from 'styled-components';
import { Icon } from '../UI/Icon';

interface Props {
  opened?: boolean;
  isAcive?: boolean;
  isError?: boolean;
}

export const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  width: 305px;
  height: 50px;
  margin: 0px 0 5px 40px;

  display: flex;

  background: ${({ theme }) => theme.colors.darkBlue};

  border-left: 20px solid ${({ theme }) => theme.colors.lightBlue};
`;

export const Title = styled.div`
  ${({ theme }) => theme.typography.text_16_19}
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};

  margin: auto 0;
  padding-left: 15px;
`;

export const PenIcon = styled(Icon)`
  width: 50px;
  height: 50px;

  margin: 5px 0 0 80px;
  padding-left: 5px;
`;

// eslint-disable-next-line prettier/prettier
export const Box = styled.div<Props>`
  ${({ isError, theme }) =>
    isError &&
    css`
      border: 1px solid ${theme.colors.mainRed};
    `}
`;

export const FormWrapper = styled.div`
  position: relative;

  width: 305px;
  height: 20px;
  margin-left: 40px;

  display: flex;
  justify-content: space-between;

  background: ${({ theme }) => theme.colors.darkBlue};

  border-left: 20px solid ${({ theme }) => theme.colors.lightBlue};
  border-right: 50px solid ${({ theme }) => theme.colors.white};
`;

export const FormText = styled.div`
  ${({ theme }) => theme.typography.text_12_14}
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};

  margin: auto 0;
  padding-left: 15px;

  width: 235px;
`;

export const FormInput = styled.input`
  width: 305px;
  height: 30px;
  margin-left: 40px;

  padding-left: 10px;
  margin-bottom: 5px;

  outline: none;
  border: 0;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.typography.text_12_14}
  font-weight: 400;
`;

// eslint-disable-next-line prettier/prettier
export const OpenButton = styled.button<Props>`
  padding: 0;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    transition: all .2s;
    transform: rotate(${({ opened }) => opened && '180deg'});
  }
`;

// eslint-disable-next-line prettier/prettier
export const HeaderTopDropdownWrapper = styled.div<Props>`
  position: absolute;
  right: 0;
  bottom: 0;
  transition: all 0.2s;
  transform: ${({ opened }) =>
    opened ? 'translateY(103%)' : 'translateY(90%)'};
  visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};
  opacity: ${({ opened }) => Number(opened)};
  cursor: pointer;
  
  width: 100%;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  display: flex;
  flex-direction: column;
`;

// eslint-disable-next-line prettier/prettier
export const HeaderTopDropdownItem = styled.div<Props>`
  width: 100%;
  padding: 0 4px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  padding: 2px;
  ${({ theme }) => theme.typography.text_16_19}
  transition: color .1s;
  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    bottom: -1px;
    width: 100%;
    left: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:last-child::after {
    display: none;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.lightBlue};
  }

  ${({ theme, isAcive }) =>
    isAcive &&
    css`
      color: ${theme.colors.lightBlue};
    `}
`;

export const TextAreaWrapper = styled.div`
  width: 305px;
  height: 245px;
  margin-left: 40px;

  display: flex;

  background: ${({ theme }) => theme.colors.white};

  border-left: 20px solid ${({ theme }) => theme.colors.darkBlue};
`;

export const FormTextArea = styled.textarea`
  width: 285px;
  height: 245px;
  padding: 15px;

  resize: none;
  outline: none;
  border: 0;

  ${({ theme }) => theme.typography.text_12_14}
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;

  &::-webkit-input-placeholder {
    ${({ theme }) => theme.typography.text_12_14}
    color: ${({ theme }) => theme.colors.lightGray};
    font-weight: 600;
  }

  &:-moz-placeholder {
    ${({ theme }) => theme.typography.text_12_14}
    color: ${({ theme }) => theme.colors.lightGray};
    font-weight: 600;
  }
`;
