/* eslint-disable unused-imports/no-unused-vars */
// тут у нас основная логика
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setModifiedCracks, setNewCracks, setDeleteCracks } from './actions';
import axios from 'axios';
import { ICrack } from '../files';

export interface ICrackNew {
  imgId: number;
  crack: ICrack;
}

// описываем что должно быть у нас в стейте, обязательные и необязательные поля(?)
export interface CracksChangeState {
  newCracks: ICrackNew[];
  modifiedCracks: ICrack[];
  deleteCracks: number[];
  loadingCracksChangeState: 'loading' | 'failed' | 'success';
}

// инициализируем стейт
const initialState: CracksChangeState = {
  newCracks: [],
  modifiedCracks: [],
  deleteCracks: [],
  loadingCracksChangeState: 'success',
};

// создаем асинхронный action -> первый аргумент - имя состоящее из имя редусера и метода
export const updateCrack = createAsyncThunk(
  'cracksChange/update-crack',
  async ({ key, cracks }: { key: string, cracks: ICrack[] }) => {
    const snackeCracks: any = [];

    cracks.forEach((item) => {
      snackeCracks.push({
        crack_id: item.id,
        layer_inst__pix_middle: item.layerInstPixMiddle,
        layer_inst__start_pix: item.layerInstStartPix,
        layer_inst__end_pix: item.layerInstEndPix,
        crack_type: item.crackType,
        description: '',
      });
    });

    const { data } = await axios.post(`io/crack/update/`, snackeCracks, {
      headers: {
        Authorization: `Token ${key}`,
      },
    });
    return {
      cracks,
    };
  }
);

export const addCrack = createAsyncThunk(
  'cracksChange/add-crack',
  async ({ key, cracks }: { key: string, cracks: ICrackNew[] }) => {
    const newCracks: any = [];

    cracks.forEach((item) => {
      newCracks.push({
        img_id: item.imgId,
        crack_type: item.crack.crackType,
        layer_inst__end_pix: item.crack.layerInstEndPix,
        layer_inst__pix_middle: item.crack.layerInstPixMiddle,
        layer_inst__start_pix: item.crack.layerInstStartPix,
      });
    });

    const { data } = await axios.post(`io/crack/create/`, newCracks, {
      headers: {
        Authorization: `Token ${key}`,
      },
    });
    return {
      cracks,
    };
  }
);

export const removeCrack = createAsyncThunk(
  'cracksChange/remove-crack',
  async ({ key, cracks }: { key: string, cracks: number[] }) => {
    const { data } = await axios.delete(`io/crack/delete/`, {
      headers: {
        Authorization: `Token ${key}`,
      },
      data: {
        ids_to_delete: cracks,
      },
    });
    return {
      cracks,
    };
  }
);

export const cracksChangeSlise = createSlice({
  name: 'counter',
  initialState,
  /*
    тут юзаешь обычные изменения состаяния, пихаешь action'ы
  */
  reducers: {
    setNewCracks,
    setModifiedCracks,
    setDeleteCracks,
  },

  /*
    тут Cоздаешь reducer'ы в зависимости от вернувшегося состояния промиса(из asyncActions)
    [pending] = загрузка результатов
    [fulfilled] = успешное выполнение
    [rejected] = ошибка
  */
  extraReducers: (builder) => {
    builder.addCase(updateCrack.pending, (state) => {
      state.loadingCracksChangeState = 'loading';
    });

    builder.addCase(updateCrack.rejected, (state, action) => {
      state.loadingCracksChangeState = 'failed';
    });

    builder.addCase(updateCrack.fulfilled, (state, action) => {
      state.loadingCracksChangeState = 'success';
      state.modifiedCracks = [];
    });

    builder.addCase(addCrack.pending, (state) => {
      state.loadingCracksChangeState = 'loading';
    });

    builder.addCase(addCrack.rejected, (state, action) => {
      state.loadingCracksChangeState = 'failed';
    });

    builder.addCase(addCrack.fulfilled, (state, action) => {
      state.loadingCracksChangeState = 'success';
      state.newCracks = [];
    });

    builder.addCase(removeCrack.pending, (state) => {
      state.loadingCracksChangeState = 'loading';
    });

    builder.addCase(removeCrack.rejected, (state, action) => {
      state.loadingCracksChangeState = 'failed';
    });

    builder.addCase(removeCrack.fulfilled, (state, action) => {
      state.loadingCracksChangeState = 'success';
      state.deleteCracks = [];
    });
  },
});

// экспортишь все actions
export const {
  setNewCracks: setNewCracksAct,
  setModifiedCracks: setModifiedCracksAct,
  setDeleteCracks: setDeleteCracksAct,
} = cracksChangeSlise.actions;

export default cracksChangeSlise.reducer;
