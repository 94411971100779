/* eslint-disable unused-imports/no-unused-imports */
import { PayloadAction, current } from '@reduxjs/toolkit';
import { FilesState, ICrack, IFormData, IImgTable } from '.';

type dragTypes = 'file' | 'img';

export const addFormData = (
  state: FilesState,
  { payload }: PayloadAction<IFormData>
) => {
  state.formData = {
    ...state.formData,
    ...payload,
  };
};

export const setSelectedCompany = (
  state: FilesState,
  { payload }: PayloadAction<number>
) => {
  state.selectedCompany = payload;
};

export const selectedTabelsCreated = (
  state: FilesState,
  { payload }: PayloadAction<number>
) => {
  state.selectedTabels.push(payload);
};

export const selectedTabelsDeleted = (
  state: FilesState,
  { payload }: PayloadAction<number>
) => {
  state.selectedTabels = state.selectedTabels.filter(
    (item) => item !== payload
  );
};

export const setImageSetId = (
  state: FilesState,
  { payload }: PayloadAction<number>
) => {
  state.imagesetId = payload;
};

export const setSelectLanguage = (
  state: FilesState,
  { payload }: PayloadAction<'Ru' | 'En' | string>
) => {
  state.selectLanguage = payload;
};

export const setForwardRoutes = (
  state: FilesState,
  { payload }: PayloadAction<string[] | undefined>
) => {
  state.forwardRoutes = payload;
};

export const localLoadFiles = (
  state: FilesState,
  { payload }: PayloadAction<File[]>
) => {
  if (state.uploadedFilesNow) {
    payload.forEach((file) => state.uploadedFilesNow?.append(file.name, file));
  } else {
    state.uploadedFilesNow = new FormData();
    payload.forEach((file) => state.uploadedFilesNow?.append(file.name, file));
  }
};

export const checkImgInfo = (
  state: FilesState,
  { payload }: PayloadAction<number>
) => {
  state.imgInfo = (state.newImgInfo || state.images).filter(
    (img) => img.id === payload
  )[0]?.characteristics;

  // console.log(current(state.imgInfo));
};

export const fillDragDrop = (
  state: FilesState,
  { payload }: PayloadAction<dragTypes>
) => {
  switch (payload) {
    case 'file':
      state.filledDrops[0] = true;
      break;

    case 'img':
      state.filledDrops[1] = true;
      break;

    default:
      break;
  }
};

export const checkValid = (state: FilesState) => {
  state.stepsStates[state.currStep - 1] = 'valid';
  // const { well, fieldd, mine } = state.formData;

  // const allFilled = state.filledDrops.reduce((prev, curr) => prev && curr);
};

export const clearAll = (
  state: FilesState,
  { payload }: PayloadAction<dragTypes>
) => {
  state.uploadedFilesNow = undefined;
  switch (payload) {
    case 'file':
      state.filledDrops[0] = false;
      break;

    case 'img':
      state.filledDrops[1] = false;
      break;

    default:
      break;
  }
};

export const removeError = (
  state: FilesState,
  { payload }: PayloadAction<number>
) => {
  state.errors = state.errors.filter((e) => e.id !== payload);
};

export const clearImgs = (state: FilesState) => {
  state.images = [];
  state.globalInfoImgs = [];
  state.newImgInfo = undefined;
};

export const clearImgsTable = (state: FilesState) => {
  state.imgTable = [];
};

export const resetLoadingBar = (state: FilesState) => {
  state.loadingBar = false;
  state.uploadedFilesNow = undefined;
};

export const overrideImgInfo = (
  state: FilesState,
  { payload }: PayloadAction<{ imgId: number, id: number, value: any }>
) => {
  const { imgId, id, value } = payload;
  const currInfo = state.newImgInfo
    ? state.newImgInfo.find((img) => img.id === imgId)
    : state.images.find((img) => img.id === imgId);

  state.newImgInfo = (state.newImgInfo || state.images).map((imgInf) => {
    if (imgInf.id === imgId) {
      const newCh = currInfo?.characteristics?.map((ch) => {
        return {
          ...ch,
          edited: true,
          value: ch.idReqCharSettings === id ? value : ch.value,
        };
      });
      return {
        ...imgInf,
        edited: true,
        characteristics: newCh || imgInf.characteristics,
      };
    }
    return imgInf;
  });
  console.log(state.newImgInfo);
};

export const setCropType = (
  state: FilesState,
  { payload }: PayloadAction<string>
) => {
  state.typeCrop = payload;
};

export const setProfileType = (
  state: FilesState,
  { payload }: PayloadAction<string>
) => {
  state.prifileType = payload;
};

// export const setModifiedCracks = (
//   state: FilesState,
//   { payload }: PayloadAction<ICrack[]>
// ) => {
//   state.modifiedCracks = [...payload];
// };

export const setimgTable = (
  state: FilesState,
  { payload }: PayloadAction<IImgTable[][]>
) => {
  state.imgTable = payload;
};

export const setImgSetStatus = (
  state: FilesState,
  { payload }: PayloadAction<string>
) => {
  state.imgSetStatus = payload;
};

export const setLoadingBarSize = (
  state: FilesState,
  { payload }: PayloadAction<number>
) => {
  state.loadingBarSize = payload;
  console.log(payload);
};

export const setLoadingBar = (
  state: FilesState,
  { payload }: PayloadAction<boolean>
) => {
  state.loadingBar = payload;
  console.log(payload);
};

export const saveSelectTreeItem = (
  state: FilesState,
  {
    payload,
  }: PayloadAction<{
    name: string,
    url?: string,
    id?: number,
    numBox?: string,
    range?: string,
  }>
) => {
  state.selectedTreeItem = {
    ...state.selectedTreeItem,
    ...payload,
  };
};

export const clearFeilds = (state: FilesState) => {
  state.listFields = [];
};

export const clearMine = (state: FilesState) => {
  state.listMine = [];
};

export const clearWell = (state: FilesState) => {
  state.listMine = [];
};
