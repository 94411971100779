import typography from './typography';

const colors = {
  mainRed: '#A0191F',
  darkRed: '#801419',
  mainRedDisabled: '#D9555B',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#CCCCCC',
  lightGray: '#999999',
  darkGray: '#666666',
  dirtyWhite: '#F2F2F2',
  lightBlue: '#286275',
  darkBlue: '#0B3148',
  bgGray: '#E6E6E6',
  ligthBlack: '#323232',
  bgButton: '#80ACB6',
  hintColor: '#D8D8D8',
  hiddenColor: '#BCBCBC',
  linkColor: '#2323FD',
  lightTextGray: '#808080',
  darkTextGray: '#4D4D4D',
  disabilityGray: '#B3B3B3',
  menuGray: '#333333',
  menuActiveGray: '#0A0A0A',
  imgColor: '#C0C0C0',
  fileBg: '#808080',
  limeGreen: '#35CB00',
  brown: '#8C581A',
};

const baseStyles = {
  paddings: {
    mainSide: '80px',
    mainBottom: '53px',
    button: '8px',
  },
};

const animation = {
  transitionDuration: '0.25s',
};

const baseVariables = {
  colors: {
    ...colors,
  },
  typography,
  baseStyles,
  animation,
};

// eslint-disable-next-line prettier/prettier
export type stylesType = typeof baseVariables;

export { baseVariables };
