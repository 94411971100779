import { createGlobalStyle } from 'styled-components';
import { stylesType } from './baseVariables';

// eslint-disable-next-line prettier/prettier
const GlobalStyle = createGlobalStyle<{theme: stylesType}>`
  * {
    box-sizing: border-box;
    font-family: 'Raleway';
  }
  html, body, #root {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    background: ${({ theme }) => theme.colors.bgGray};
  }
  button {
    border: none;
    padding: 0;
    margin: 0;
  }
  a{
    text-decoration: none !important;
    border: none !important;
    }
`;

export { GlobalStyle };
