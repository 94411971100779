/* eslint-disable prettier/prettier */
import styled, { css, keyframes } from 'styled-components';

interface Props {
    active?: boolean
}

export const Root = styled.div<Props>`
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    background: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    ${({ active }) => css`
        visibility: ${active ? 'visible' : 'hidden'};
        opacity: ${Number(active)};
    `}
`;

const rotAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
`

export const LoadRect = styled.div`
    position: relative;
    width: 80px;
    height: 80px;
    background: transparent;
    border-radius: 8px;
    border: 5px solid ${({ theme }) => theme.colors.mainRed};
    animation: ${rotAnimation} 2s infinite forwards;
`

export const LoadProgress = styled.p`
    position: absolute;
    top: 47%;
    left: 49%;
    font-weight: 600;
    ${({ theme }) => theme.typography.text_16_18}
    color: ${({ theme }) => theme.colors.mainRed};
`;
