// тут у нас основная логика
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { incrementAction, decrementAction, valueIncAcntion } from './actions';
import axios from 'axios';
import { Todo } from 'src/store/types';

// описываем что должно быть у нас в стейте, обязательные и необязательные поля(?)
export interface CounterState {
  value: number; // обязательное
  state: 'loadig' | 'failed' | 'done';
  error?: string; // не обязательное поле(?)
  activeTodo?: Todo; // не обязательное поле(?)
}

// инициализируем стейт
const initialState: CounterState = {
  value: 0,
  state: 'done',
};

// создаем асинхронный action -> первый аргумент - имя состоящее из имя редусера и метода
export const asyncAct = createAsyncThunk(
  'counter/asyncAct',
  async (todoId: number) => {
    const { data } = await axios.get(
      `https://jsonplaceholder.typicode.com/todos/${todoId}`
    );
    return data;
  }
);

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  /*
    тут юзаешь обычные изменения состаяния, пихаешь action'ы
  */
  reducers: {
    incrementAction,
    decrementAction,
    valueIncAcntion,
  },

  /*
    тут Cоздаешь reducer'ы в зависимости от вернувшегося состояния промиса(из asyncActions)
    [pending] = загрузка результатов
    [fulfilled] = успешное выполнение
    [rejected] = ошибка
  */
  extraReducers: (builder) => {
    builder.addCase(asyncAct.pending, (state) => {
      state.state = 'loadig';
    });

    builder.addCase(asyncAct.rejected, (state, action) => {
      state.state = 'failed';
      state.error = action.error.code;
    });

    builder.addCase(asyncAct.fulfilled, (state, action) => {
      state.activeTodo = action.payload;
      state.state = 'done';
    });
  },
});

// экспортишь все actions
export const {
  incrementAction: increment,
  decrementAction: decrement,
  valueIncAcntion: valueInc,
} = counterSlice.actions;

export default counterSlice.reducer;
