/* eslint-disable prettier/prettier */
// import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Icon } from '../UI/Icon';

interface Props {
  isactive?: number;
  disabled?: number;
}

export const Root = styled.nav`
  display: flex;
  // flex-direction: column;

  padding: 40px 10px 40px 10px;
  margin-left: 20px
`;

// export const Tab = styled(Link)<Props>`
export const Tab = styled.div<Props>`
  width: 100px;
  height: 90px;
  z-index: 1;
  // background: ${({ theme }) => theme.colors.darkBlue};
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  &:hover {
    transition: 0.1s;
    background: ${({ theme }) => theme.colors.lightBlue};

    & svg {
      & use {
        fill: ${({ theme }) => theme.colors.white};
        stroke: ${({ theme }) => theme.colors.white};
      }
    }
    & p {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ theme, isactive, disabled }) => disabled 
    ? css`
      // background: ${theme.colors.lightGray};
      &:hover {
        background: ${({ theme }) => theme.colors.lightGray};
      }
    ` 
    : isactive && css`background: ${theme.colors.lightBlue}`}
`;

export const PhotoIcon = styled(Icon)`
  margin-top: 15px;
`;

export const TabText = styled.p<Props>`
  ${({ theme }) => theme.typography.text_12_14}
  color: ${({ theme }) => theme.colors.black};

  text-align: center;
  // width: 75px;

  margin: 5px auto;

  ${({ theme, isactive }) => isactive && css`color: ${theme.colors.white}`}
`;

export const LongTabText = styled.p`
  ${({ theme }) => theme.typography.text_12_14}
  color: ${({ theme }) => theme.colors.black};

  text-align: center;
  width: 120px;

  margin: 0 auto;
`;

export const DisabiledInfo = styled.div<Props>`
  position: absolute;
  display: flex;
  text-align: center;
  
  width: 100px;
  height: 90px;

  background: ${({ theme }) => theme.colors.black};
  opacity: 0;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
`

export const DisabiledInfoText = styled.p`
  margin: auto;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_12_14}
  opacity: 1;
`
