/* eslint-disable prettier/prettier */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { store, persistedStore } from './store/store';
import './styles/index.scss';
import { PersistGate } from 'redux-persist/integration/react'
import axios from 'axios';
import { _baseURL } from './env';

axios.defaults.baseURL = `${_baseURL}/v1/`;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistedStore} loading={null}>
        <Router>
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
