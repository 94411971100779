import styled, { css } from 'styled-components';
import { Icon } from '../UI/Icon';

interface Props {
  isInvalid?: boolean;
  opened?: boolean;
}

export const Root = styled.div`
  width: 100vw;
  height: 88px;
  background: ${({ theme }) => theme.colors.white};
  padding-left: 5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoIcon = styled(Icon)`
  width: 100%;
  height: 100%;

  padding-top: 7px;
  padding-left: 75px;
`;

export const LangBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 0;
  height: 100%;
  position: relative;
  color: ${({ theme }) => theme.colors.black} !important;
  ${({ theme }) => theme.typography.text_20_23}
  svg {
    margin: 0 5px;
  }
`;

// eslint-disable-next-line prettier/prettier
export const CustomIcon = styled(Icon)<Props>`
  transition: all 0.2s;
  cursor: pointer;
  transform: rotate(${({ opened }) => (opened ? '180deg' : '0')});
`;

// eslint-disable-next-line prettier/prettier
export const HiddenLang = styled.span<Props>`
  position: absolute;
  top: 30px;
  right: 24px;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.darkGray};
  ${({ opened }) =>
    opened
      ? css`
          transform: translateY(102%);
          opacity: 1;
          visibility: visible;
        `
      : css`
          transform: translateY(0%);
        `}
`;

export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  padding-right: 75px;
  height: 88px;
`;

export const HomeIcon = styled(Icon)`
  width: 70px;
  height: 80px;
  padding-top: 8px;
  cursor: pointer;
`;

export const UserInfo = styled.div`
  width: 16vw;
  margin: auto 0;
  margin-left: 15px;
  margin-right: 15px;
`;

export const UserName = styled.p`
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.typography.text_20_23}
  word-wrap: break-word;
  text-align: right;
  margin-bottom: 3px;
`;

export const UserID = styled.p`
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.typography.text_16_19}
  color: ${({ theme }) => theme.colors.lightGray};
  font-weight: 600;
  word-wrap: break-word;
  text-align: right;
`;

export const ProfileIcon = styled(Icon)`
  width: 70px;
  height: 80px;
  padding-top: 8px;

  cursor: pointer;
`;

export const ExitIcon = styled(Icon)`
  margin-left: 25px;
  cursor: pointer;
`;
