/* eslint-disable unused-imports/no-unused-vars */
import React, { useState } from 'react';
import * as Styled from './styles';

type CheckBoxItem = {
  id: number,
  text: string,
  selected: boolean,
};

const SortSettings: React.FC = () => {
  const [activeSelectSort, setActiveSelectSort] = useState<boolean>(false);
  const [selectedSortOption, setSelectedSortOption] =
    useState<string>('По номеру ящика');
  const [optionsSortList, setOptionsSortList] = useState<string[]>([
    'По номеру ящика',
    'По имени файла',
  ]);
  const [checkBoxItems, setCheckBoxItems] = useState<CheckBoxItem[]>([
    {
      id: 1,
      text: 'Количество доп. файлов',
      selected: false,
    },
    {
      id: 2,
      text: 'Дата загрузки',
      selected: false,
    },
    {
      id: 3,
      text: 'Статус',
      selected: false,
    },
  ]);

  const changeCheckBoxSelected = (id: number) => {
    const newArr = checkBoxItems.map((item: CheckBoxItem) => ({
      ...item,
      selected: item.id === id ? !item.selected : item.selected,
    }));
    setCheckBoxItems(newArr);
  };

  return (
    <Styled.Root>
      <Styled.SortSettingsItemWrapper>
        <Styled.SortSettingsItemTitle>
          Отображаемые поля:
        </Styled.SortSettingsItemTitle>
        <Styled.SortSettingsItemBody>
          {checkBoxItems.map((item: CheckBoxItem) => (
            <Styled.MultipleCheckBoxItem
              onClick={() => changeCheckBoxSelected(item.id)}
            >
              <Styled.CheckBox isactive={item.selected} />
              <Styled.CheckBoxText>{item.text}</Styled.CheckBoxText>
            </Styled.MultipleCheckBoxItem>
          ))}
        </Styled.SortSettingsItemBody>
      </Styled.SortSettingsItemWrapper>

      <Styled.SortSettingsItemWrapper>
        <Styled.SortSettingsItemTitle>
          Порядок сортировки:
        </Styled.SortSettingsItemTitle>
        <Styled.SortSettingsItemBody>
          <Styled.SelectSortBox
            onClick={() => setActiveSelectSort(!activeSelectSort)}
          >
            <Styled.SelectSortBoxText>
              {selectedSortOption}
            </Styled.SelectSortBoxText>
            <Styled.SelectIcon
              name="down"
              color="#FFFFFF"
              width={16}
              height={16}
              isactive={activeSelectSort}
            />
          </Styled.SelectSortBox>

          <Styled.SelectSortOptionsBox isactive={activeSelectSort}>
            {optionsSortList.map((item: string) => (
              <Styled.SelectBoxOption
                onClick={() => {
                  setActiveSelectSort(!activeSelectSort);
                  setSelectedSortOption(item);
                }}
                isactive={activeSelectSort}
              >
                <Styled.SelectBoxOptionText isactive={activeSelectSort}>
                  {item}
                </Styled.SelectBoxOptionText>
              </Styled.SelectBoxOption>
            ))}
          </Styled.SelectSortOptionsBox>
        </Styled.SortSettingsItemBody>
      </Styled.SortSettingsItemWrapper>

      <Styled.SortSettingsItemWrapper>
        <Styled.SortSettingsItemTitle>
          Прочее поле:
        </Styled.SortSettingsItemTitle>
        <Styled.SortSettingsItemBody>
          <Styled.SortSettingsItemInput placeholder="Поле ввода" />
        </Styled.SortSettingsItemBody>
      </Styled.SortSettingsItemWrapper>
    </Styled.Root>
  );
};

export default SortSettings;
