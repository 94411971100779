import { PayloadAction } from '@reduxjs/toolkit';
import { CracksChangeState, ICrackNew } from '.';
import { ICrack } from '../files';

export const setModifiedCracks = (
  state: CracksChangeState,
  { payload }: PayloadAction<ICrack[]>
) => {
  state.modifiedCracks = payload;
  console.log(state.modifiedCracks);
};

export const setNewCracks = (
  state: CracksChangeState,
  { payload }: PayloadAction<ICrackNew[]>
) => {
  state.newCracks = payload;
  console.log(state.newCracks);
};

export const setDeleteCracks = (
  state: CracksChangeState,
  { payload }: PayloadAction<number[]>
) => {
  state.deleteCracks = payload;
  console.log(state.deleteCracks);
};
