import styled from 'styled-components';

export const Root = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
`;

export const TargetRoot = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
`;
