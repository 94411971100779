/* eslint-disable prettier/prettier */
import { Icon } from 'src/components/UI/Icon';
import styled, { css } from 'styled-components';

interface Props {
  lastitem?: number;
  contentType?: string;
  tabType?: string;
  disabled?: number;
}

export const Root = styled.div`
  width: 100vw;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 88px);
  display: flex;
`;

export const LitoNavigation = styled.div`
  width: 305px;
  margin: 40px;
`;

export const SearchBarWrapper = styled.div`
  width: 100%;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 10px solid ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 18px;
  display: flex;
`;

export const SearchInput = styled.input`
  ${({ theme }) => theme.typography.text_16_19}
  padding-left: 8px;
  width: 275px;
  outline: none;
  border: 0;
`;

export const SearchButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  cursor: pointer;
  display: flex;

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }
`;

export const SearchIcon = styled(Icon)`
  margin: auto;
`;

export const ProjectsList = styled.div`
  width: 100%;
  height: 70vh;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Box = styled.div`
  // width: 740px;
  min-width: calc(100vw - 650px);
`;

export const MainLitoTabs = styled.div`
  width: 100%;
  height: 30px;
  margin: 40px 0 18px 20px;
  display: flex;
`;

export const TabWrapper = styled.div<Props>`
  position: relative;
  width: calc(33.3% - 13px);
  height: 100%;
  background-color: ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.lightGray};
  transform: skew(45deg);
  transition: 0.2s;
  position: relative;
  margin-left: 2px;
  padding-left: 12px;
  cursor: pointer;

  display: flex;
  justify-content:  space-between;

  &:first-child {
    padding-left: 0px;
  }

  &:first-child:after {
    content: '';
    position: absolute;
    top: 0px;
    left: -22px;
    height: 30px;
    width: 37px;
    transform: skew(-45deg);
    background-color: ${({ theme }) => theme.colors.gray};
    transition: 0.2s;
  }

  &:last-child:after {
    content: '';
    position: absolute;
    top: 0px;
    right: -15px;
    height: 30px;
    width: 30px;
    transform: skew(-45deg);
    background-color: ${({ theme }) => theme.colors.gray};
    transition: 0.2s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};
    transition: 0.2s;
  }

  &:first-child:hover:after {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    transition: 0.2s;
  }

  &:last-child:hover:after {
    background: ${({ theme }) => theme.colors.black};
    transition: 0.2s;
  }

  ${({contentType, tabType}) => contentType === tabType && css`
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};
  `}

  ${({contentType}) => contentType === 'parameters' && css`
    &:first-child:after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
    }
  `}

  ${({contentType}) => contentType === 'reporting' && css`
    &:last-child:after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
    }
  `}

  ${({ disabled }) => disabled && css`
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray};
      color: ${({ theme }) => theme.colors.lightGray};
      transition: 0.1s;
    }
    &:last-child:hover:after {
      background-color: ${({ theme }) => theme.colors.gray};
    }
	`}
`;

export const TabText = styled.p`
  // ${({ theme }) => theme.typography.text_16_19}
  font-size: 1.7vmin;
  font-weight: 700;
  margin: auto 15px;
  transform: skew(-45deg);
`;

export const TabIcon = styled(Icon)<Props>`
  transform: skew(-45deg);
  margin: auto 20px;
  color: ${({ theme }) => theme.colors.white};
  ${({lastitem}) => lastitem === 1 && css`
    margin: auto 13px;
  `}
`;

export const DisabiledInfo = styled.div<Props>`
  position: absolute;
  left: 15px;
  display: flex;
  text-align: center;
  
  width: 100%;
  height: 100%;
  transform: skew(-45deg);

  background: ${({ theme }) => theme.colors.black};
  opacity: 0;
  transition: 0.3s;
  z-index: 999;

  &::after {
    content: '';

    position: absolute;
    top: 0px;
    left: -15px;
    transform: skew(-135deg);
    
    width: 30px;
    height: 30px;
    background-color: ${({ theme }) => theme.colors.black};
    z-index: 998;
  }

  &::before {
    content: '';

    position: absolute;
    top: 0px;
    right: 0px;

    width: 30px;
    height: 30px;
    background-color: ${({ theme }) => theme.colors.black};
    z-index: 998;
  }

  &:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
`

export const DisabiledInfoText = styled.p`
  position: relative;
  z-index: 999;

  margin: auto;
  margin-left: 12px;

  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.typography.text_14_16}
  opacity: 1;

  user-select: none;
`
