/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { LitoShelfItem } from 'src/components/LitoShelfItem';
import { Loading } from 'src/components/UI/Loading';
import { _baseURL } from 'src/env';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import {
  updateCrack,
  addCrack,
  removeCrack,
} from 'src/store/reducers/cracksChange';
import { getCropedImages } from 'src/store/reducers/files/async';
// import { setCropTypeAct } from 'src/store/reducers/files';
import * as Styled from './styles';

interface LitoParametersProps {
  className?: string;
}

type colorItem = {
  colorName: string,
  width: number,
};

export interface Crack {
  confidence: number;
  crackType: string;
  layerColor: string;
  color?: string;
  id?: number;
  quantity?: number;
  layerInstEndPix: number;
  layerInstPixMiddle: number;
  layerInstStartPix: number;
}

export interface ShelfItem {
  id: number;
  maskUrl: string;
  url: string;
  from: number;
  cstart: number;
  to: number;
  realFrom: number;
  realTo: number;
  colors: colorItem[];
  cracks: Crack[];
}

const LitoParameters: React.FC<LitoParametersProps> = ({ className }) => {
  const [activeBoxImg, setActiveBoxImg] = useState<boolean>(true);
  const { loadingState, authKey } = useAppSelector((state) => state.auth);
  const [shelfItemList, setShelfItemList] = useState<ShelfItem[]>([]);
  const [showMasks, setShowMasks] = useState<boolean>(false);
  const [saveFlag, setSaveFlag] = useState<boolean>(false);
  const {
    selectedTreeItem,
    typeCrop,
    selectLanguage,
    imagesetId,
    tablePhotos,
    cropedImages,
  } = useAppSelector((state) => state.files);
  const { modifiedCracks, newCracks, loadingCracksChangeState, deleteCracks } =
    useAppSelector((state) => state.cracksChange);
  const { id: imageId } = useAppSelector(
    (state) => state.files.selectedTreeItem
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      newCracks.length !== 0 ||
      modifiedCracks.length !== 0 ||
      deleteCracks.length !== 0
    ) {
      setSaveFlag(true);
    } else {
      setSaveFlag(false);
    }
  }, [modifiedCracks, newCracks, deleteCracks]);

  useEffect(() => {
    setShelfItemList(
      cropedImages.map((cImg) => {
        return {
          id: cImg.id,
          url: cImg.path,
          maskUrl: cImg.maskUpload,
          cstart: cImg.cstart,
          from: cImg.start,
          to: cImg.finish,
          realFrom: cImg.cstart,
          realTo: cImg.cfinish,
          colors: [],
          cracks: cImg.cacks,
        };
      })
    );
    if (tablePhotos.length === 0) {
      setShelfItemList([]);
    }
  }, [cropedImages]);

  useEffect(() => {
    if (loadingCracksChangeState === 'failed' && imageId) {
      dispatch(
        getCropedImages({
          key: authKey || '',
          imageId,
          type: typeCrop,
        })
      );
    }
  }, [loadingCracksChangeState]);

  return (
    <Styled.Root className={className}>
      <Loading active={loadingCracksChangeState === 'loading'} />
      <Styled.BoxInfo>
        <Styled.BoxInfoWrapper>
          <Styled.BoxInfoText>
            {`${selectLanguage === 'Ru' ? 'Номер ящика:' : 'Box number:'}
            ${selectedTreeItem.numBox}`}
          </Styled.BoxInfoText>
          <Styled.BoxInfoText>
            {`${selectLanguage === 'Ru' ? 'Интервал:' : 'Interval:'}
            ${selectedTreeItem.range}`}
          </Styled.BoxInfoText>
        </Styled.BoxInfoWrapper>
      </Styled.BoxInfo>
      <Styled.BoxImgWrapper isactive={activeBoxImg ? 1 : 0}>
        <Styled.BoxImg
          src={`${_baseURL}/media/${selectedTreeItem.url}`}
          isactive={activeBoxImg ? 1 : 0}
          alt="Box"
        />

        <Styled.HideActionButton
          onClick={() => setActiveBoxImg(!activeBoxImg)}
          isactive={activeBoxImg ? 1 : 0}
        >
          <Styled.HideActionArrow
            name="down"
            width={20}
            height={20}
            color="#FFFFFF"
            isactive={activeBoxImg ? 1 : 0}
          />
          <Styled.HideActionArrowText isactive={activeBoxImg ? 1 : 0}>
            {selectLanguage === 'Ru'
              ? activeBoxImg
                ? 'Свернуть'
                : 'Развернуть'
              : activeBoxImg
              ? 'Collapse'
              : 'Expand'}
          </Styled.HideActionArrowText>
        </Styled.HideActionButton>
      </Styled.BoxImgWrapper>
      <Styled.ShowMaskBtn
        onClick={() => setShowMasks(!showMasks)}
        disabled={typeCrop === 'lythology' || typeCrop === 'oxides'}
      >
        {selectLanguage === 'Ru'
          ? showMasks
            ? 'Скрыть маски'
            : 'Показать маски'
          : showMasks
          ? 'Hide masks'
          : 'Show masks'}
      </Styled.ShowMaskBtn>

      <Styled.ShowMaskBtn
        onClick={() => {
          dispatch(
            updateCrack({
              key: authKey || '',
              cracks: modifiedCracks,
            })
          );
          dispatch(
            addCrack({
              key: authKey || '',
              cracks: newCracks,
            })
          );
          dispatch(
            removeCrack({
              key: authKey || '',
              cracks: deleteCracks,
            })
          );
        }}
        disabled={!saveFlag}
      >
        {selectLanguage === 'Ru' ? 'Сохранить' : 'Save'}
      </Styled.ShowMaskBtn>
      <Styled.CropWrapper>
        {shelfItemList.map((shelf: ShelfItem) => (
          <LitoShelfItem shelfItem={shelf} showMasks={showMasks} />
        ))}
      </Styled.CropWrapper>
    </Styled.Root>
  );
};

export default LitoParameters;
