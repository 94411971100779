/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { useOnClickOutside } from 'src/hooks/domHooks';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { setCropTypeAct } from 'src/store/reducers/files';
import {
  downloadKernXlsx,
  getAnaliz,
  getCropedImages,
  updateTable,
} from 'src/store/reducers/files/async';
import { ILegend, Row } from 'src/store/types';
import { Loading } from '../UI/Loading';
import * as Styled from './styles';

interface Props {
  data: Row[];
}

type Option = {
  id: number,
  name: string,
  type: string,
  selected: boolean,
};

const Analytics: React.FC<Props> = ({ data }) => {
  const [posx, setPosx] = useState<number>(0);
  const [posy, setPosy] = useState<number>(0);
  const [selectedRowId, setSelectRowId] = useState<number>(0);
  const [menuState, setMenuState] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const [activeLegend, setActiveLegend] = useState<boolean>(false);
  const [activeParametersSelect, setActiveParametersSelect] =
    useState<boolean>(false);
  const [rows, setRows] = useState<Row[]>([]);
  const { authKey } = useAppSelector((state) => state.auth);
  const {
    analizId,
    loadingState,
    legend,
    tableInfo,
    selectLanguage,
    typeCrop,
    analizMenu,
    imagesetId,
  } = useAppSelector((state) => state.files);
  const ref = useRef<null | HTMLDivElement>(null);

  const [tableWindowHeight, setTableWindowHeight] = useState<number>(0);
  const tableWindowRef = useRef<any>(null);

  const [options, setOptions] = useState<Option[]>([
    {
      id: 1,
      name: selectLanguage === 'Ru' ? 'Литология' : 'Lithology',
      type: 'lythology',
      selected: typeCrop === 'lythology',
    },
    {
      id: 2,
      name: selectLanguage === 'Ru' ? 'Трещины' : 'Cracks',
      type: 'crack',
      selected: typeCrop === 'crack',
    },
    {
      id: 3,
      name: selectLanguage === 'Ru' ? 'Сульфиды' : 'Sulfides',
      type: 'sulfides',
      selected: typeCrop === 'sulfides',
    },
    {
      id: 4,
      name: selectLanguage === 'Ru' ? 'Окисление' : 'Oxides',
      type: 'oxides',
      selected: typeCrop === 'oxides',
    },
  ]);

  const HeaderTitles: string[] =
    selectLanguage === 'Ru'
      ? [
          'Номер ящика',
          'Полка',
          'Интервал бурения',
          '',
          'Рейс бурения',
          'от',
          'до',
          'м.',
          'факт м.',
          'Выход (TCR)',
        ]
      : [
          'Box number',
          'Shelf',
          'Drilling interval',
          '',
          'Drilling flight',
          'from',
          'to',
          'm.',
          'real m.',
          'Exit (TCR)',
        ];

  useOnClickOutside(ref, () => setMenuState(false));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (tableWindowRef !== null && tableWindowRef.current !== null) {
      setTableWindowHeight(tableWindowRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    setRows(
      data.map((row) => ({
        ...row,
        valueTo: Number(row.valueTo?.toFixed(3)),
      }))
    );
  }, [data]);

  if (loadingState === 'loading') {
    return <Loading active />;
  }

  const rightClick = (
    e: React.MouseEvent<HTMLImageElement | HTMLDivElement>,
    rowId: number
  ) => {
    e.preventDefault();

    if (e.type === 'contextmenu') {
      const posX = e.pageX;
      const posY = e.pageY;

      if (posx === posX) {
        setMenuState(!menuState);
      } else {
        setMenuState(true);
      }

      setPosx(posX);
      setPosy(posY);
      setSelectRowId(rowId);
    }
  };

  const updItemType = (color) => {
    const newArr = rows.map((item: Row) => {
      if (item.id !== selectedRowId) {
        return {
          ...item,
        };
      }
      return {
        ...item,
        color,
      };
    });
    setRows(newArr);
    setMenuState(false);
  };

  const updAllItemType = (color) => {
    const idArr = [selectedRowId];
    const index = rows.indexOf(
      rows.filter((item) => item.id === selectedRowId)[0]
    );

    let left = index;
    let right = index;
    const curColor = rows[index].color;

    while (
      rows[left - 1]?.color === curColor ||
      rows[right + 1]?.color === curColor
    ) {
      if (left - 1 >= 0 && rows[left - 1].color === curColor) {
        left--;
        idArr.push(rows[left].id);
      }
      if (right + 1 < rows.length && rows[right + 1].color === curColor) {
        right++;
        idArr.push(rows[right].id);
      }
    }

    const newArr = rows.map((item: Row) => {
      if (!idArr.includes(item.id)) {
        return {
          ...item,
        };
      }
      return {
        ...item,
        color,
      };
    });
    setRows(newArr);
    setMenuState(false);
  };

  const сhangeRow = (rowId: number, value: string, field: string) => {
    console.log(analizId);
    if (value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
      const newArr = rows.map((item: Row) => {
        if (item.id !== rowId) {
          return {
            ...item,
          };
        }
        return {
          ...item,
          [field]: value,
          changed: true,
        };
      });
      setRows(newArr);
    }
  };

  const onLeaveRow = (value: string, changed: boolean, id?: number) => {
    if (changed && analizId) {
      const func = async () => {
        await dispatch(
          updateTable({
            choiseType: tableInfo?.typeChoice?.toLowerCase() || '',
            key: authKey || '',
            analizId: analizId || 0,
            drillingId: id,
            value: Number(value),
          })
        );
        await dispatch(
          getAnaliz({
            key: authKey || '',
            imageSetId: analizId,
            typeCrop,
          })
        );
      };
      func();
    }
    rows.map((row) => ({
      ...row,
      changed: false,
    }));
  };

  const switchActiveoption = (type: string) => {
    const newArr = options.map((item: Option) => {
      console.log(type);
      return {
        ...item,
        selected: type === item.type,
      };
    });

    newArr.forEach((item: Option) => {
      if (item.selected) {
        dispatch(setCropTypeAct(item.type));
      }
    });

    setOptions(newArr);
  };

  const Row = ({ index, style }) => {
    const row = rows[index];

    return (
      <Styled.RowWrapper key={row.id} style={style}>
        <Styled.RowItemBox>
          <Styled.RowItem>{row.boxNumFinish}</Styled.RowItem>
        </Styled.RowItemBox>
        <Styled.RowItemBox>
          <Styled.RowItem>{row.cabinetNumFinish}</Styled.RowItem>
        </Styled.RowItemBox>
        <Styled.RowItemBox>
          <Styled.RowItem>{row.interval}</Styled.RowItem>
        </Styled.RowItemBox>
        <Styled.RowItemColorBoxWrapper>
          <Styled.RowItemColorBox
            color={row.color}
            onContextMenu={(e) => rightClick(e, row.id)}
          />
          <Styled.RowItemHint>
            {
              legend.filter((item) => item.color === row.color)[0]
                ?.lythologyName
            }
          </Styled.RowItemHint>
        </Styled.RowItemColorBoxWrapper>
        <Styled.RowItemBox>
          <Styled.RowItem>{row.cruiserNum}</Styled.RowItem>
        </Styled.RowItemBox>
        <Styled.RowItemBox>
          <Styled.RowItem>{row.valueFrom?.toFixed(3)}</Styled.RowItem>
        </Styled.RowItemBox>
        <Styled.RowItemBox>
          {row.cruiserNum ? (
            <Styled.RowItemInput
              value={row.valueTo}
              onBlur={(e) =>
                onLeaveRow(e.target.value, !!row.changed, row.drillingId)
              }
              onChange={(e) => сhangeRow(row.id, e.target.value, 'valueTo')}
            />
          ) : (
            <Styled.RowItem />
          )}
        </Styled.RowItemBox>
        <Styled.RowItemBox>
          <Styled.RowItem>{row.metr?.toFixed(3)}</Styled.RowItem>
        </Styled.RowItemBox>
        <Styled.RowItemBox>
          <Styled.RowItem>{row.factMetr?.toFixed(3)}</Styled.RowItem>
        </Styled.RowItemBox>
        <Styled.RowItemBox>
          <Styled.RowItem>{row.output}</Styled.RowItem>
        </Styled.RowItemBox>
      </Styled.RowWrapper>
    );
  };

  return (
    <Styled.Root>
      {menuState && (
        <Styled.MenuBox xpos={posx} ypos={posy} ref={ref}>
          <Styled.MenuItems>
            <Styled.MenuItemR>
              {selectLanguage === 'Ru' ? 'Изменить тип ячейки' : 'Change type'}
              <Styled.MenuItemMenu className="sub-menu">
                <Styled.MenuItems>
                  {legend?.map((leg) => (
                    <Styled.MenuItemR
                      onClick={() => updItemType(leg.color)}
                      key={leg.lythologyName}
                      color={leg.color}
                    >
                      {leg.lythologyName}
                    </Styled.MenuItemR>
                  ))}
                </Styled.MenuItems>
              </Styled.MenuItemMenu>
            </Styled.MenuItemR>
            <Styled.MenuItemR>
              {selectLanguage === 'Ru' ? 'Изменить тип отрезка' : 'Change type'}
              <Styled.MenuItemMenu className="sub-menu">
                <Styled.MenuItems>
                  {legend?.map((leg) => (
                    <Styled.MenuItemR
                      onClick={() => updAllItemType(leg.color)}
                      key={leg.lythologyName}
                      color={leg.color}
                    >
                      {leg.lythologyName}
                    </Styled.MenuItemR>
                  ))}
                </Styled.MenuItems>
              </Styled.MenuItemMenu>
            </Styled.MenuItemR>
          </Styled.MenuItems>
        </Styled.MenuBox>
      )}

      <Styled.ActionsPanel>
        <Styled.LegendWrapper>
          <Styled.Legend
            onClick={() => setActiveLegend(!activeLegend)}
            isactive={activeLegend ? 1 : 0}
          >
            <Styled.LegendText>
              {selectLanguage === 'Ru' ? 'Легенда' : 'Legend'}
            </Styled.LegendText>
            <Styled.LegendIcon
              name="down"
              color="#FFFFFF"
              width={16}
              height={16}
              isactive={activeLegend ? 1 : 0}
            />
          </Styled.Legend>
          <Styled.LegendInfo isactive={activeLegend ? 1 : 0}>
            {legend &&
              legend?.map((Legend: ILegend) => (
                <Styled.LegendItem>
                  <Styled.LegendColor color={Legend.color} />
                  <Styled.LegendItemText>
                    {Legend.lythologyName}
                  </Styled.LegendItemText>
                </Styled.LegendItem>
              ))}
          </Styled.LegendInfo>
        </Styled.LegendWrapper>

        <Styled.ParametersWrapper>
          <Styled.Parameters
            onClick={() => {
              setActiveParametersSelect(!activeParametersSelect);
              setActiveLegend(false);
            }}
            isactive={activeParametersSelect ? 1 : 0}
          >
            <Styled.SelectedParameter>
              {/* {selectOption} */}
              {analizMenu.length > 0
                ? options.filter((item) => item.selected)[0]?.name
                : ''}
            </Styled.SelectedParameter>
            <Styled.LegendIcon
              name="down"
              color="#FFFFFF"
              width={16}
              height={16}
              isactive={activeParametersSelect ? 1 : 0}
            />
          </Styled.Parameters>

          <Styled.ParametersList isactive={activeParametersSelect ? 1 : 0}>
            {analizMenu.map((item: string) => (
              <Styled.ParameterOption
                isactive={
                  options.filter((option) => option.type === item)[0]?.selected
                    ? 1
                    : 0
                }
                onClick={() => switchActiveoption(item)}
              >
                <Styled.ParameterOptionText>
                  {options.filter((option) => option.type === item)[0]?.name}
                </Styled.ParameterOptionText>
              </Styled.ParameterOption>
            ))}
          </Styled.ParametersList>
        </Styled.ParametersWrapper>

        <Styled.MenuWrapper>
          <Styled.Menu onClick={() => setActiveMenu(!activeMenu)} />
          <Styled.MenuList isactive={activeMenu ? 1 : 0}>
            <Styled.MenuItem
              onClick={() => {
                if (imagesetId && authKey) {
                  dispatch(
                    downloadKernXlsx({
                      key: authKey || '',
                      imagesetId,
                      typeCrop,
                    })
                  );
                }
              }}
            >
              <Styled.MenuItemText>
                {selectLanguage === 'Ru'
                  ? 'Выгрузить отчет'
                  : 'Upload a report'}
              </Styled.MenuItemText>
            </Styled.MenuItem>
          </Styled.MenuList>
        </Styled.MenuWrapper>
      </Styled.ActionsPanel>

      <Styled.TableWrapper ref={tableWindowRef}>
        <Styled.Table>
          <Styled.TableHeader>
            {HeaderTitles?.map((title) => (
              <Styled.TableTextWrapper>
                <Styled.HeaderText key={title}>{title}</Styled.HeaderText>
              </Styled.TableTextWrapper>
            ))}
          </Styled.TableHeader>

          <List
            height={tableWindowHeight - 80}
            itemCount={rows.length}
            itemSize={25}
            width={709}
          >
            {Row}
          </List>
        </Styled.Table>
      </Styled.TableWrapper>
    </Styled.Root>
  );
};

export default Analytics;
