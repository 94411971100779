/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports */
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { setCropTypeAct } from 'src/store/reducers/files';
import { ILegend } from 'src/store/types';
import { LitoParameters } from './LitoParameters';
import * as Styled from './styles';

interface ParametersProps {
  className?: string;
}

// type LegendItem = {
//   color: string,
//   legendName: string,
// };

type Option = {
  id: number,
  name: string,
  type: string,
  selected: boolean,
};

// export const Legends: LegendItem[] = [
//   {
//     color: 'gray',
//     legendName: 'Название легенды 1',
//   },
//   {
//     color: 'mainRed',
//     legendName: 'Название легенды 2',
//   },
// ];

const Parameters: React.FC<ParametersProps> = ({ className }) => {
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const [activeLegend, setActiveLegend] = useState<boolean>(false);
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const [activeParametersSelect, setActiveParametersSelect] =
    useState<boolean>(false);
  // const [selectOption, setSelectedOption] = useState<string>('Литология');
  const { typeCrop, legend, selectLanguage, analizMenu } = useAppSelector(
    (state) => state.files
  );

  const [options, setOptions] = useState<Option[]>([
    {
      id: 1,
      name: selectLanguage === 'Ru' ? 'Литология' : 'Lithology',
      type: 'lythology',
      selected: typeCrop === 'lythology',
    },
    {
      id: 2,
      name: selectLanguage === 'Ru' ? 'Трещины' : 'Cracks',
      type: 'crack',
      selected: typeCrop === 'crack',
    },
    {
      id: 3,
      name: selectLanguage === 'Ru' ? 'Сульфиды' : 'Sulfides',
      type: 'sulfides',
      selected: typeCrop === 'sulfides',
    },
    {
      id: 4,
      name: selectLanguage === 'Ru' ? 'Окисление' : 'Oxides',
      type: 'oxides',
      selected: typeCrop === 'oxides',
    },
  ]);

  const dispatch = useAppDispatch();

  const switchActiveoption = (type: string) => {
    const newArr = options.map((item: Option) => {
      console.log(type);
      return {
        ...item,
        selected: type === item.type,
      };
    });

    newArr.forEach((item: Option) => {
      if (item.selected) {
        // console.log(item);
        dispatch(setCropTypeAct(item.type));
        // setSelectedOption(item.name);
      }
    });

    setOptions(newArr);
  };

  // useEffect(() => {
  //   console.log(selectOption);
  // }, [selectOption]);

  return (
    <Styled.Root className={className}>
      <Styled.OverlayPanel
        isactive={activeModal ? 1 : 0}
        onClick={() => setActiveModal(!activeModal)}
      />
      <Styled.ActionsPanel>
        <Styled.LegendWrapper>
          <Styled.Legend
            onClick={() => {
              setActiveLegend(!activeLegend);
              setActiveParametersSelect(false);
            }}
            isactive={activeLegend ? 1 : 0}
          >
            <Styled.LegendText>
              {selectLanguage === 'Ru' ? 'Легенда' : 'Legend'}
            </Styled.LegendText>
            <Styled.LegendIcon
              name="down"
              color="#FFFFFF"
              width={16}
              height={16}
              isactive={activeLegend ? 1 : 0}
            />
          </Styled.Legend>
          <Styled.LegendInfo isactive={activeLegend ? 1 : 0}>
            {legend?.map((Legend: ILegend) => (
              <Styled.LegendItem>
                <Styled.LegendColor color={Legend.color} />
                <Styled.LegendItemText>
                  {Legend.lythologyName}
                </Styled.LegendItemText>
              </Styled.LegendItem>
            ))}
          </Styled.LegendInfo>
        </Styled.LegendWrapper>

        <Styled.ParametersWrapper>
          <Styled.Parameters
            onClick={() => {
              setActiveParametersSelect(!activeParametersSelect);
              setActiveLegend(false);
            }}
            isactive={activeParametersSelect ? 1 : 0}
          >
            <Styled.SelectedParameter>
              {/* {selectOption} */}
              {analizMenu.length > 0
                ? options.filter((item) => item.selected)[0]?.name
                : ''}
            </Styled.SelectedParameter>
            <Styled.LegendIcon
              name="down"
              color="#FFFFFF"
              width={16}
              height={16}
              isactive={activeParametersSelect ? 1 : 0}
            />
          </Styled.Parameters>

          <Styled.ParametersList isactive={activeParametersSelect ? 1 : 0}>
            {analizMenu.map((item: string) => (
              <Styled.ParameterOption
                isactive={
                  options.filter((option) => option.type === item)[0]?.selected
                    ? 1
                    : 0
                }
                onClick={() => switchActiveoption(item)}
              >
                <Styled.ParameterOptionText>
                  {options.filter((option) => option.type === item)[0]?.name}
                </Styled.ParameterOptionText>
              </Styled.ParameterOption>
            ))}
          </Styled.ParametersList>
        </Styled.ParametersWrapper>

        <Styled.EditButton onClick={() => setActiveModal(!activeModal)}>
          <Styled.EditButtonText>
            {selectLanguage === 'Ru' ? 'Помощь' : 'Help'}
          </Styled.EditButtonText>
        </Styled.EditButton>

        <Styled.MenuWrapper>
          <Styled.Menu onClick={() => setActiveMenu(!activeMenu)} />
          <Styled.MenuList isactive={activeMenu ? 1 : 0}>
            <Styled.MenuItem>
              <Styled.MenuItemText>
                {selectLanguage === 'Ru'
                  ? 'Получить рейсы бурения'
                  : 'Get drilling flights'}
              </Styled.MenuItemText>
            </Styled.MenuItem>
          </Styled.MenuList>
        </Styled.MenuWrapper>
      </Styled.ActionsPanel>

      <LitoParameters />

      <Styled.HelpModal isactive={activeModal ? 1 : 0}>
        <Styled.HelpModalTitle>
          <Styled.HelpModalExit onClick={() => setActiveModal(!activeModal)} />
          {selectLanguage === 'Ru' ? 'Помощь в пользовании' : 'Help in using'}
        </Styled.HelpModalTitle>
        <Styled.HelpModalContent>
          <Styled.HelpModalText>
            {selectLanguage === 'Ru'
              ? `Для того, чтобы добавить трещину, наведите курсор на интересующую
                вас фотографию керна в пустое пространство, после нажмите на ПКМ и в
                выпадающем списке выберитине пункт "добавить трещину"`
              : `In order to add a crack, hover the cursor over the photo of the 
                core you are interested in in an empty space, then click on the RCM 
                and select "add crack" in the drop-down list`}
          </Styled.HelpModalText>

          <Styled.HelpModalText>
            {selectLanguage === 'Ru'
              ? `Для того, чтобы удалить трещину, наведите курсор на интересующую вас
                трещину, после нажните на ПКМ и в выпадающем списке выберите пункт
                "удалить трещину"`
              : `In order to remove a crack, hover the cursor over the one you are interested in
                crack, then click on the RCM and select the item in the drop-down list
                "remove the crack"`}
          </Styled.HelpModalText>

          <Styled.HelpModalText>
            {selectLanguage === 'Ru'
              ? `Для того, чтобы изменить тип трещины, наведите курсор на
                интересующую вас трещину, после нажмите на ПКМ и в выпадающем списке
                выберите пункт "изменить тип трещины", в подменю выберите
                интересующий вас тип`
              : `To change the type of crack, hover the cursor over
                the crack you are interested in, then click on the RCM and in the drop-down list
                select "change crack type", in the submenu select
                the type you are interested in`}
          </Styled.HelpModalText>
        </Styled.HelpModalContent>
      </Styled.HelpModal>
    </Styled.Root>
  );
};

export default Parameters;
