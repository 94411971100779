import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
// eslint-disable-next-line unused-imports/no-unused-imports
import { setActivePathAct, setSubPath } from 'src/store/reducers/navigation';
import { SvgNames } from '../UI/Icon/Icon';
import * as Styled from './styles';
import { useLocation, useNavigate } from 'react-router-dom';

interface NavigationProps {
  className?: string;
}

type NavigationItem = {
  name: SvgNames,
  text: string,
  textEn: string,
  selected: boolean,
  path: string[],
  disabled: boolean,
};

export const innerRoutes: NavigationItem[] = [
  {
    name: 'home',
    text: 'Кабинет',
    textEn: 'Cabinet',
    selected: true,
    path: ['/home'],
    disabled: false,
  },
  {
    name: 'photo',
    text: 'Загрузка фотографий',
    textEn: 'Uploading photos',
    selected: false,
    path: ['/upload#upload', '/upload#check'],
    disabled: false,
  },
  {
    name: 'scan',
    text: 'Определение характеристик',
    textEn: 'Definition characteristics',
    selected: false,
    path: ['/check-lito/'],
    disabled: false,
  },
  {
    name: 'settings',
    text: 'Администри рование',
    textEn: 'Administration',
    selected: false,
    path: ['/settings'],
    disabled: true,
  },
  {
    name: 'copy',
    text: 'Отчетность',
    textEn: 'Reporting',
    selected: false,
    path: ['/сopy'],
    disabled: true,
  },
  {
    name: 'integration',
    text: 'Интеграция',
    textEn: 'Integration',
    selected: false,
    path: ['/integration'],
    disabled: true,
  },
];

export const Navigation: React.FC<NavigationProps> = () => {
  const { selectLanguage, firstAvailableImageSetId } = useAppSelector(
    (state) => state.files
  );
  const _dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();

  const [tabsList, setTabsList] = useState<NavigationItem[]>(
    innerRoutes.map((item) => ({
      ...item,
      selected:
        item.path.includes(pathname + hash) || pathname.includes(item.path[0]),
    }))
  );

  useEffect(() => {
    const newArr = tabsList.map((item) => ({
      ...item,
      selected:
        item.path.includes(pathname + hash) || pathname.includes(item.path[0]),
      // pathname.split('/')[0] === item.path[0].split('/')[0],
    }));
    setTabsList(newArr);
    console.log(newArr);
  }, [navigate, pathname, hash]);

  useEffect(() => {
    setTabsList(
      tabsList.map((r) => {
        return {
          ...r,
          path:
            r.name === 'scan'
              ? [`check-lito/${firstAvailableImageSetId}/`]
              : r.path,
        };
      })
    );
  }, [firstAvailableImageSetId]);

  const сhangeActiveTab = (tab: NavigationItem) => {
    const newArr = tabsList.map((item) => ({
      ...item,
      selected: tab.name === item.name,
    }));
    const [baseMain, baseSub] = tab.path[0].split('#');
    console.log(baseMain, baseSub);

    _dispatch(setActivePathAct(baseMain));
    _dispatch(setSubPath(baseSub ? `#${baseSub}` : ''));
    setTabsList(newArr);
  };

  return (
    <Styled.Root>
      {tabsList.map((tab: NavigationItem) => (
        <Styled.Tab
          key={tab.name}
          isactive={tab.selected ? 1 : 0}
          disabled={tab.disabled ? 1 : 0}
          onClick={() => {
            if (!tab.disabled) {
              сhangeActiveTab(tab);
            }
          }}
        >
          <Styled.PhotoIcon
            name={tab.name}
            width={16}
            height={16}
            color={tab.selected ? '#fff' : '#000'}
            stroke={tab.selected ? '#fff' : tab.name === 'home' ? '#000' : ''}
          />
          <Styled.TabText isactive={tab.selected ? 1 : 0}>
            {selectLanguage === 'Ru' ? tab.text : tab.textEn}
          </Styled.TabText>
          {tab.disabled ? (
            <Styled.DisabiledInfo disabled={tab.disabled ? 1 : 0}>
              <Styled.DisabiledInfoText>
                {selectLanguage === 'Ru'
                  ? 'Недоступно в Демо версии'
                  : 'Not available in the Demo version'}
              </Styled.DisabiledInfoText>
            </Styled.DisabiledInfo>
          ) : (
            ''
          )}
        </Styled.Tab>
      ))}
    </Styled.Root>
  );
};

export default Navigation;
