import React from 'react';
import * as Styled from './styles';

interface AdminOtherSettingsProps {
  className?: string;
}

const AdminOtherSettings: React.FC<AdminOtherSettingsProps> = ({
  className,
}) => {
  return <Styled.Root className={className}>AdminOtherSettings</Styled.Root>;
};

export default AdminOtherSettings;
