/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { DragDrop } from 'src/components/DragDrop';
import { Icon } from 'src/components/UI/Icon';
import { Loading } from 'src/components/UI/Loading';
import { UploadForms } from 'src/components/UploadForms';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import {
  resetLoadingBarAct,
  setProfileTypeAct,
} from 'src/store/reducers/files';
import { getProfiles, uploadFiles } from 'src/store/reducers/files/async';
import { IProfile } from 'src/store/types';
import * as Styled from './styles';

interface Props {
  title: string;
  describe: string;
  data: IProfile[];
  active?: IProfile;
  onClick: (arg: any) => void;
}

const HeaderBlock: React.FC<Props> = ({
  title,
  describe,
  data,
  active,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Styled.HeaderTop>
      <Styled.LeftBlock>{title}</Styled.LeftBlock>
      <Styled.RightBlock>{describe}</Styled.RightBlock>
      <Styled.OpenButton opened={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Icon name="down" color="#FFFFFF" width={14} height={14} />
      </Styled.OpenButton>
      <Styled.HeaderTopDropdownWrapper opened={isOpen}>
        {data.map((profile) => (
          <Styled.HeaderTopDropdownItem
            isAcive={active?.id === profile.id}
            key={profile.id}
            onClick={() => {
              onClick(profile);
              setIsOpen(false);
            }}
          >
            {profile.name}
          </Styled.HeaderTopDropdownItem>
        ))}
      </Styled.HeaderTopDropdownWrapper>
    </Styled.HeaderTop>
  );
};

const MainUpload: React.FC = () => {
  const dispatch = useAppDispatch();

  const [activeProfile, setActiveProfile] = useState<IProfile>({
    id: 0,
    name: '',
    isfieldd: false,
    iswell: false,
    ismine: false,
    profileType: '',
  });
  const { authKey } = useAppSelector((state) => state.auth);
  const {
    loadingState,
    profilesList,
    currStep,
    stepsStates,
    defaultProflileID,
    selectLanguage,
    prifileType,
    selectedCompany,
  } = useAppSelector((state) => state.files);

  useEffect(() => {
    console.log('reset');
    dispatch(resetLoadingBarAct());
  }, []);

  useEffect(() => {
    console.log(prifileType);
  }, [prifileType]);

  useEffect(() => {
    // console.log(selectedCompany);
    // if (!profilesList?.length) {
    dispatch(getProfiles({ key: authKey }));
    // }
  }, [selectedCompany]);

  useEffect(() => {
    // console.log(profilesList);
    if (profilesList && defaultProflileID) {
      setActiveProfile(
        profilesList.find((item) => item.id === defaultProflileID) ||
          profilesList[0]
      );
    }
  }, [defaultProflileID, profilesList, selectedCompany]);

  const sendData = (e) => {
    e.preventDefault();
    console.log(activeProfile);
    if (activeProfile) {
      dispatch(
        uploadFiles({
          profileId: activeProfile?.id,
          key: authKey || '',
        })
      );
    }
  };

  return (
    <Styled.Root>
      <Loading active={loadingState === 'loading'} />
      <UploadForms activeProfile={activeProfile} />
      <Styled.MainBlock>
        <Styled.Header>
          <HeaderBlock
            title={
              selectLanguage === 'Ru' ? 'Профиль загрузки' : 'Boot Profile'
            }
            active={activeProfile}
            describe={
              activeProfile?.name
                ? activeProfile?.name
                : selectLanguage === 'Ru'
                ? 'Профиль загрузки'
                : 'Boot Profile'
            }
            data={profilesList || []}
            onClick={(profile: IProfile) => {
              setActiveProfile(profile);
              dispatch(
                setProfileTypeAct(
                  profile.profileType
                    .replace('(', '')
                    .replace(/'()/g, '')
                    .split(', ')[0]
                )
              );
            }}
          />
        </Styled.Header>
        <Styled.FilesWrapper>
          <DragDrop
            dragType="img"
            mustFiles
            // accessExtension={activeProfile?.imgFormat}
          />

          <DragDrop
            dragType="file"
            mustFiles
            // accessExtension={activeProfile?.docFormat}
          />
        </Styled.FilesWrapper>
        <Styled.SaveButton
          onClick={sendData}
          disabled={stepsStates[currStep - 1] === 'invalid'}
        >
          {selectLanguage === 'Ru' ? 'Сохранить' : 'Save'}
          <Icon name="next" width={15} height={15} color="#FFFFF" />
        </Styled.SaveButton>
      </Styled.MainBlock>
    </Styled.Root>
  );
};

export default MainUpload;
