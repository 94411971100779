/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import { baseVariables } from '../../styles/theme/baseVariables';
import { useAppDispatch, useAppSelector } from 'src/hooks/storeHooks';
import { logIn } from 'src/store/reducers/auth';

interface LogInSystemProps {
  className?: string;
}

const LogInSystem: React.FC<LogInSystemProps> = () => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [isPassword, setIsPassword] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [activeCheckbox, setActiveCheckbox] = useState<boolean>(false);

  const { selectLanguage } = useAppSelector((state) => state.files);

  useEffect(() => {
    setIsValid(email.length > 0 && password.length > 0);
  }, [email, password]);

  return (
    <Styled.Root>
      <Styled.LoginWrapper>
        <Styled.LoginTitle>
          {selectLanguage === 'Ru'
            ? 'Войти в систему'
            : 'Log in to the system'
          }
        </Styled.LoginTitle>
        <Styled.AccessRequest href="#">
          {selectLanguage === 'Ru'
            ? 'или запросить доступ'
            : 'or request access'
          }
        </Styled.AccessRequest>

        <Styled.LoginHint>
          {selectLanguage === 'Ru'
            ? 'Логин'
            : 'Login'
          }
        </Styled.LoginHint>
        <Styled.LoginInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Styled.PasswordHint>
          {selectLanguage === 'Ru'
            ? 'Пароль'
            : 'Password'
          }
        </Styled.PasswordHint>
        <Styled.FlexPassword>
          <Styled.PasswordInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={isPassword ? 'password' : 'text'}
          />
          <Styled.IconWrapper>
            <Styled.CustomIcon
              name="eye"
              width={12}
              height={12}
              color={baseVariables.colors.bgGray}
              onClick={() => setIsPassword(!isPassword)}
            />
          </Styled.IconWrapper>
        </Styled.FlexPassword>

        <Styled.LoginButton
          disabled={!isValid}
          onClick={() => dispatch(logIn({ email, password }))}
        >
          {selectLanguage === 'Ru'
            ? 'Войти'
            : 'Enter'
          }
        </Styled.LoginButton>

        <Styled.AdditionalOptions>
          <Styled.CastomCheckboxWrapper>
            <Styled.CastomCheckbox
              activeCheckbox={activeCheckbox}
              onClick={() => setActiveCheckbox(!activeCheckbox)}
            />
            <Styled.CastomCheckboxText>
              {selectLanguage === 'Ru'
                ? 'Запомнить это устройство'
                : 'Remember this device'
              }
            </Styled.CastomCheckboxText>
          </Styled.CastomCheckboxWrapper>

          <Styled.ButtonProblems href="#">
            {selectLanguage === 'Ru'
              ? 'Проблемы с доступом?'
              : 'Problems with access?'
            }
          </Styled.ButtonProblems>
        </Styled.AdditionalOptions>
      </Styled.LoginWrapper>

      <Styled.LoginFooter>
        <Styled.DataStoragePolicy href="#">
          {selectLanguage === 'Ru'
            ? 'Политика хранения и обработки персональных данных'
            : 'Personal Data Storage and Processing Policy'
          }
        </Styled.DataStoragePolicy>
        <Styled.LogoIcon
          width={66}
          height={44}
          name={selectLanguage === 'Ru' ? "logo-1" : "logo_eng-1"}
          color={baseVariables.colors.bgGray}
        />
      </Styled.LoginFooter>
    </Styled.Root>
  );
};

export default LogInSystem;
