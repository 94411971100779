import React from 'react';
import * as Styled from './styles';

interface HomeSettingsProps {
  className?: string;
}

const HomeSettings: React.FC<HomeSettingsProps> = ({ className }) => {
  return <Styled.Root className={className}>HomeSettings</Styled.Root>;
};

export default HomeSettings;
