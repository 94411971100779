import React from 'react';
import IconsParh from './Icons.svg';
import cn from 'classnames';
import './styles.scss';

export type SvgNames =
  | 'save'
  | 'add'
  | 'minus'
  | 'photo'
  | 'settings'
  | 'draw'
  | 'down'
  | 'downloald'
  | 'next'
  | 'scan'
  | 'copy'
  | 'plus'
  | 'clip'
  | 'detect'
  | 'integration'
  | 'search'
  | 'long-line'
  | 'back'
  | 'info'
  | 'home'
  | 'account'
  | 'collapse'
  | 'exclamation'
  | 'log-out'
  | 'right'
  | 'left'
  | 'up-down-mouse'
  | 'docx'
  | 'pdf'
  | 'xlsx'
  | 'png'
  | 'eye'
  | 'danger'
  | 'logo-1'
  | 'logo-2'
  | 'logo_eng-1'
  | 'logo_eng-2'
  | 'lang'
  | 'txt'
  | 'expand'
  | 'chevron-down'
  | 'analytics'
  | 'settings-mini'
  | 'drilling';

export interface IconProps {
  name: SvgNames;
  className?: string;
  onClick?: () => void;
  width?: number;
  height?: number;
  color?: string;
  stroke?: string;
}

const Icon: React.FC<IconProps & React.SVGProps<SVGSVGElement>> = ({
  name,
  className,
  color,
  onClick,
  width = 20,
  height = 20,
  stroke,
  ...rest
}) => {
  return (
    <svg
      key={name}
      className={cn('root', {}, className)}
      onClick={onClick}
      width={width * 2}
      height={height * 2}
      color={color}
      {...rest}
    >
      {stroke ? (
        <use
          xlinkHref={`${IconsParh}#${name}`}
          fill="currentColor"
          width={width * 2}
          height={height * 2}
          color={color}
          stroke={stroke}
        />
      ) : (
        <use
          xlinkHref={`${IconsParh}#${name}`}
          fill="currentColor"
          width={width * 2}
          height={height * 2}
          color={color}
        />
      )}
    </svg>
  );
};

export default Icon;
