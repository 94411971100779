/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

interface Props {
  isactive?: boolean;
}

export const Root = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const Navigation = styled.div`
  width: 24%;
  height: 70vh;
  padding: 20px 0;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const NavigationItem = styled.div<Props>`
  display: flex;
  cursor: pointer;
  position: relative;
  color:  ${({ theme }) => theme.colors.lightBlue};

  &::after {
    content: '';
    position: absolute;
    
    width: 90%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray};
    bottom: 0;
    right: 5%;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};
    transition: 0.1s;

    &::after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
      transition: 0.1s;
    }
  }

  ${({isactive}) => isactive && css`
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};

    &::after {
      background-color: ${({ theme }) => theme.colors.darkBlue};
    }
  `}
`;

export const NavigationItemText = styled.p<Props>`
  font-weight: 700;
  margin: 7px 0 7px 25px;
`;

export const Box = styled.div`
  width: 74%;
  height: 70vh;
  background-color: ${({ theme }) => theme.colors.white};

  display: flex;
  flex-direction: column;
`;